import axios, { AxiosResponse } from "axios";
import React, { MutableRefObject, useEffect, useState } from "react";
import { accordionCreditInfo } from "./HelpViewInfo";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";

import "../styles/ActivateView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

export const ActivateView = () => {
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");
  const [ok, setOk] = useState<boolean | null>(null);

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_ACTIVATE_PAGE, {});
  }, [utm]);

  useEffect(() => {
    for (var pair of query.entries()) {
      if (pair[0] === "token") {
        axios
          .get(
            [
              `${process.env.REACT_APP_API_SERVER_URL}`,
              `/user/activate?token=${pair[1]}`,
            ]
              .map((p) => p.replace(/^\/|\/$/g, ""))
              .join("/")
          )
          .then((response) => {
            console.log(response);
            setOk(true);
          })
          .catch((err) => {
            console.error(err);
            setOk(false);
          });
      }
    }
  }, []);

  return (
    <div className="activate-view">
      {ok === true && "Thank you, your email has been successfully confirmed."}
      {ok === false && "Something went wrong."}
    </div>
  );
};
