import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LoginView } from "./views/LoginView";
import { HomeView } from "./views/HomeView";
import { OnboardingView } from "./views/OnboardingView";
import Container from "react-bootstrap/Container";
import { SettingsView } from "./views/SettingsView";
import { HistoryView } from "./views/HistoryView";
import { ProductView } from "./views/ProductView";
import { RegisterView } from "./views/RegisterView";
import { AdminView } from "./views/AdminView";
import { ActiveAccessesView } from "./views/ActiveAccessesView";
import { ComingSoonView } from "./views/ComingSoonView";
import { CreditHistoryView } from "./views/CreditHistoryView";
import { ResetView } from "./views/ResetView";
import { ResetPasswordView } from "./views/ResetPasswordView";
import { PrivacyPolicyView } from "./views/PrivacyPolicyView";
import { TermsView } from "./views/TermsView";
import { HelpView } from "./views/HelpView";
import { AuthContext } from "./context/AuthContext";
import { NavigationBar } from "./components/NavigationBar";
import { Footer } from "./components/Footer";
import { CenterLoader } from "./components/Loader";
import { TrackingPixel } from "./components/TrackingPixel";
import UserCreditProvider from "./context/UserCreditContext";
import { ToastProvider } from "react-toast-notifications";
import { MyCustomToast } from "./components/ToastComponent";
import { WaitListConfirmation } from "./views/WaitListConfirmation";
import { PartnerSite } from "./views/PartnerSite";
import { OAuth2 } from "./views/OAuth2";
import { ChromeExtensionNotification } from "./components/ChromeExtensionNotification";
import { BannerView } from "./views/BannerView";
import { RegisterHelpView } from "./views/RegisterHelpView";
import { LandingView } from "./views/LandingView";
import { LandingForStudentsView } from "./views/LandingForStudentsView";
import { LandingForProfessionalsView } from "./views/LandingForProfessionalsView";
import { LandingForIndependentProsView } from "./views/LandingForIndependentProsView";
import { LandingExtensionView } from "./views/LandingExtensionView";
import { MainView } from "./views/MainView";
import { SubscribeView } from "./views/SubscribeView";
import { ActivateView } from "./views/ActivateView";
import { PaymentCompleteView } from "./views/PaymentCompleteView";
import { VerifyEmailView } from "./views/VerifyEmailView";

function App() {
  return (
    <>
      <TrackingPixel />
      <DisplayRoute />
    </>
  );
}

function DisplayRoute() {
  const { user, loader } = React.useContext(AuthContext);
  const tabs = [
    { name: "Home", link: "/home", event_name: "HOME" },
    // { name: "Settings", link: "/settings" },
    // { name: "Admin", link: "/admin" },

    // { name: "Purchase History", link: "/history", event_name: "HISTORY" },
    {
      name: "Active Products",
      link: "/active",
      event_name: "ACTIVE_ACCESSES",
    },
    // {
    //   name: "Coming Soon",
    //   link: "/coming-soon",
    //   event_name: "COMING_SOON",
    // },
    {
      name: "How Keye Works",
      link: "/help",
      event_name: "HOW_KEYE_WORKS",
    },
  ];

  return (
    <Switch>
      <Route path={"/login"} render={() => <LoginView />} />
      <Route path={"/register"} render={() => <RegisterView />} />
      <Route path={"/reset"} render={() => <ResetView />} />
      <Route
        path={"/resetpassword/:resetPasswordJWT"}
        render={() => <ResetPasswordView />}
      />
      <Route path={"/privacy"} render={() => <PrivacyPolicyView />} />
      <Route path={"/terms"} render={() => <TermsView />} />
      <Route path={"/activate"} render={() => <ActivateView />} />
      <Route
        path={"/home"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <ToastProvider components={{ Toast: MyCustomToast }}>
                  <HomeView />
                </ToastProvider>
              </Container>

              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/onboarding"}
        render={({ location }) =>
          user ? (
            <>
              <Container fluid className="onboarding-view px-0">
                <OnboardingView />
              </Container>
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/banner"}
        render={({ location }) =>
          user ? (
            <div className="banner">
              <UserCreditProvider>
                <NavigationBar tabs={tabs} />
                <BannerView />
              </UserCreditProvider>
              <Footer />
            </div>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/product/:productId"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                  <ProductView />
                  <ChromeExtensionNotification />
                </UserCreditProvider>
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/active"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <ActiveAccessesView />
              </Container>

              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/coming-soon"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <ComingSoonView />
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/payment-complete"}
        render={({ location }) =>
          user ? (
            <>
              <Container className="payment-complete-view">
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <PaymentCompleteView />
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/help"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <HelpView />
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={["/landing", "/about"]}
        render={({ location }) =>
          loader ? (
            <CenterLoader />
          ) : (
            <>
              <Container fluid className="landing-view-old">
                <LandingView />
              </Container>
            </>
          )
        }
      />

      <Route
        path="/for-students-universities-and-schools"
        render={({ location }) =>
          loader ? (
            <CenterLoader />
          ) : (
            <>
              <Container fluid className="landing-for-students-view">
                <LandingForStudentsView />
              </Container>
            </>
          )
        }
      />

      <Route
        path="/for-teams-and-professionals"
        render={({ location }) =>
          loader ? (
            <CenterLoader />
          ) : (
            <>
              <Container fluid className="landing-for-professionals-view">
                <LandingForProfessionalsView />
              </Container>
            </>
          )
        }
      />

      <Route
        path="/for-independent-professionals"
        render={({ location }) =>
          loader ? (
            <CenterLoader />
          ) : (
            <>
              <Container fluid className="landing-for-independent-pros-view">
                <LandingForIndependentProsView />
              </Container>
            </>
          )
        }
      />

      <Route
        path="/browser-extension"
        render={({ location }) =>
          loader ? (
            <CenterLoader />
          ) : (
            <>
              <Container fluid className="landing-extension-view px-0">
                <LandingExtensionView />
              </Container>
            </>
          )
        }
      />

      <Route
        path={"/help-register"}
        render={() => (
          <>
            <UserCreditProvider>
              <NavigationBar tabs={[]} />
            </UserCreditProvider>
            <RegisterHelpView />
          </>
        )}
      />

      <Route
        path={"/verifyemail"}
        render={({ location }) =>
          user ? (
            <>
              <VerifyEmailView />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/settings"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <SettingsView />
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/history"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <HistoryView />
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/credit-history"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                  <CreditHistoryView />
                </UserCreditProvider>
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/subscribe"}
        render={({ location }) =>
          user ? (
            <>
              <Container className="subscribe-view">
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                  <SubscribeView />
                </UserCreditProvider>
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route
        path={"/admin"}
        render={({ location }) =>
          user && user.admin ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                </UserCreditProvider>
                <AdminView />
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
      <Route
        path={"/waitListConfirmation/:productId"}
        render={({ location }) =>
          user ? (
            <>
              <Container>
                <UserCreditProvider>
                  <NavigationBar tabs={tabs} />
                  <WaitListConfirmation />
                </UserCreditProvider>
              </Container>
              <Footer />
            </>
          ) : loader ? (
            <CenterLoader />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />

      <Route path={"/oauth2"} render={() => <OAuth2 />} />

      <Route
        path="/"
        render={({ location }) =>
          loader ? (
            <CenterLoader />
          ) : (
            <>
              <Container fluid className="main-view px-0">
                <MainView />
              </Container>
            </>
          )
        }
      />

      <Route path={"/partnersite"} render={() => <PartnerSite />} />
    </Switch>
  );
}

export default App;
