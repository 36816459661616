import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  CompletedProductSurvey,
  Product,
  ProductCreditCost,
  ProductSurvey,
} from "../../models/Product";
import { completeProductSurvey } from "../../scripts/createApiInstance";
import { isEmpty } from "../../scripts/util";
import { displayFormattedText } from "../../utils/Format";
import {
  accessDetailsBundle,
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  feedbackBundle,
  productDetailsBundle,
  sendAnalyticsData,
} from "../../scripts/analytics";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import "../../styles/ProductCheckoutFlow.scss";

const IMMEDIATE_ACCESS = "IMMEDIATE_ACCESS";
const WAITLIST_ACCESS = "WAITLIST_ACCESS";

function initializePreaccessSurvey(preaccessSurveys: ProductSurvey[]) {
  return preaccessSurveys.length > 0 ? preaccessSurveys[0] : null;
}

export function ProductCheckoutFlow({
  product,
  handleClose,
  handleStartWaitlistAccess,
  handleStartImmediateAccess,
  nextAvailabletime,
  productSurveys,
  completedSurveys,
  productCreditCost,
}: {
  product: Product;
  handleClose: () => void;
  handleStartWaitlistAccess: () => void;
  handleStartImmediateAccess: () => void;
  nextAvailabletime: string;
  productSurveys: ProductSurvey[];
  completedSurveys: CompletedProductSurvey[];
  productCreditCost: ProductCreditCost | undefined;
}): JSX.Element {
  const history = useHistory();

  // Find the preaccess survey
  const preaccessSurveys = productSurveys.filter(
    // @ts-ignore
    (x) => x.productSurveyType === "PREACCESS"
  );
  const { apiInstance, user } = useContext(AuthContext);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [surveyCompleted, setSurveyCompleted] = useState<boolean>(false);
  const [preaccessSurvey, setPreaccessSurvey] = useState<ProductSurvey | null>(
    initializePreaccessSurvey(preaccessSurveys)
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<number>(2);
  const [selectedAccessType, setSelectedAccessType] = useState<string>("");

  useEffect(() => {
    if (selectedAccessType.length > 0) {
      if (termsAgreed) {
        setSelectedStep(3);
      } else {
        setSelectedStep(2);
      }
    }
  }, [selectedAccessType, termsAgreed]);

  useEffect(() => {
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_ACCESS_REQUEST_PAGE, {
      ...eventBundleProperties(user, EVENT_TYPE.UI),
      ...productDetailsBundle(product, "ACCESSIBLE"),
      ...accessDetailsBundle(product),
    });
  }, [user, product]);

  // Don't show the survey if we have already completed it
  let completedPreaccessSurvey = false;
  if (preaccessSurvey) {
    completedPreaccessSurvey =
      completedSurveys.filter((x) => x.productSurveyId === preaccessSurvey.id)
        .length > 0;
  }

  function sendProductAccessEvent(): void {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_JOIN_WAITLIST, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, "ACCESSIBLE"),
    });
  }

  const confirmAccessAction = () => {
    setDisabled(true);
    sendProductAccessEvent();

    if (completedPreaccessSurvey || !preaccessSurvey) {
      // Just activate access since survey was already completed or does not exist
      handleStartWaitlistAccess();
    } else {
      // Complete the product survey and activate access
      const completedSurvey = preaccessSurvey!;
      completeProductSurvey(
        apiInstance,
        completedSurvey.productId,
        completedSurvey.id,
        completedSurvey.survey
      )
        .then(function (resp) {
          sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_FEEDBACK_SURVEY, {
            ...eventBundleProperties(user, EVENT_TYPE.UA),
            ...feedbackBundle(completedSurvey),
          });
          handleStartWaitlistAccess();
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setDisabled(false);
        });
    }
    history.push(`/waitListConfirmation/${product.id}`);
  };

  const testerImmediatelyConfirmAccess = () => {
    setDisabled(true);
    setTermsAgreed(true);

    if (completedPreaccessSurvey || !preaccessSurvey) {
      // Just activate access since survey was already completed or does not exist
      handleStartImmediateAccess();
    } else {
      // Complete the product survey and activate access
      const completedSurvey = preaccessSurvey!;
      completeProductSurvey(
        apiInstance,
        completedSurvey.productId,
        completedSurvey.id,
        completedSurvey.survey
      )
        .then(function (resp) {
          sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_FEEDBACK_SURVEY, {
            ...eventBundleProperties(user, EVENT_TYPE.UA),
            ...feedbackBundle(completedSurvey),
          });
          handleStartImmediateAccess();
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setDisabled(false);
        });
    }
  };
  // const currentHour = currentTime().hour();
  // const disableImmediateAccess = currentHour >= 7 && currentHour < 9;
  const disableImmediateAccess = false;

  function resetToStepNumber(stepNo: number): void {
    if (stepNo === 1) {
      setSelectedStep(1);
      setSelectedAccessType("");
      setTermsAgreed(false);
      setPreaccessSurvey(initializePreaccessSurvey(preaccessSurveys));
      setSurveyCompleted(false);
    }
  }

  function getStepStyleHeader(stepNo: number): string {
    if (stepNo < selectedStep) {
      return "completed-step-header-text";
    }
    return "step-header-text";
  }

  var localDate;
  var tomorrowDate;
  if (!isEmpty(nextAvailabletime) && product.remainingAccess <= 0) {
    localDate = moment.utc(nextAvailabletime).format("MM/DD/YY");
    tomorrowDate = moment
      .utc(nextAvailabletime)
      .add(1, "days")
      .format("MM/DD/YY");
  } else {
    localDate = moment.utc().format("MM/DD/YY");
    tomorrowDate = moment.utc(localDate).add(1, "days").format("MM/DD/YY");
  }

  // Want to convert 2pm and 4pm to the user's local time
  const endLocalTime = moment.utc().toDate();
  const endLocalString = moment(endLocalTime).format("hh:mm A").toLowerCase();
  const startLocalTime = moment.utc("2022-01-16T09:00:00").toDate();
  const startLocalString = moment(startLocalTime)
    .format("hh:mm A")
    .toLowerCase();
  const additionalTermsOutput = displayFormattedText(product.additionalTerms);

  // if(!disableImmediateAccess && product.remainingAccess > 0) {
  //   setSelectedAccessType(IMMEDIATE_ACCESS);
  //   setSelectedStep(2);
  // }
  return (
    <div>
      {completedSurveys.length > 0 ? (
        <>
          <div className={"step-group"}>
            <div>
              {!disableImmediateAccess && product.remainingAccess > 0 ? (
                <></>
              ) : (
                <>
                  <p
                    className={getStepStyleHeader(1)}
                    onClick={() => resetToStepNumber(1)}>
                    Choose Access Period
                  </p>
                  <Button
                    className="m-3"
                    variant="outline-warning"
                    style={{ fontSize: "10px", padding: "5px 5px" }}
                    onClick={() => {
                      setSelectedAccessType(WAITLIST_ACCESS);
                      setTermsAgreed(true);
                    }}>
                    <span style={{ fontSize: "14px" }}>
                      Join wait list{" "}
                      {product.remainingAccess <= 0
                        ? "(Current accesses are full)"
                        : ""}
                    </span>
                    <br />({startLocalString} on {localDate} to {endLocalString}{" "}
                    on {tomorrowDate})
                  </Button>
                </>
              )}

              {product.remainingAccess > 0 ? (
                <p id="limited-option-message"></p>
              ) : (
                <p id="limited-option-message">
                  This product will be available soon. Join the waitlist for the
                  next available access to be notified.
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"step-group"}>
            {selectedStep === 1 && (
              <div>
                {!disableImmediateAccess && product.remainingAccess > 0 ? (
                  <></>
                ) : (
                  <>
                    <p
                      className={getStepStyleHeader(1)}
                      onClick={() => resetToStepNumber(1)}>
                      Choose Access Period
                    </p>
                    <Button
                      className="m-3"
                      variant="outline-warning"
                      style={{ fontSize: "10px", padding: "5px 5px" }}
                      onClick={() => setSelectedAccessType(WAITLIST_ACCESS)}>
                      <span style={{ fontSize: "14px" }}>
                        Join wait list{" "}
                        {product.remainingAccess <= 0
                          ? "(Current accesses are full)"
                          : ""}
                      </span>
                      <br />({startLocalString} on {localDate} to{" "}
                      {endLocalString} on {tomorrowDate})
                    </Button>
                  </>
                )}
                {product.remainingAccess > 0 ? (
                  <p id="limited-option-message"></p>
                ) : (
                  <p id="limited-option-message">
                    This product will be available soon. Join the waitlist for
                    the next available access to be notified.
                  </p>
                )}
              </div>
            )}
          </div>
          <div className={"step-group"}>
            <p className={getStepStyleHeader(2)}>Review and accept terms</p>
            {selectedStep === 2 && (
              <div id="review-terms-box">
                <p>
                  * The extension will automatically log you into the service
                  once you have gone through the process of requesting access.
                </p>
                <p>* You will be logged out once your access period ends.</p>
                {!isEmpty(product.additionalTerms)
                  ? additionalTermsOutput
                  : null}
                {/*<button*/}
                {/*  id="terms-accept-button"*/}
                {/*  onClick={() => setTermsAgreed(true)}>*/}
                {/*  Accept*/}
                {/*</button>*/}
              </div>
            )}
          </div>
        </>
      )}
      {(isEmpty(selectedAccessType) ||
        selectedAccessType === IMMEDIATE_ACCESS) &&
      product.remainingAccess > 0 ? (
        <button
          className="btn btn-success"
          id="access-button"
          onClick={testerImmediatelyConfirmAccess}
          disabled={
            // (!termsAgreed && completedSurveys.length===0)
            // || (!surveyCompleted &&
            // !completedPreaccessSurvey &&
            // preaccessSurvey !== null)
            // ||
            disabled
          }>
          {productCreditCost
            ? completedSurveys.length > 0
              ? "Activate Access"
              : "Accept Terms & Activate Access"
            : "Activate Keye Access"}
        </button>
      ) : (
        <button
          className="btn btn-success"
          id="join-waitlist-button"
          onClick={confirmAccessAction}
          disabled={
            !termsAgreed ||
            (!surveyCompleted &&
              !completedPreaccessSurvey &&
              preaccessSurvey !== null) ||
            disabled
          }>
          Join Waitlist
        </button>
      )}
    </div>
  );
}
