import { useContext } from "react";
import { ProductContext } from "../context/ProductContext";
import { AccountAccess } from "../models/Product";
import { CenterLoader } from "../components/Loader";
import { displayDate } from "../scripts/util";
import { Row, Col, Table } from "react-bootstrap";

import "../styles/HistoryView.scss";

export function HistoryView(): JSX.Element {
  const { allProductAccesses, allProductAccessesLoading } =
    useContext(ProductContext);

  return allProductAccessesLoading ? (
    <CenterLoader />
  ) : (
    <div>
      <AccessComponentTable accountAccesses={allProductAccesses} />
    </div>
  );
}

export function AccessComponentTable({
  accountAccesses,
}: {
  accountAccesses: AccountAccess[];
}): JSX.Element {
  const tableRows = accountAccesses.map((x) => (
    <tr key={JSON.stringify(x)}>
      <th scope={"row"}>{x.product.name}</th>
      <td>
        {displayDate(x.accessed)} - {displayDate(x.expiration)}
      </td>
    </tr>
  ));

  return (
    <Row>
      <Col>
        <Table striped responsive>
          <thead>
            <tr id="purchase-history-table-header">
              <th scope="col">Product</th>
              <th scope="col">Access Date Range</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </Col>
    </Row>
  );
}
