export enum Frequency {
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
  UNLIMITED,
}

export enum CATEGORY {
  DATA,
  NEWS,
  ENTERTAINMENT,
  INFORMATION,
  PRODUCTIVITY,
  EDUCATION,
  LEARNING,
  MISCELLANEOUS,
  HEALTH,
}

export interface FeaturedMetadata {
  featured: boolean;
  featuredMessage: string;
  extraDescription: string;
  contentUrl: string;
}

export enum ProductStatus {
  AVAILABLE,
  COMING_SOON,
  INACTIVE,
}

export interface Product {
  id: string;
  name: string;
  category: CATEGORY;
  shortDescription: string;
  longDescription: string;
  keyeBenefits: string;
  url: string;
  oauth: boolean;
  loginUrl: string;
  imgUrl: string;
  additionalTerms: string;
  status: ProductStatus;
  comingSoonDate: string;
  featuredMetadata: FeaturedMetadata;
  price: number;
  frequency: Frequency;
  retailPrice: number;
  retailFrequency: Frequency;
  created: string;
  remainingAccess: number;
  creditPrice: number;
}

export interface ProductAccount {
  productAccountId: string;
  productId: string;
  username: string;
  password: string;
  active: true;
  created: string;
  updated: string;
  accessed: string;
  expiration: string;
  concurrentLimit: number;
  remainingAccess: number;
  price: number;
  // Used for only internal purposes
  changed: boolean;
  shouldDeactivate: boolean;
  credentialWhitelisted: boolean;
}

export interface AccountAccess {
  id: string;
  product: Product;
  accessed: string;
  expiration: string;
  price: number;
  active: boolean;
}

export interface ProductAccountAccess {
  product: Product;
  productAccount: ProductAccount;
}

export interface ProductScreenshot {
  id: string;
  imgUrl: string;
  created: string;
}

export enum WaitlistStatus {
  ACTIVE,
  WITHDRAWN,
  FULFILLED,
}

export enum TransactionOperation {
  LOAD,
  ACCESS,
  DECREASE,
  INCREASE,
}

export interface UserTransactionRecord {
  operation: TransactionOperation;
  message: string;
  amount: number;
  balance: number;
  created: string;
}

export interface WaitlistDetails {
  productName: any;
  id: string;
  userId: string;
  productId: string;
  requestTime: string;
  scheduledTime: string;
  accessStart: string;
  waitlistStatus: WaitlistStatus;
  statusChange: string;
}

export interface ProductCreditCost {
  productId: string;
  creditCost: number;
}

export interface ProductReservationDetails {
  id: string;
  userId: string;
  productId: string;
  productAccountId: string;
  scheduledTime: string;
  active: boolean;
  created: string;
  updated: string;
}

export interface SearchQuery {
  page: number;
  limit: number;
}

export interface RequestSearchQuery {
  query: string;
  page: number;
  limit: number;
}

export enum ProductSurveyType {
  PREACCESS,
  POSTACCESS,
}

export enum SurveyRecurringFrequency {
  SINGLE,
  REPEAT,
}

export enum ProductSurveyComponentType {
  SINGLE_SELECTION = "SINGLE_SELECTION",
  OPEN_ENDED = "OPEN_ENDED",
}

export interface ProductSurveyComponent {
  question: string;
  type: ProductSurveyComponentType;
  options: string[];
  selectedOption: string;
}

export interface ProductSurveyQuestionairre {
  components: ProductSurveyComponent[];
}

export interface ProductSurvey {
  id: string;
  productId: string;
  survey: ProductSurveyQuestionairre;
  productSurveyType: ProductSurveyType;
  recurringFrequency: SurveyRecurringFrequency;
  active: boolean;
  created: string;
}

export interface CompletedProductSurvey {
  id: string;
  productSurveyId: string;
  userId: string;
  responses: ProductSurveyQuestionairre;
  active: boolean;
  completionDate: string;
}

export interface ProductServiceUseStats {
  activeAccesses: number;
  productId: number;
  productName: string;
  remainingAccesses: number;
  totalAccesses: number;
  totalAccounts: number;
}
