import posthog from "posthog-js";
import React, { useRef } from "react";
import { Col, Navbar, NavDropdown, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
} from "../scripts/analytics";
import { User } from "../models/User";
import { AuthContext } from "../context/AuthContext";

import "../styles/Header.scss";

export function Header(): JSX.Element {
  const history = useHistory();
  const ref = useRef(null);
  const { setUserJWT, user } = React.useContext(AuthContext);

  const HEADER_LINKS = [
    {
      text: "Products on Keye",
      href: "/#products",
      onClick: () => {
        sendAnalyticsData(
          ANALYTICS_EVENT.ON_CLICK_NAVBAR_PRODUCTS_ON_KEYE,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );
      },
    },
    {
      text: "How Keye works",
      href: "/#more-about-keye",
      onClick: () => {
        sendAnalyticsData(
          ANALYTICS_EVENT.ON_CLICK_NAVBAR_HOW_KEYE_WORKS,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );
      },
    },
    {
      text: "Login",
      href: "/login",
      onClick: () => {
        sendAnalyticsData(
          ANALYTICS_EVENT.ON_CLICK_NAVBAR_LOGIN,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );
      },
    },
  ];

  const HEADER_LINKS_SIGNED_IN = [
    {
      text: "Products on Keye",
      href: "/#products",
      onClick: () => {
        sendAnalyticsData(
          ANALYTICS_EVENT.ON_CLICK_NAVBAR_PRODUCTS_ON_KEYE,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );
      },
    },
    {
      text: "How Keye works",
      href: "/#more-about-keye",
      onClick: () => {
        sendAnalyticsData(
          ANALYTICS_EVENT.ON_CLICK_NAVBAR_HOW_KEYE_WORKS,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );
      },
    },
    {
      text: "Logout",
      href: "#",
      onClick: () => {
        setUserJWT("", false);
        sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_SIGN_OUT, {});
        posthog.reset();
        history.push("/");
      },
    },
  ];

  return (
    <>
      <Navbar className="header" collapseOnSelect>
        <Row style={{ width: "100%" }}>
          <Col xs={4}>
            <Navbar.Brand>
              <Link to="/">
                <img src="/images/keye_logo.png" alt="Keye logo" />
              </Link>
            </Navbar.Brand>
          </Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            {(user ? HEADER_LINKS_SIGNED_IN : HEADER_LINKS).length &&
              (isMobile ? (
                <>
                  <NavDropdown
                    ref={ref}
                    title="☰"
                    style={{ width: "10%", display: "inline-block" }}>
                    {(user ? HEADER_LINKS_SIGNED_IN : HEADER_LINKS).map(
                      (link) => (
                        <Link
                          key={link.text}
                          to={link.href}
                          onClick={(ev) => {
                            if (typeof link.onClick === "function") {
                              link.onClick();
                            }

                            const linkNode: HTMLAnchorElement =
                              ev.target as HTMLAnchorElement;
                            if (linkNode.hash.length > 0) {
                              // Don't keep the dropdown menu open
                              if (ref && ref.current) {
                                (ref.current as any).click();
                              }

                              setTimeout(() => {
                                const id: string = linkNode.hash.replace(
                                  "#",
                                  ""
                                );
                                const targetElNode =
                                  document.getElementById(id);
                                if (targetElNode) {
                                  targetElNode.scrollIntoView();
                                }
                              }, 0);
                            } else {
                              window.scrollTo(0, 0);

                              // Don't keep the dropdown menu open
                              if (ref && ref.current) {
                                (ref.current as any).click();
                              }
                            }
                          }}>
                          {link.text}
                        </Link>
                      )
                    )}
                  </NavDropdown>
                </>
              ) : (
                <ul>
                  {(user ? HEADER_LINKS_SIGNED_IN : HEADER_LINKS).map(
                    (link) => (
                      <Link
                        key={link.text}
                        to={link.href}
                        onClick={(ev) => {
                          if (typeof link.onClick === "function") {
                            link.onClick();
                          }

                          const linkNode: HTMLAnchorElement =
                            ev.target as HTMLAnchorElement;
                          if (linkNode.hash.length > 0) {
                            setTimeout(() => {
                              const id: string = linkNode.hash.replace("#", "");
                              const targetElNode = document.getElementById(id);
                              if (targetElNode) {
                                targetElNode.scrollIntoView();
                              }
                            }, 0);
                          } else {
                            window.scrollTo(0, 0);
                          }
                        }}>
                        {link.text}
                      </Link>
                    )
                  )}
                </ul>
              ))}
          </Col>
        </Row>
      </Navbar>
    </>
  );
}
