import React from "react";

import "../../styles/HeroSection.scss";

export const HeroSection = () => {
  return (
    <div className="relative container pt-5 pb-5 flex items-stretch justify-between mx-auto align-middle">
      <div className="my-auto z-5">
        <h1 className="font-bold font-gilroy ">
          Experience the <span className="text-primary">Keye </span>to all the
          subscriptions you need
        </h1>
        <p className="my-6">Watch how Keye works!</p>
      </div>
      <div unselectable="on">
        <div className="relative w-12 z-10">
          <img
            src="/images/question_transparent.gif"
            alt=""
            unselectable="on"
          />
        </div>
      </div>
    </div>
  );
};

export const HeroSectionMobile = () => {
  return (
    <div>
      <div className="px-2 pt-6 pb-6 mt-6 flex flex-col justify-between items-center overflow-hidden relative">
        <div className="z-10">
          <h1 className="font-bold font-gilroy text-medium xl:text-hero sm:text-larger">
            Experience the <span className="text-primary">Keye </span>to all the
            subscriptions you need
          </h1>
          <div className="mt-3">
            <img
              src="/images/question_transparent.gif"
              alt=""
              unselectable="on"
            />
          </div>
          <p className="text-extraSmall mt-3">Watch how Keye works!</p>
        </div>
      </div>
    </div>
  );
};
