import React from "react";
import { Helmet } from "react-helmet";

import { CenterLoader } from "../components/Loader";
import { AuthContext } from "../context/AuthContext";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export function PricingTable() {
  const { user, loader } = React.useContext(AuthContext);

  return loader ? (
    <CenterLoader />
  ) : user ? (
    <>
      <Helmet>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        client-reference-id={user.id}
        customer-email={user.email}></stripe-pricing-table>
    </>
  ) : (
    <>Must be logged in in order to view pricing table.</>
  );
}
