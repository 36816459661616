import { useContext } from "react";
import { Table } from "react-bootstrap";
import { Loader } from "../components/Loader";
import { UserCreditContext } from "../context/UserCreditContext";

import "../styles/CreditHistoryView.scss";

export function CreditHistoryView() {
  const { userTransactions, userTransactionsLoading } =
    useContext(UserCreditContext);

  return (
    <div>
      {userTransactionsLoading ? (
        <Loader />
      ) : (
        <div>
          <Table striped responsive>
            <thead>
              <tr id="credit-history-table-header">
                <th>Transaction Name</th>
                <th>Date</th>
                <th>Credit Amount</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {userTransactions.map((transaction) => (
                <tr key={transaction.created}>
                  <td>{transaction.message}</td>

                  <td>{transaction.created.slice(0, 10)}</td>

                  <td>{transaction.amount}</td>
                  <td>{transaction.balance}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}
