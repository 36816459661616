import React from "react";

import "../styles/ToastView.scss";

export const MyCustomToast = ({
  appearance,
  children,
}: {
  appearance: string;
  children: any;
}) => (
  <div className={"square"}>
    <div
      className={"content"}
      style={{ flexDirection: "column", padding: "10px" }}
      dangerouslySetInnerHTML={{ __html: children }}></div>
  </div>
);
