import React, { MutableRefObject, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import Carousel from "nuka-carousel";
import { Accordion } from "./HelpView";
import { accordionCreditInfo } from "./HelpViewInfo";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";
import { isMobile } from "react-device-detect";
import { Header } from "../components/Header";
import { SignUpInputField } from "../components/SignUpInputField";
import { VerticallyScrollingText } from "../components/VerticallyScrollingText";

import "../styles/LandingView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

function onClickAddExtn() {
  sendAnalyticsData(ANALYTICS_EVENT.EXTENSION_DOWNLOAD, {});
}

function onClickSignUp() {
  sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_LANDING_PAGE_SIGNUP, {});
}

export const LandingView = () => {
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_LANDING_PAGE, {});
  }, [utm]);

  return (
    <>
      <div>
        <Navbar>
          <Navbar.Brand>
            <a href="https://www.unlockkeye.com/">
              <img
                src="/images/keye_logo.png"
                alt="Small blue Keye icon"
                id="keye-logo-nav"
                style={{
                  width: "150px",
                  marginBottom: isMobile ? "0" : "60px",
                }}
              />
            </a>
          </Navbar.Brand>
        </Navbar>
      </div>

      <div
        className="section-1"
        style={{
          background: isMobile ? "#f4f8ff" : "_",
          padding: isMobile ? "3rem 2rem" : "4rem 2.5rem",
        }}>
        <h1>
          Your favorite{" "}
          <span style={{ color: "#175FD3" }}> premium content </span>
          <br />
          All in <span style={{ color: "#175FD3" }}> one place </span>
          <br />
          For one <span style={{ color: "#175FD3" }}> low price </span>
        </h1>
        <p style={{ fontSize: "24px", marginTop: "50px" }}>
          Instantly access thousands of books, videos and classes by premium
          <br />
          services with the click of a button. No subscription required.
        </p>
        <Row>
          <Col xs="12" sm="8" md="6">
            <Button
              type="submit"
              className="btn btn-primary"
              id="submit-signup-btns"
              onClick={() => {
                onClickSignUp();
                history.push("/register");
              }}>
              Try it FREE
            </Button>
            <ul className="list" style={{ marginLeft: ".75rem" }}>
              <li>No credit card needed</li>
              <li>No hidden charges</li>
              <li>Cancel anytime</li>
            </ul>
          </Col>
          <Col xs="12" sm="4" md="4">
            <img
              src="images/tab-view.png"
              style={{
                display: isMobile ? "none" : "",
              }}
              alt=""
            />
          </Col>
        </Row>
      </div>

      <div className={`section-2 ${isMobile ? "mobileHide" : ""}`}>
        <h1>Unlimited content on your terms</h1>

        <div className="box">
          <img
            src="images/landing-unlimited-content.svg"
            className="graphic"
            alt=""
          />
          <p>
            Get more for less when you use Keye to access over 30 premium
            <br />
            websites and tools, all in one place, for a fraction of the cost.
          </p>
        </div>

        {!isMobile && (
          <Row
            className="partners no-gutters"
            style={{ flexDirection: isMobile ? "column" : "row" }}>
            <Col>
              <img src="/images/partner_logo/crunchbase_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/masterclass_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/transkriptor_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/picsart_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/information_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/blinkist_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/techcrunch_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/many_more_landing.png" alt="" />
            </Col>
          </Row>
        )}
      </div>

      <div
        className="section-3"
        style={{
          padding: isMobile
            ? "3.5rem 1.5rem 7.5rem 1.5rem"
            : "5rem 7.5rem 7.5rem 7.5rem",
        }}>
        <h1>
          Your favorite <span style={{ color: "#175FD3" }}>premium</span>{" "}
          content
        </h1>
        <h3>
          Access everything you need and use Keye <b> as much as you want</b>,
          for a limited time only.
        </h3>
        <img src="images/landing-premium-content.png" />
        <p style={{ fontSize: "2rem", margin: 0, paddingTop: "5rem" }}>
          …and many more
        </p>
      </div>

      <div className={isMobile ? "section-4 p-2" : "section-4 p-5"}>
        <h1>
          🗝️ <b style={{ color: "#175FD3" }}>Keye</b> is a better way to access
          <br />
          premium tools & content
        </h1>

        <h2>How It Works</h2>

        <div className="accordion">
          {accordionCreditInfo.map(
            ({
              title,
              content,
              image,
              video,
              imageAlt,
              imagePosition,
              multipleImages,
            }) => (
              <Accordion
                key={title}
                title={title}
                content={content}
                image={image}
                video={video}
                imageAlt={imageAlt}
                imagePosition={imagePosition}
                multipleImages={multipleImages}
              />
            )
          )}
        </div>
      </div>

      <div className="section-5">
        <h1>Sign up to Keye</h1>

        <Row
          className="footer-section"
          style={{
            padding: isMobile
              ? "1.5rem 1.5rem 7.5rem 1.5rem"
              : "7.5rem 7.5rem 2rem 7.5rem",
          }}>
          <Col
            xs="12"
            sm="12"
            md="6"
            style={{
              textAlign: isMobile ? "center" : "left",
            }}>
            <p>
              Sick of trying to game your way into paying less for
              subscriptions?
              <br />
              Stop making fake email addresses and join Keye now!
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                window.scrollTo(0, 0);
                onClickSignUp();
                history.push("/register");
              }}>
              Sign up
            </Button>
          </Col>
          <Col
            md="6"
            style={{
              display: isMobile ? "none" : "block",
            }}>
            <img src="images/section-5.png" alt="" />
          </Col>
        </Row>
      </div>
    </>
  );
};
