import React, { MutableRefObject, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import Carousel from "nuka-carousel";
import { Accordion } from "./HelpView";
import { accordionCreditInfo } from "./HelpViewInfo";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";
import { isMobile } from "react-device-detect";
import { Header } from "../components/Header";
import { SignUpInputField } from "../components/SignUpInputField";
import { VerticallyScrollingText } from "../components/VerticallyScrollingText";

import "../styles/LandingForStudentsView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

function onClickSignUp() {
  sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_LANDING_PAGE_STUDENTS_SIGNUP, {});
}

export const LandingForStudentsView = () => {
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_LANDING_PAGE_STUDENTS, {});
  }, [utm]);

  return (
    <>
      <div>
        <Navbar>
          <Navbar.Brand>
            <a href="https://www.unlockkeye.com/">
              <img
                src="/images/keye_logo.png"
                alt="Small blue Keye icon"
                id="keye-logo-nav"
                style={{
                  width: "150px",
                  marginBottom: isMobile ? "0" : "60px",
                }}
              />
            </a>
          </Navbar.Brand>
        </Navbar>
      </div>

      <div
        className="section-1"
        style={{
          background: isMobile ? "#f4f8ff" : "_",
          padding: isMobile ? "3rem 2rem" : "1rem 2.5rem",
        }}>
        <h1>
          <span style={{ color: "#175FD3" }}>Keye</span> is all you need for
          school
        </h1>
        <Row>
          <Col xs="12" sm="5">
            <p>
              Get ahead of the curve with unlimited access to textbooks, grammar
              tools, online classes and more.
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                onClickSignUp();
                history.push("/register");
              }}>
              Try it FREE
            </Button>
          </Col>
          <Col xs="12" sm="7">
            <img
              src="images/landing-all-you-need-for-school.svg"
              style={{
                display: isMobile ? "none" : "",
              }}
              alt=""
            />
          </Col>
        </Row>
      </div>

      {!isMobile && (
        <div className="section-1-5">
          <Row
            className="partners no-gutters"
            style={{ flexDirection: isMobile ? "column" : "row" }}>
            <Col>
              <img src="/images/partner_logo/crunchbase_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/masterclass_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/transkriptor_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/picsart_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/information_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/blinkist_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/techcrunch_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/many_more_landing.png" alt="" />
            </Col>
          </Row>
        </div>
      )}

      <div className={`section-2 ${isMobile ? "mobileHide" : ""}`}>
        <h1>
          The ultimate student <span style={{ color: "#175FD3" }}>hack</span>
        </h1>

        <Row
          style={{
            width: isMobile ? "auto" : "82%",
          }}>
          <Col xs="12" sm="12" md="8">
            <img
              src="images/landing-ultimate-student-hack.svg"
              className="graphic"
              alt=""
            />
          </Col>
          <Col xs="12" sm="12" md="4">
            <p
              style={{
                marginTop: isMobile ? "6rem" : "7rem",
                marginLeft: isMobile ? "6rem" : "-4rem",
              }}>
              Access the biggest collection of{" "}
              <span style={{ whiteSpace: "nowrap" }}>up-to-date</span> books,
              classes and articles on the internet.
            </p>
          </Col>
        </Row>
      </div>

      <div
        className="section-3"
        style={{
          padding: isMobile
            ? "3.5rem 1.5rem 7.5rem 1.5rem"
            : "5rem 7.5rem 7.5rem 7.5rem",
        }}>
        <h1>
          Study <span style={{ color: "#175FD3" }}>smarter</span> and{" "}
          <span style={{ color: "#175FD3" }}>save</span>
        </h1>
        <h3>Get access to these sites and more</h3>
        <img src="images/landing-for-students-premium-content.png" />
      </div>

      <div className={isMobile ? "section-4 p-2" : "section-4 p-5"}>
        <h1>How It Works</h1>

        <div className="accordion">
          {accordionCreditInfo.map(
            ({
              title,
              content,
              image,
              video,
              imageAlt,
              imagePosition,
              multipleImages,
            }) => (
              <Accordion
                key={title}
                title={title}
                content={content}
                image={image}
                video={video}
                imageAlt={imageAlt}
                imagePosition={imagePosition}
                multipleImages={multipleImages}
              />
            )
          )}
        </div>
      </div>

      <div className="section-5">
        <h1>Unlock your potential</h1>

        <Row
          className="footer-section"
          style={{
            padding: isMobile
              ? "1.5rem 1.5rem 7.5rem 1.5rem"
              : "7.5rem 7.5rem 2rem 7.5rem",
          }}>
          <Col
            xs="12"
            sm="12"
            md="6"
            style={{
              textAlign: isMobile ? "center" : "left",
            }}>
            <p>
              Sick of trying to game your way into paying less for
              subscriptions? Stop making fake email addresses and join Keye now!
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                window.scrollTo(0, 0);
                onClickSignUp();
                history.push("/register");
              }}>
              Sign up
            </Button>
          </Col>
          <Col
            md="6"
            style={{
              display: isMobile ? "none" : "block",
            }}>
            <img src="images/section-5.png" alt="" />
          </Col>
        </Row>
      </div>
    </>
  );
};
