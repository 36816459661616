import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import "../styles/VerticallyScrollingText.scss";

export function VerticallyScrollingText(textItems: string[]): JSX.Element {
  const ref = useRef(null);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsScrollingUp(true);
    }, 500);

    const intervalId = setInterval(() => {
      if (ref && ref.current) {
        const listNode = ref.current as HTMLElement;

        setIsScrollingUp(false);

        const firstChild = listNode.removeChild(listNode.children[0]);
        listNode.appendChild(firstChild);

        setTimeout(() => {
          setIsScrollingUp(true);
        }, 500);
      }
    }, 1750);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className={
        "vertically-scrolling-text" + (isScrollingUp ? " scrolling-up" : "")
      }>
      <ul ref={ref}>
        {textItems.map((textItem) => (
          <li key={textItem}>{textItem}</li>
        ))}
      </ul>
    </div>
  );
}
