import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { LoginCredentials } from "../models/User";
import { loginUser } from "../scripts/createApiInstance";

import { CenterLoader } from "../components/Loader";
import { Header } from "../components/Header";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
} from "../scripts/analytics";
import { KeyeLandingPageHeader } from "../components/KeyeLandingPageHeader";
import { Row, Col } from "react-bootstrap";

import "../styles/LoginView.scss";

export function LoginView(): JSX.Element {
  const { apiInstance, setUserJWT, loader, user } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [accountInvalid, setAccountInvalid] = useState<boolean>(false);
  const history = useHistory();
  const ref = useRef(null);

  useEffect(() => {
    let rendered = ref.current !== null;
    if (rendered) {
      if (!loader && !user) {
        sendAnalyticsData(
          ANALYTICS_EVENT.ON_LOAD_LOGIN_SCREEN,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );
      }
    }
  }, [loader, user]);

  useEffect(() => {
    if (accountInvalid) {
      sendAnalyticsData(
        ANALYTICS_EVENT.ON_FAILED_LOGIN_ATTEMPT,
        eventBundleProperties(null, EVENT_TYPE.UI)
      );
    }
  }, [accountInvalid]);

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>): void {
    evt.preventDefault();
    setDisabled(true);
    // there was a user in the prod db with a blank email address and password from early in october 2021.
    // this meant that just clicking on login with empty creds was passing in prod (but not in dev since the user in question was only in prod)
    // that user is deleted now, but this is here just to prevent that from happening again.
    if (email === "" || password === "") {
      setAccountInvalid(true);
      setDisabled(false);
      return;
    }
    const loginCreds: LoginCredentials = {
      email: email.toLowerCase(),
      password: password,
    };
    loginUser(apiInstance, loginCreds)
      .then(function (resp: any) {
        const { token, fistLogin } = resp?.data;
        setUserJWT(token, fistLogin);
      })
      .catch(function (err) {
        console.log(err);
        setAccountInvalid(true);
      })
      .finally(function () {
        setDisabled(false);
      });
  }
  const formGroupClass = "form-group m-3";

  return user ? (
    <Redirect to={{ pathname: "/home" }} />
  ) : loader ? (
    <CenterLoader />
  ) : (
    <>
      <Header />

      <div className="login-view">
        <Row className="no-gutters">
          <Col xs={1} sm={2} md={2} lg={3} xl={4}></Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            lg={6}
            xl={4}
            style={{ margin: "6rem auto" }}>
            <div style={{ textAlign: "center" }}>
              <h1>Welcome back!</h1>
              <h2>
                Please enter your details to access Keye Products in no time
              </h2>
            </div>

            <form onSubmit={handleSubmit} ref={ref}>
              <fieldset disabled={disabled}>
                <div className={formGroupClass}>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={formGroupClass}>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <Row className="no-gutters">
                  <Col xs={6}>
                    {accountInvalid && (
                      <p className="invalid-feedback d-block p-0 mt-0 mx-3 mb-3 text-nowrap">
                        Invalid credentials
                      </p>
                    )}
                  </Col>
                  <Col xs={6}>
                    <p
                      className="mx-3 mb-3 text-nowrap"
                      style={{ textAlign: "right" }}>
                      <Link
                        to="/reset"
                        onClick={() => {
                          sendAnalyticsData(
                            ANALYTICS_EVENT.ON_CLICK_FORGOT_PASSWORD,
                            eventBundleProperties(null, EVENT_TYPE.UI)
                          );
                        }}>
                        Forgot your password?
                      </Link>
                    </p>
                  </Col>
                </Row>
                <div className="mx-3 mt-1">
                  <button
                    type="submit"
                    className="btn w-100"
                    id="submit-signup-btns">
                    Login
                  </button>
                </div>
              </fieldset>
            </form>
            <p className={[formGroupClass, "px-3"].join(" ")}>
              New to Keye? <Link to="/register">Create an account</Link>
            </p>
            <button
              className="btn btn-primary google"
              onClick={() => {
                apiInstance
                  .get(
                    [
                      `${process.env.REACT_APP_API_SERVER_URL}`,
                      `/oauth2/authorization-url`,
                    ]
                      .map((p) => p.replace(/^\/|\/$/g, ""))
                      .join("/")
                  )
                  .then((response) => {
                    window.location = response.data.authorizationUrl;
                  })
                  .catch(console.error);
              }}>
              Sign in with Google
            </button>
          </Col>
          <Col xs={1} sm={2} md={2} lg={3} xl={4}></Col>
        </Row>
      </div>
    </>
  );
}
