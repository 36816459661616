import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import { Product, ProductScreenshot } from "../../models/Product";
import { getProductScreenshots } from "../../scripts/createApiInstance";
import { ProductDescription } from "./ProductDescription";
import { ProductImages } from "./ProductImages";

export default function ProductInfo({
  product,
}: {
  product: Product;
}): JSX.Element {
  const [screenshotsLoader, setScreenshotsLoader] = useState(false);
  const [productScreenshots, setProductScreenshots] = useState<
    ProductScreenshot[]
  >([]);
  const productId = product.id;

  const { apiInstance } = useContext(AuthContext);

  useEffect(() => {
    async function getProductScreenshotsOnLoad() {
      try {
        setScreenshotsLoader(true);
        const productScreenshotsResponse = await getProductScreenshots(
          apiInstance,
          productId
        );
        setProductScreenshots(productScreenshotsResponse.data);
      } catch (err) {
        console.log("Error getting product screenshots", err);
      } finally {
        setScreenshotsLoader(false);
      }
    }
    getProductScreenshotsOnLoad();
  }, [apiInstance, productId]);
  return (
    <>
      <Row>
        {screenshotsLoader ? (
          <div>Loading...</div>
        ) : (
          <ProductImages product={product} screenshots={productScreenshots} />
        )}
      </Row>
      <Row>
        <ProductDescription product={product} />
      </Row>
    </>
  );
}
