import { Row, Col } from "react-bootstrap";
import { Product, WaitlistDetails } from "../../models/Product";
import { displayDate } from "../../scripts/util";

export function ProductAccountWaitlistPage({
  product,
  waitlistDetails,
}: {
  product: Product;
  waitlistDetails: WaitlistDetails;
}): JSX.Element {
  return (
    <div>
      <h2 id="success-title-text">Success! You're on our waitlist.</h2>
      <Row>
        <Col md={8}>
          <h2 id="success-product-text">{product.name}</h2>
          <p className="waitlist-text">
            Next access is available on{" "}
            {displayDate(waitlistDetails.scheduledTime)}
          </p>
          <br></br>
          <p className="waitlist-text">
            You will receive your login information as soon as your account is
            ready.
          </p>
          <br></br>
          <p className="waitlist-text">
            We will email you when your account is available.
          </p>
          <p className="spam-waitlist-text">
            Please add contact@keye.co to your address book to make sure the
            email doesn't go to spam.
          </p>
        </Col>

        <Col md={4}>
          <img src="/images/welcome_message.png" alt="Welcome!" />
        </Col>
      </Row>
    </div>
  );
}
