import flagsmith from "flagsmith";
import { User } from "../models/User";

export const initFlagsmith = () => {
  var flagsmithID: string = process.env.REACT_APP_FLAGSMITH_ID
    ? process.env.REACT_APP_FLAGSMITH_ID
    : "";
  flagsmith.init({
    environmentID: flagsmithID,
  });
};

export const identifyUser = (user: User) => {
  return flagsmith.identify(user.email);
};

export enum FLAGS {
  TEST_FEATURE_FLAG = "test_feature_flag",
  CREDIT_SYSTEM = "credit_system",
}
