import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  BuySubscription,
  // BuySubscriptionMobile,
} from "./registerHelpView/buy-subscriptions";
import Faqs from "./registerHelpView/faqs";
import {
  HeroSection,
  // HeroSectionMobile,
} from "./registerHelpView/hero-section";
import {
  JoinBetaBanner,
  // JoinBetaBannerMobile,
} from "./registerHelpView/join-beta-banner";

const useQuery = () => new URLSearchParams(useLocation().search);

export function RegisterHelpView(): JSX.Element {
  document.body.style.backgroundColor = "white";
  // const [showMobileMenu, setShowMobileMenu] = useState(false);
  // const [modalVisible, setModalVisible] = useState(false);
  let query = useQuery();
  let invite: any;
  const [inviteId, setInviteId] = useState<string>("");
  for (var pair of query.entries()) {
    if (pair[0] === "invite-id") {
      invite = pair[1];
    }
  }

  useEffect(() => {
    setInviteId(invite);
    console.log("invite : ", invite);
  }, [invite]);

  return (
    //   <MediaContextProvider>
    //     <Media greaterThanOrEqual="md">
    <div className="flex flex-col">
      {/* {modalVisible ? <Modal onBackdropPress={() => setModalVisible(false)} /> : null} */}
      <div className="fixed w-full bg-white opacity-100 bg-opacity-100 z-30">
        {/* <Header onPress={() => setModalVisible(true)} /> */}
      </div>
      <div className={"mt-4"}>
        <HeroSection />
        <BuySubscription />
        <Faqs />
        <JoinBetaBanner invite={inviteId} />
        {/* <Footer /> */}
      </div>
    </div>
    //     </Media>
    //   </MediaContextProvider>
  );
}
