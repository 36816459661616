import posthog from "posthog-js";
import { User } from "../models/User";
import { currentTime, getCurrentEpoch } from "./util";
import {
  Frequency,
  Product,
  ProductSurvey,
  ProductSurveyComponent,
} from "../models/Product";

export enum EVENT_TYPE {
  UI = "UI",
  UA = "UA",
}

export enum ANALYTICS_EVENT {
  LOGGED_IN = "LOGGED_IN",
  SIGNED_OUT = "SIGNED_OUT",
  ON_LOAD_LOGIN_SCREEN = "ON_LOAD_LOGIN_SCREEN",
  ON_LOAD_SIGNUP_PAGE = "ON_LOAD_SIGNUP_PAGE",
  ON_LOAD_HOME_SCREEN = "ON_LOAD_HOME_SCREEN",
  ON_LOAD_LANDING_PAGE = "ON_LOAD_LANDING_PAGE",
  ON_SUBMIT_PRODUCT_DETAILS_REQUEST = "ON_CLICK_HOMEPAGE_PRODUCT_CARD_ACCESS",
  ON_LOAD_PRODUCTPAGE = "ON_LOAD_PRODUCTPAGE",
  ON_SUBMIT_ACCESS_REQUEST = "ON_CLICK_PRODUCTPAGE_BUY_ACCESS",
  ON_SUBMIT_GO_TO_PRODUCT_SITE = "ON_CLICK_PRODUCTPAGE_AUTO_LOGIN",
  ON_LOAD_ACCESS_REQUEST_PAGE = "ON_LOAD_ACCESS_REQUEST_PAGE",
  ON_SUBMIT_CONFIRM_ACCESS = "ON_CLICK_PRODUCTPAGE_ACTIVATE_ACCESS",
  ON_SUBMIT_ACCEPT_TERMS = "ON_SUBMIT_ACCEPT_TERMS",
  ON_SUBMIT_FEEDBACK_SURVEY = "ON_SUBMIT_FEEDBACK_SURVEY",
  ON_SUBMIT_FEEDBACK_SURVEY_COMPONENT = "ON_SUBMIT_FEEDBACK_SURVEY_COMPONENT",
  ON_SUBMIT_JOIN_WAITLIST = "ON_SUBMIT_JOIN_WAITLIST",
  ON_SUBMIT_BACK = "ON_SUBMIT_BACK",
  ON_REQUEST_NEW_PRODUCT = "ON_REQUEST_NEW_PRODUCT",
  ON_SUBMIT_COMING_SOON_PRODUCT = "ON_SUBMIT_COMING_SOON_PRODUCT",
  ON_CLICK_TAB = "ON_CLICK_TAB",
  ON_CLICK_SIGN_UP_BETA = "ON_CLICK_SIGN_UP_BETA",
  ON_CLICK_ACCEPT_TERMS = "ON_CLICK_ACCEPT_TERMS",
  ON_SIGNUP_USER = "SIGNUP_USER",
  ON_CLICK_QUICK_VIEW = "ON_CLICK_HOMEPAGE_PRODUCT_CARD",
  EXTENSION_DOWNLOAD = "EXTENSION_DOWNLOAD",
  ON_CLICK_SIGN_OUT = "ON_CLICK_SIGN_OUT",
  ON_LOAD_MAIN_PAGE = "ON_LOAD_MAIN_PAGE",
  ON_LOAD_SUBSCRIBE_PAGE = "ON_LOAD_SUBSCRIBE_PAGE",
  ON_LOAD_ACTIVATE_PAGE = "ON_LOAD_ACTIVATE_PAGE",
  ON_LOAD_PAYMENT_COMPLETE_PAGE = "ON_LOAD_PAYMENT_COMPLETE_PAGE",
  ON_CLICK_FORGOT_PASSWORD = "ON_CLICK_FORGOT_PASSWORD",
  ON_CLICK_NAVBAR_LOGIN = "ON_CLICK_NAVBAR_LOGIN",
  ON_CLICK_NAVBAR_HOW_KEYE_WORKS = "ON_CLICK_NAVBAR_HOW_KEYE_WORKS",
  ON_CLICK_NAVBAR_PRODUCTS_ON_KEYE = "ON_CLICK_NAVBAR_PRODUCTS_ON_KEYE",
  ON_CLICK_MAIN_PAGE_GET_KEYE_FREE = "ON_CLICK_MAIN_PAGE_GET_KEYE_FREE",
  ON_CLICK_MAIN_PAGE_CONNECT = "ON_CLICK_MAIN_PAGE_CONNECT",
  ON_FAILED_LOGIN_ATTEMPT = "ON_FAILED_LOGIN_ATTEMPT",
  ON_CLICK_RESET_PASSWORD_CONFIRM = "ON_CLICK_RESET_PASSWORD_CONFIRM",
  ON_CLICK_SIGNUP_PAGE_CONTINUE = "ON_CLICK_SIGNUP_PAGE_CONTINUE",
  ON_LOAD_SIGNUP_PAGE_STAGE_2 = "ON_LOAD_SIGNUP_PAGE_STAGE_2",
  ON_CLICK_SIGNUP_PAGE_STAGE_2_CONTINUE = "ON_CLICK_SIGNUP_PAGE_STAGE_2_CONTINUE",
  ON_LOAD_LANDING_PAGE_STUDENTS = "ON_LOAD_LANDING_PAGE_STUDENTS",
  ON_LOAD_LANDING_PAGE_FULLTIME_EMPLOYED = "ON_LOAD_LANDING_PAGE_FULLTIME_EMPLOYED",
  ON_LOAD_LANDING_PAGE_SELF_EMPLOYED = "ON_LOAD_LANDING_PAGE_SELF_EMPLOYED",
  ON_LOAD_LANDING_PAGE_EXTENSION = "ON_LOAD_LANDING_PAGE_EXTENSION",
  ON_CLICK_LANDING_PAGE_STUDENTS_SIGNUP = "ON_CLICK_LANDING_PAGE_STUDENTS_SIGNUP",
  ON_CLICK_LANDING_PAGE_FULLTIME_EMPLOYED_SIGNUP = "ON_CLICK_LANDING_PAGE_FULLTIME_EMPLOYED_SIGNUP",
  ON_CLICK_LANDING_PAGE_SELF_EMPLOYED_SIGNUP = "ON_CLICK_LANDING_PAGE_SELF_EMPLOYED_SIGNUP",
  ON_CLICK_LANDING_PAGE_SIGNUP = "ON_CLICK_LANDING_PAGE_SIGNUP",
  ON_CLICK_LANDING_PAGE_EXTENSION_SIGNUP = "ON_CLICK_LANDING_PAGE_EXTENSION_SIGNUP",
  ON_LOAD_ONBOARDING_PAGE = "ON_LOAD_ONBOARDING_PAGE",
  ON_CLICK_ONBOARDING_PAGE_SKIP = "ON_CLICK_ONBOARDING_PAGE_SKIP",
  ON_CLICK_LANDING_PAGE_EXTENSION_SKIP = "ON_CLICK_LANDING_PAGE_EXTENSION_SKIP",
}

// Timeframe on when the user hit the visit access
export enum VISIT_TIMEFRAME {
  PREACCESS = "PREACCESS",
  POSTACCESS = "POSTACCESS",
  HOMEPAGE = "HOMEPAGE",
}

export const initAnalytics = () => {
  posthog.init(`${process.env.REACT_APP_POSTHOG_PROJECT_API_KEY}`, {
    api_host: "https://app.posthog.com",
  });
};

export const setAnalyticsUserId = (user: User) => {
  console.log("posthog id : ", user);
  posthog.identify(user.id, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    userCategory: user.userCategory,
  });
  // amplitude.getInstance().setUserId(userId);
};

export const sendAnalyticsData = (eventType: string, eventProperties: any) => {
  const hevoDataRequest = {
    event: eventType,
    properties: eventProperties,
  };
  sendHevoData(hevoDataRequest);
  posthog.capture(eventType, eventProperties);
  // amplitude.getInstance().logEvent(eventType, eventProperties);
};

declare global {
  interface Window {
    analytics: any;
  }
}

export const sendHevoData = (hevoDataRequest: any) => {
  // axios
  //   .post("https://us-webhook.hevodata.com/t/8bxjl8dbs6", hevoDataRequest)
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  window.analytics?.identify(hevoDataRequest.properties.uid, {
    ...hevoDataRequest.properties,
  });
  window.analytics.track(hevoDataRequest.event, {
    ...hevoDataRequest.properties,
  });
};

export const sendEvent = (eventType: string) => {
  posthog.capture(eventType);
  // amplitude.getInstance().logEvent(eventType);
};

export const eventBundleProperties = (user: User | null, etype: EVENT_TYPE) => {
  return {
    uid: user?.id,
    email: user?.email,
    testUser: user?.testUser,
    ets: getCurrentEpoch(),
    etype: etype,
    timestamp: currentTime(),
  };
};

export const productDetailsBundle = (product: Product, status: string) => {
  return {
    pid: product.id,
    pname: product.name,
    pofferid: product.price,
    pcat: product.category,
    pstatus: status,
  };
};

export const accessDetailsBundle = (product: Product) => {
  return {
    pid: product.id,
    aperiod: Frequency[product.frequency],
  };
};

export const feedbackBundle = (survey: ProductSurvey) => {
  return {
    sarray: survey.survey,
    pid: survey.productId,
    accesstype: survey.productSurveyType,
  };
};
export const feebackComponentBundle = (
  component: ProductSurveyComponent,
  feedbackID: string
) => {
  return {
    question: component.question,
    type: component.type,
    options: component.options,
    selectedOption: component.selectedOption,
    feedbackID: feedbackID,
  };
};
