import { Product } from "../../models/Product";
import {
  getBackgroundColor,
  getTextColor,
  toTitleCase,
} from "../../scripts/util";
import { useContext, useState } from "react";

import { AuthContext } from "../../context/AuthContext";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  getComingSoonProductNotificationsForUser,
  subscribeToComingSoonNotifications,
} from "../../scripts/createApiInstance";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  productDetailsBundle,
  sendAnalyticsData,
} from "../../scripts/analytics";
import { ProductContext } from "../../context/ProductContext";

import "../../styles/ProductCardStyles/ProductCards.scss";
import "../../styles/ProductCardStyles/ComingSoonProductCard.scss";

export function ComingSoonProductCard({
  product,
  notification,
}: {
  product: Product;
  notification: String[];
}): JSX.Element | null {
  const [isSubscribedToNotifications, setIsSubscribedToNotifications] =
    useState(notification.length > 0);

  const { apiInstance, user } = useContext(AuthContext);
  const { setComingSoonProductNotifications } = useContext(ProductContext);

  function sendComingSoonProductNotificationEvent() {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_COMING_SOON_PRODUCT, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, "COMING_SOON"),
    });
  }

  async function handleNotificationSubscription() {
    const productIdArray = [product.id];
    try {
      sendComingSoonProductNotificationEvent();
      await subscribeToComingSoonNotifications(apiInstance, productIdArray);
      setIsSubscribedToNotifications(true);
      const notifications = await getComingSoonProductNotificationsForUser(
        apiInstance
      );
      setComingSoonProductNotifications(notifications.data);
    } catch (e) {
      console.log("Error subscribing to notifications: ", e);
    }
  }

  return (
    <Card id="product-card">
      <Card.Img
        variant="top"
        src={product.imgUrl}
        alt={product.name}
        id="product-card-image"
      />

      <Card.Body
        className="d-flex flex-column"
        id="coming-soon-product-card-body">
        <div id="coming-soon-product-card-top-row">
          <Card.Text as="h5" id="coming-soon-product-card-name">
            {product.name}
          </Card.Text>
          <Card.Text as="h5" id="coming-soon-flag">
            COMING SOON{" "}
          </Card.Text>
        </div>
        <Card.Text>
          <Button
            disabled
            id="decorative-category-pills"
            style={{
              backgroundColor: getBackgroundColor(String(product.category)),
              color: getTextColor(String(product.category)),
            }}>
            {toTitleCase(String(product.category))}
          </Button>
        </Card.Text>
        <Card.Text>{product.shortDescription}</Card.Text>
      </Card.Body>
      {!isSubscribedToNotifications && (
        <OverlayTrigger
          placement="top"
          trigger={["hover", "focus", "click"]}
          overlay={
            <Tooltip>We will email you when this product is available</Tooltip>
          }>
          <Button
            id="coming-soon-product-card-action-button"
            onClick={handleNotificationSubscription}>
            NOTIFY ME
          </Button>
        </OverlayTrigger>
      )}
      {isSubscribedToNotifications && (
        <OverlayTrigger
          placement="top"
          trigger={["hover", "focus", "click"]}
          overlay={
            <Tooltip>We will email you when this product is available</Tooltip>
          }>
          <span id="coming-soon-product-card-action-span">
            <Button disabled id="coming-soon-product-card-action-button">
              NOTIFICATIONS ON
            </Button>
          </span>
        </OverlayTrigger>
      )}
    </Card>
  );
}
