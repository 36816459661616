import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  LoginCredentials,
  NewUser,
  ResetPasswordDetails,
  User,
  UserCredit,
} from "../models/User";
import {
  AccountAccess,
  CompletedProductSurvey,
  Product,
  ProductAccount,
  ProductAccountAccess,
  ProductCreditCost,
  ProductReservationDetails,
  ProductScreenshot,
  ProductServiceUseStats,
  ProductSurvey,
  ProductSurveyQuestionairre,
  RequestSearchQuery,
  SearchQuery,
  UserTransactionRecord,
  WaitlistDetails,
  WaitlistStatus,
} from "../models/Product";

export function createApiInstance(jwt: string) {
  return axios.create({
    baseURL: getBaseURL(),
    headers: { Authorization: `Bearer ${jwt}` },
  });
}

// USER ENDPOINTS

export function createUser(
  api: AxiosInstance,
  newUser: NewUser
): Promise<AxiosResponse<User>> {
  return api.post<User>(`user`, newUser);
}

export function getCurrentUser(
  api: AxiosInstance
): Promise<AxiosResponse<User>> {
  return api.get<User>(`user`);
}

export function loginUser(
  api: AxiosInstance,
  loginCreds: LoginCredentials
): Promise<AxiosResponse<string>> {
  return api.post<string>(`auth/login`, loginCreds);
}

export function sendResetPasswordLink(
  api: AxiosInstance,
  email: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`user/resetpassword/link?email=${email}`);
}

export function resetPassword(
  api: AxiosInstance,
  details: ResetPasswordDetails
): Promise<AxiosResponse<void>> {
  return api.post<void>(`user/resetpassword`, details);
}

// PRODUCT ENDPOINTS

/**
 * Gets all Products that have a ProductStatus equal to ACTIVE (not COMING_SOON or INACTIVE)
 * Requires valid user but the products returned are not specific to the user.
 * @param api - authenticates the user
 */
export function getProductsForUser(
  api: AxiosInstance
): Promise<AxiosResponse<Product[]>> {
  return api.get<Product[]>(`product`);
}

export function getProduct(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<Product>> {
  return api.get<Product>(`product/${productId}`);
}
/**
 * This calls the getProductAccessForUser method in the productService backend. This validates the productID, checking to the Account_Access table and Product_Account table.
 * It then calls toProductAccountWithAccessDetails(rec) function in the backend and returns the full ProductAccount, which is associated with the user and productID.
 * This product account is the actual login / password for the product, etc. Not explicitly tied to user in frontend.
 * @param api - authenticates the user
 * @param productId
 * @returns - a promise that resolves to a ProductAccount object
 */
export function getProductAccountForUser(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<ProductAccount>> {
  return api.get<ProductAccount>(`product/${productId}/account`);
}

export function getProductScreenshots(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<ProductScreenshot[]>> {
  return api.get<ProductScreenshot[]>(`product/${productId}/screenshots`);
}

// ACCESS ENDPOINTS
export function startAccessForUser(
  api: AxiosInstance,
  productId: string,
  startAccess: boolean
): Promise<AxiosResponse<ProductAccount>> {
  return api.post<ProductAccount>(
    `product/${productId}/access?startAccess=${startAccess}`
  );
}

/**
 * NOTE: This returns an AccountAccess, NOT a ProductAccountAccess.
 *
 * The key difference is that while a ProductAccountAccess references both a Product and a ProductAccount,
 * an AccountAccess has no connection to any ProductAccount.
 *
 * The AccountAccess model is not directly tied to a user -- we filter for this on the backend.
 *
 * This is mostly used to look at historical accesses for a user.
 *
 * @param api - authenticates the user, used to only get AccountAccesses for the user.
 * @returns - a promise that resolves to an array of AccountAccess objects
 */

export function getAllAccessesForUser(
  api: AxiosInstance
): Promise<AxiosResponse<AccountAccess[]>> {
  return api.get<AccountAccess[]>(`product/accesses`);
}
/**
 *  Gets current ProductAccountAccesses for the user (not expired)
 * A ProductAccountAccess consists of a Product and a Product Account. Neither of these
 * is directly tied to a user -- we filter this on the backend.
 * @param api - authenticates the user, used to only get ProductAccountAccesses for the user.
 * @returns A promise that resolves to an array of ProductAccountAccess objects.
 */
export function getCurrentAccessesForUser(
  api: AxiosInstance
): Promise<AxiosResponse<ProductAccountAccess[]>> {
  return api.get<ProductAccountAccess[]>(`product/current_accesses`);
}

// WAITLIST ENDPOINTS

/**
 * For a given user and product, get any WaitlistDetails where the WaitlistStatus is equal to ACTIVE (not WITHDRAWN or FULFILLED)
 *
 * @param api - authenticates the user, used to only get WaitlistDetails for the user.
 * @param productId
 * @returns - a promise that resolves to a Waitlist object
 */
export function getWaitlistDetailsForUser(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<WaitlistDetails>> {
  return api.get<WaitlistDetails>(`product/${productId}/waitlist`);
}

/**
 * For a given user and array of productIds, get any WaitlistDetails for each product Id where the WaitlistStatus is equal to ACTIVE (not WITHDRAWN or FULFILLED)
 *
 * @param api - authenticates the user, used to only get WaitlistDetails for the user.
 * @param productIds - an array of productIds
 * @returns - a promise that resolves to an array of Waitlist objects
 */
export function getWaitlistDetailsForUserForManyProducts(
  api: AxiosInstance,
  productIds: string[]
): Promise<AxiosResponse<WaitlistDetails[]>> {
  return api.post<WaitlistDetails[]>(`product/waitlist`, productIds);
}

/**
 * For a given user and array of productIds, get any WaitlistDetails for each product Id where the WaitlistStatus is equal to ACTIVE (not WITHDRAWN or FULFILLED)
 *
 * @param api - authenticates the user, used to only get WaitlistDetails for the user.
 * @param productIds - an array of productIds
 * @returns - a promise that resolves to an array of Waitlist objects
 */
export function getWaitlistDetailsMyProducts(
  api: AxiosInstance,
  productIds: string[]
): Promise<AxiosResponse<WaitlistDetails[]>> {
  return api.post<WaitlistDetails[]>(`product/waitlist/today`, productIds);
}

export function updateWaitlistStatusForUser(
  api: AxiosInstance,
  waitlistId: string,
  status: WaitlistStatus
): Promise<AxiosResponse<string>> {
  const stringStatus = WaitlistStatus[status];
  return api.put<string>(
    `product/waitlist/${waitlistId}?status=${stringStatus}`
  );
}
// RESERVATION ENDPOINTS

/**
 * For a given user and product, get any ProductReservationDetails where the active boolean on ProductReservationDetails is true.
 * This is mostly used as an interim step between a Waitlist and an Acccess.
 *
 * @param api - authenticates the user, used to only get ProductReservationDetails for the user.
 * @param productId
 * @returns - a promise that resolves to an array of ProductReservationDetails objects
 */
export function getReservationDetailsForUser(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<ProductReservationDetails>> {
  return api.get<ProductReservationDetails>(`product/${productId}/reservation`);
}

export function getReservationDetailsForUserForManyProducts(
  api: AxiosInstance,
  productIds: string[]
): Promise<AxiosResponse<ProductReservationDetails[]>> {
  return api.post<ProductReservationDetails[]>(
    `product/reservation`,
    productIds
  );
}

// SURVEY ENDPOINTS

export function getActiveProductSurveys(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<ProductSurvey[]>> {
  return api.get<ProductSurvey[]>(`product/${productId}/survey`);
}

export function getCompletedProductSurveys(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<CompletedProductSurvey[]>> {
  return api.get<CompletedProductSurvey[]>(
    `product/${productId}/survey/completed`
  );
}

export function completeProductSurvey(
  api: AxiosInstance,
  productId: string,
  surveyId: string,
  completedQuestionairre: ProductSurveyQuestionairre
): Promise<AxiosResponse<string>> {
  return api.post<string>(
    `product/${productId}/survey/${surveyId}/complete`,
    completedQuestionairre
  );
}

export function getPendingSurveys(
  api: AxiosInstance
): Promise<AxiosResponse<ProductSurvey[]>> {
  return api.get<ProductSurvey[]>(`product/survey/pending`);
}

export function getNextAvailableTime(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<string>> {
  return api.get<string>(`product/${productId}/access/info`);
}

// COMING SOON ENDPOINTS

export function subscribeToComingSoonNotifications(
  api: AxiosInstance,
  productIds: string[]
): Promise<AxiosResponse<void>> {
  return api.post<void>(`product/comingsoon/notifications`, productIds);
}

/**
 * Gets an array of productIDs that the user has subscribed to coming soon notifications for.
 *
 * @param api - authenticates the user. used to only get ComingSoonNotifications for the user.
 * @returns - a promise that resolves to an array of strings, each of which is a productId
 */

export function getComingSoonProductNotificationsForUser(
  api: AxiosInstance
): Promise<AxiosResponse<String[]>> {
  return api.get<String[]>(`product/comingsoon/notifications`);
}

/**
 *
 * Gets all Products with the ProductStatus COMING_SOON (not ACTIVE or INACTIVE)
 * Requires valid user but the products returned are not specific to the user.
 *
 * @param api - authenticates the user
 * @returns - a promise that resolves to an array of Product objects
 */
export function getComingSoonProductsForUser(
  api: AxiosInstance
): Promise<AxiosResponse<Product[]>> {
  return api.get<Product[]>(`product/comingsoon`);
}

// REQUEST PRODUCT ENDPOINTS

export function addProductToProductUniverse(
  api: AxiosInstance,
  productNames: string[]
): Promise<AxiosResponse<void>> {
  return api.post<void>(`product/request/universe`, productNames);
}

export function searchProductUniverse(
  api: AxiosInstance,
  query: RequestSearchQuery
): Promise<AxiosResponse<string[]>> {
  return api.post<string[]>(`product/request/search`, query);
}

export function referralUser(
  api: AxiosInstance,
  query: RequestSearchQuery
): Promise<AxiosResponse<any>> {
  return api.post<any>(`user/referral`, query);
}

export function requestProductForUser(
  api: AxiosInstance,
  productNames: string[]
): Promise<AxiosResponse<void>> {
  return api.post<void>(`product/request`, productNames);
}

export function getRequestedProductsForUser(
  api: AxiosInstance
): Promise<AxiosResponse<String[]>> {
  return api.get<String[]>(`product/request`);
}

// CREDIT ENDPOINTS

/**
 * Gets the credit cost for a product. This actually is determined via external service Flagsmith.
 * We set the credit costs as a default value there, and then can update the cost for A/B testing purposes.
 *
 * We also have a ProductCreditCosts object defined in the frontend that connects the cost with the productID for easier use.
 * You can see this interface defined in the Product model and how it is used in the ProductContext.
 *
 * @param api - authenticates the user
 * @param productId - the product to look up the credit cost for
 * @returns - a promise that resolves to the credit cost of the product
 */
export function getCreditsForProduct(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<string>> {
  return api.get<string>(`product/${productId}/credits`);
}

// @ts-ignore
export function getCreditsForManyProducts(
  api: AxiosInstance,
  productIds: string[]
): Promise<AxiosResponse<ProductCreditCost[]>> {
  return api.post<ProductCreditCost[]>(`product/credits`, productIds);
}

export function getUserTransactions(
  api: AxiosInstance
): Promise<AxiosResponse<UserTransactionRecord[]>> {
  return api.get<UserTransactionRecord[]>(`credit/transaction`);
}

export function getCreditsForUser(
  api: AxiosInstance
): Promise<AxiosResponse<number>> {
  return api.get<number>(`credit`);
}

/**
 * Just sets a notification that the user has requested a credit refill. This can be fulfilled using the admin panel (Manage Credit Requests, Manage Credits)
 *
 * @param api - authenticates the user
 * @returns - a promise that has a status of 204 if the request was successful
 */
export function requestCreditsForUser(
  api: AxiosInstance
): Promise<AxiosResponse<void>> {
  return api.post<void>(`credit/request`);
}

export function hasUserRequestedMoreCredits(
  api: AxiosInstance
): Promise<AxiosResponse<boolean>> {
  return api.get<boolean>(`credit/request`);
}
// ADMIN Endpoints

export function adminGetWhitelistUsers(
  api: AxiosInstance
): Promise<AxiosResponse<string[]>> {
  return api.get<string[]>(`user/whitelist`);
}

export function adminAddWhitelistUsers(
  api: AxiosInstance,
  emails: string[]
): Promise<AxiosResponse<string[]>> {
  return api.post<string[]>(`user/whitelist`, emails);
}

export function adminCreateProductAccounts(
  api: AxiosInstance,
  productId: string,
  productAccounts: ProductAccount[]
): Promise<AxiosResponse<ProductAccount[]>> {
  return api.post<ProductAccount[]>(
    `product/${productId}/accounts`,
    productAccounts
  );
}

export function adminGetProductAccount(
  api: AxiosInstance,
  productId: string,
  available: boolean
): Promise<AxiosResponse<ProductAccount[]>> {
  return api.get<ProductAccount[]>(
    `admin/product/${productId}/accounts?available=${available}`
  );
}

export function adminGetWaitlist(
  api: AxiosInstance,
  productId: string,
  query: SearchQuery
): Promise<AxiosResponse<WaitlistDetails[]>> {
  return api.post<WaitlistDetails[]>(
    `processing/waitlist/${productId}/search`,
    query
  );
}

export function adminGetReservation(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<ProductReservationDetails[]>> {
  return api.get<ProductReservationDetails[]>(
    `processing/reservations/${productId}`
  );
}

export function deactivateRecentlyUsedAccounts(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`processing/accounts/${productId}/deactivate`);
}

export function processWaitlist(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`processing/waitlist/${productId}`);
}

export function processReservations(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`processing/reservations/${productId}`);
}

export function adminGetAllAccountAccesses(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<AccountAccess[]>> {
  return api.get<AccountAccess[]>(`admin/product/${productId}/accesses`);
}

export function adminDeactivateAccountAccess(
  api: AxiosInstance,
  accountAccessId: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`admin/product/accesses/${accountAccessId}/deactivate`);
}

export function adminDeactivateProductAccounts(
  api: AxiosInstance,
  productAccountIds: string[]
): Promise<AxiosResponse<void>> {
  return api.post<void>(`admin/product/accounts/deactivate`, productAccountIds);
}

export function adminForceStartReservation(
  api: AxiosInstance,
  reservationId: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`processing/reservations/start/${reservationId}`);
}

export function adminUpdateProductAccounts(
  api: AxiosInstance,
  productAccounts: ProductAccount[],
  makeActive: boolean
): Promise<AxiosResponse<void>> {
  return api.post<void>(
    `product/accounts?active=${makeActive}`,
    productAccounts
  );
}

export function adminGetAllUserCredits(
  api: AxiosInstance
): Promise<AxiosResponse<UserCredit[]>> {
  return api.get<UserCredit[]>(`credit/admin`);
}

export function adminSetUserCredits(
  api: AxiosInstance,
  userCredits: UserCredit[]
): Promise<AxiosResponse<void>> {
  return api.post<void>(`credit/admin/set`, userCredits);
}

export function adminUpdateProductData(
  api: AxiosInstance,
  product: Product
): Promise<AxiosResponse<Product>> {
  return api.post<Product>(`product/${product.id}`, product);
}

export function processPostAccess(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse<void>> {
  return api.post<void>(`processing/accesses/${productId}/complete`);
}

export function adminSetProductScreenshots(
  api: AxiosInstance,
  productId: string,
  screenshots: ProductScreenshot[]
): Promise<AxiosResponse<ProductScreenshot[]>> {
  return api.post<ProductScreenshot[]>(
    `product/${productId}/screenshots`,
    screenshots
  );
}

export function searchEmailAlreadyExist(
  api: AxiosInstance,
  email: any
): Promise<AxiosResponse<boolean>> {
  return api.get<boolean>(`user/query?email=${email}`);
}

export function adminGetAllUsers(
  api: AxiosInstance
): Promise<AxiosResponse<User[]>> {
  return api.get<User[]>(`user/admin/all`);
}

export function adminGetAllUsersWhoRequestedCredits(
  api: AxiosInstance
): Promise<AxiosResponse<string[]>> {
  return api.get<string[]>(`credit/admin/request`);
}

export function adminRemoveUsersWhoRequestedCredits(
  api: AxiosInstance,
  userIds: string[]
): Promise<AxiosResponse<void>> {
  return api.delete<void>(`credit/admin/request`, {
    data: userIds,
  });
}

export function adminSendEmailWithTemplate(
  api: AxiosInstance,
  templateId: string,
  emails: string[]
): Promise<AxiosResponse<void>> {
  return api.post<void>(`user/admin/email/${templateId}`, emails);
}

export function getBaseURL(): string {
  return `${process.env.REACT_APP_API_SERVER_URL}`;
}

export function isDev() {
  return getBaseURL().includes("localhost");
}

export async function getAccessTokenAPI(
  authCode: string,
  grantType: "authorization_code"
): Promise<any> {
  const req = {
    code: authCode,
    grantType,
  };

  const tokenData = await fetch("http://18.221.129.149/oauth/oauth/v1/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-auth-id": "test",
    },
    body: JSON.stringify(req),
  })
    .then((response) => response.json())
    .then((data) => data);
  return tokenData;
}

export async function getUserDetailsAPI(accessToken: string): Promise<any> {
  const userData = await fetch("http://18.221.129.149/oauth/info/v1/user", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`,
      "x-auth-id": "test",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return userData;
}

export function getAccessToken(
  api: AxiosInstance,
  productId: string
): Promise<AxiosResponse> {
  return api.get(`product/${productId}/access`);
}

export function getInvitationLinks(api: AxiosInstance): Promise<AxiosResponse> {
  return api.get(`invite/fetch-all`);
}

export function adminGetServiceUseStats(
  api: AxiosInstance
): Promise<AxiosResponse<ProductServiceUseStats[]>> {
  return api.get<ProductServiceUseStats[]>(`admin/product/statistics`);
}
