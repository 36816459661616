import React, { useContext } from "react";
import { Row } from "react-bootstrap";

import { ProductContext } from "../context/ProductContext";
import { getBackgroundColor, getTextColor, toTitleCase } from "../scripts/util";
import { Loader } from "./Loader";

import "../styles/FilterProductCategoryPills.scss";

const categoriesOrder = [
  "DATA",
  "NEWS",
  "INFORMATION",
  "PRODUCTIVITY",
  "LEARNING",
  "EDUCATION",
  "MISCELLANEOUS",
  "HEALTH",
];

export function FilterProductCategoryPills({
  setCategoryFilter,
}: {
  setCategoryFilter: (category: any) => void;
}): JSX.Element {
  const { currentProducts, currentProductsLoading } =
    useContext(ProductContext);
  const categories = currentProducts.map((product) => product.category);
  // @ts-ignore
  const uniqueCategories = [...new Set(categories)];

  // Sort the unique categories
  uniqueCategories.sort((a, b) => {
    return (
      categoriesOrder.indexOf(toTitleCase(a).toUpperCase()) -
      categoriesOrder.indexOf(toTitleCase(b).toUpperCase())
    );
  });

  return (
    <>
      {currentProductsLoading ? (
        <Loader />
      ) : (
        <Row
          xs="auto"
          className="justify-content-start align-items-baseline mt-5">
          <p id="filter-intro">Show:</p>

          <button
            id="category-pills"
            onClick={() => setCategoryFilter("")}
            style={{
              backgroundColor: getBackgroundColor("None"),
              color: getTextColor("None"),
            }}>
            All
          </button>

          {uniqueCategories.map((category) => (
            <button
              id="category-pills"
              key={category}
              onClick={() => setCategoryFilter(category)}
              style={{
                backgroundColor: getBackgroundColor(category),
                color: getTextColor(category),
              }}>
              {toTitleCase(category)}
            </button>
          ))}
        </Row>
      )}
    </>
  );
}
