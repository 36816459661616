import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export function OAuth2(): JSX.Element {
  const { apiInstance, setUserJWT } = useContext(AuthContext);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const authCode: string = query.get("code") || "";

  apiInstance
    .post(
      [`${process.env.REACT_APP_API_SERVER_URL}`, `/oauth2/token`]
        .map((p) => p.replace(/^\/|\/$/g, ""))
        .join("/"),
      {
        authorizationCode: authCode,
      }
    )
    .then((response) => {
      const { token, fistLogin } = response?.data;
      setUserJWT(token, fistLogin);
      // Redirect user to dashboard
      history.push("/home");
    })
    .catch(() => {
      // Take user back to login page if something went wrong
      history.push("/login");
    });

  return <></>;
}
