import { useContext } from "react";
import { Product } from "../../models/Product";
import { AccessProductCard } from "./AccessProductCard";
import { ShoppingProductCard } from "./ShoppingProductCard";
import { WaitlistProductCard } from "./WaitlistProductCard";
import { CenterLoader } from "../Loader";
import { ProductContext } from "../../context/ProductContext";

import "../../styles/ProductCardStyles/ProductCards.scss";

/**
 * Similar to the ProductView, the main purpose of this product card is to determine what type of product card
 * we ultimately want to display -- Shopping, Waitlist, or Access.
 *
 *
 */
export function ProductCard({
  product,
  setSelectedProduct,
  selectedProduct,
}: {
  product: Product;
  setSelectedProduct: (product: Product | undefined) => void;
  selectedProduct: Product | undefined;
}) {
  const {
    allProductWaitlistDetails,
    allProductWaitlistDetailsLoading,
    currentProductAccesses,
    currentProductAccessesLoading,
    allProductReservationDetails,
    allProductReservationDetailsLoading,
    allProductCreditCosts,
    allProductCreditCostsLoading,
  } = useContext(ProductContext);

  const productAccess = currentProductAccesses.find((access) => {
    return access.product.id === product.id;
  });

  const waitlistDetails = allProductWaitlistDetails.find((waitlist) => {
    return waitlist.productId === product.id;
  });

  const reservationDetails = allProductReservationDetails.find(
    (reservation) => {
      return reservation.productId === product.id;
    }
  );

  // wherever the creditCost is used (right now, the three different types of product cards (access, shopping, waitlist) as well as the product page)
  // we must conditionally display the cost (e.g. creditCost && creditCost.creditCost)
  const creditCost = allProductCreditCosts.find((credit) => {
    // eslint-disable-next-line eqeqeq
    return credit.productId == product.id;
  });

  const isWaitlistCard =
    waitlistDetails && waitlistDetails.waitlistStatus.toString() === "ACTIVE";

  const isAccessCard = productAccess;

  const loading =
    allProductWaitlistDetailsLoading ||
    currentProductAccessesLoading ||
    allProductReservationDetailsLoading ||
    allProductCreditCostsLoading;

  return loading ? (
    <CenterLoader />
  ) : isWaitlistCard ? (
    <WaitlistProductCard
      product={product}
      waitlistDetails={waitlistDetails}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      creditCost={creditCost}
    />
  ) : isAccessCard ? (
    <AccessProductCard
      product={product}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      creditCost={creditCost}
    />
  ) : (
    <ShoppingProductCard
      product={product}
      reservationDetails={reservationDetails}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      creditCost={creditCost}
    />
  );
}
