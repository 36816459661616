import React from "react";

export const BuySubscription = () => {
  return (
    <div className="bg-primary-half pt-9 pb-9 mx-auto align-middle">
      <div className="bg-white shadow-button px-4 mx-auto rounded-2xl">
        <div className="relative pt-6 pb-6 flex items-center justify-between mx-auto align-middle">
          <div className="my-auto z-10">
            <h1 className="font-bold font-gilroy ">
              Buying subscriptions on Keye is
            </h1>
            <h1 className="font-bold font-gilroy ">
              <span className="text-primary">Easier</span>.{" "}
              <span className="text-primary">Than</span>.{" "}
              <span className="text-primary">Ever</span>.
            </h1>
            <div className="mt-5">
              <img src="/images/easier-than-ever.png" alt="" />
            </div>
          </div>
          <div unselectable="on" className="align-middle">
            <div className="relative 1400:w-16 1161:w-15 z-10 my-auto mx-auto">
              <img
                src="/images/buy-subscriptions.gif"
                alt=""
                unselectable="on"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BuySubscriptionMobile = () => {
  return (
    <div className="bg-primary-half pt-6 pb-6">
      <div className="bg-white shadow-button px-4 lg:px-8 mx-4 my-6 rounded-2xl">
        <div className="relative container pt-4 pb-4 flex flex-col items-center justify-between mx-auto max-w-screen-1920 align-middle">
          <h1 className="self-start font-bold font-gilroy sm:text-larger text-medium">
            Buying subscriptions on Keye is
          </h1>
          <h1 className="self-start font-bold font-gilroy sm:text-larger text-medium">
            <span className="text-primary">Easier</span>.{" "}
            <span className="text-primary">Than</span>.{" "}
            <span className="text-primary">Ever</span>.
          </h1>
          <div unselectable="on" className="align-middle">
            <div className="relative 1400:w-16 1161:w-15 z-10 my-auto mx-auto mt-4">
              <img
                src="/images/buy-subscriptions.gif"
                alt=""
                unselectable="on"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
