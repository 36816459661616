import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ProductAccount } from "../../models/Product";

import "../../styles/ProductCardStyles/AccessProductCard.scss";

export interface LoginCredentialsProps {
  productAccount: ProductAccount;
}

export function LoginCredentials({ productAccount }: LoginCredentialsProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [usernameIsCopied, setUsernameIsCopied] = useState<boolean>(false);
  const [passwordIsCopied, setPasswordIsCopied] = useState<boolean>(false);
  return (
    <div id="access-product-credentials-div">
      {productAccount.productId.toString() === "38" ? (
        ""
      ) : (
        <>
          <p id="access-product-credentials-header">Login Credentials</p>
          <div id="username-div">
            <p className={"access-text-message"}>
              Username: {console.log(productAccount.productId)}
              <span id="access-product-un-pw">{productAccount.username}</span>
            </p>
            <CopyToClipboard
              text={productAccount.username}
              onCopy={() => setUsernameIsCopied(true)}>
              <i
                className="fa fa-clipboard"
                id="access-product-copy-un-icon"
                aria-hidden="true">
                {usernameIsCopied ? "Copied!" : "Copy"}
              </i>
            </CopyToClipboard>
          </div>
          <div id="pw-reveal-div">
            <p className={"access-text-message"}>Password:</p>
            {open ? (
              <div>
                <p id="access-product-un-pw">{productAccount.password}</p>
              </div>
            ) : (
              <p id="access-product-un-pw">
                {
                  "\u00A0\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u00A0\u00A0 "
                }
              </p>
            )}

            <i
              onClick={() => setOpen(!open)}
              className={open ? "fa fa-eye-slash fa-lg" : "fa fa-eye fa-lg"}
              id="password-revealing-icon"></i>

            <CopyToClipboard
              text={productAccount.password}
              onCopy={() => setPasswordIsCopied(true)}>
              <i
                className="fa fa-clipboard"
                id="access-product-copy-pw-icon"
                aria-hidden="true">
                {passwordIsCopied ? "Copied!" : "Copy"}
              </i>
            </CopyToClipboard>
          </div>
        </>
      )}
    </div>
  );
}
