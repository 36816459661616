import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Countdown from "react-countdown-now";
import { useHistory, useLocation } from "react-router-dom";
import { KeyeLandingPageHeader } from "../components/KeyeLandingPageHeader";
import { CenterLoader } from "../components/Loader";
import { AuthContext } from "../context/AuthContext";
import {
  getAccessTokenAPI,
  getUserDetailsAPI,
} from "../scripts/createApiInstance";

import "../styles/ResetView.scss";

const Completionist = ({ setUserlogOut }: { setUserlogOut: any }) => {
  useEffect(() => {
    setUserlogOut(true);
  }, []);

  return <></>;
};

export function PartnerSite(): JSX.Element {
  const { setUserJWT } = useContext(AuthContext);

  const [date, setDate] = useState<number>(0);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const authCode = query.get("auth") || "";

  const [loader, setLoader] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>("");
  const [isUserSession, setUserlogOut] = useState(false);

  useEffect(() => {
    function loadUserDetails(tokenResp: any) {
      getUserDetailsAPI(tokenResp.accessToken)
        .then(function (resp) {
          setUserInfo(resp);
          console.log("use details info");
        })
        .catch(function (err) {
          console.log("This is error", err);
        });
    }

    function getActiveUserOnLoad() {
      setLoader(true);
      getAccessTokenAPI(authCode, "authorization_code")
        .then(function (resp: any) {
          setDate(resp.expiresIn);
          loadUserDetails(resp);
        })
        .catch(function (err: any) {
          console.log("This is error", err);
        })
        .finally(function () {
          setLoader(false);
        });
    }
    getActiveUserOnLoad();
  }, []);
  const history = useHistory();

  useEffect(() => {
    if (isUserSession) {
      setUserJWT("", false);
      history.push("/");
    }
  }, [isUserSession]);

  return (
    <div className="partner-site-old">
      <Row>
        <Col>
          <KeyeLandingPageHeader />
        </Col>
      </Row>
      <Row>
        <Col xs={10}>
          <legend id="reset-message">Reset Password</legend>
        </Col>
      </Row>
      <Row>
        <Col xs={10} md={8} lg={6}>
          {userInfo ? (
            <Modal
              show={true}
              className="model-popup"
              onHide={() => {
                //   setReferal(false);
                //   setSuccesRefer(false);
              }}>
              <Modal.Header closeButton>
                <Modal.Title>Login Info</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={12} md={6}>
                    <p>User Successful login</p>
                    <p>
                      <b>Email</b> : {userInfo && userInfo.userDetails.email}
                    </p>
                    <p>
                      <b>Name</b> : {userInfo && userInfo.userDetails.name}
                    </p>
                    {/* <CountDownTimer countDownDate={date} /> */}
                    <Countdown date={Date.now() + date * 1000}>
                      <Completionist setUserlogOut={setUserlogOut} />
                    </Countdown>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          ) : (
            ""
          )}
        </Col>
      </Row>
      {loader && <CenterLoader />}
    </div>
  );
}
