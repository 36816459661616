import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { ProductContext } from "../context/ProductContext";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";
import { AuthContext } from "../context/AuthContext";

import "../styles/ChromeExtensionNotification.scss";

export function ChromeExtensionNotification(): JSX.Element {
  const [showChromeExtModal, setChromeExtModal] = useState(false);
  const { user } = useContext(AuthContext);
  const closeModel = () => {
    setChromeExtModal(false);
  };

  const extensionEvent = () => {
    sendAnalyticsData(ANALYTICS_EVENT.EXTENSION_DOWNLOAD, {});
  };

  useEffect(() => {
    function checkExtension(callback: any) {
      const msg = { eventType: "exists", user: user };
      console.log("sending exists message : ", msg);
      window.postMessage(msg, "*");
      window.addEventListener("message", (event) => {
        if (
          event.data.eventType === "existsResponse" &&
          event.source === window
        ) {
          console.log("web msg rec : ", event.data);
          callback(true);
          return;
        }
      });
      callback(false);
    }

    if (!isMobile) {
      checkExtension((ok: any) => {
        console.log("Extension: %s", ok ? "installed" : "not installed");
        if (!ok) {
          setChromeExtModal(true);
        } else {
          setChromeExtModal(false);
        }
      });
    }
  }, []);

  const { currentProductAccesses } = useContext(ProductContext);

  const productAccountAccess = currentProductAccesses.find(
    (productAccount) =>
      `/product/${productAccount.product.id}` === window.location.pathname
  );

  return (
    <>
      {productAccountAccess && (
        <Modal
          show={showChromeExtModal}
          className="model-popup"
          onHide={() => {
            closeModel();
          }}>
          <div className="chrome-ext">
            <div>
              <img src="/images/chrome-ext-popup.png" alt="small keye logo" />
            </div>
            <div className="chrome-ext-text">
              <div onClick={closeModel}>
                <img
                  src="/images/close-icon.png"
                  alt="link"
                  width={20}
                  height={15}
                  style={{ right: "0", position: "absolute" }}
                />
              </div>
              <a
                href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph"
                target={"_blank"}
                rel="noreferrer"
                onClick={extensionEvent}>
                <img src="/images/chrome-ext-popup-btn.png" alt="text" />
              </a>
              <img src="/images/chrome-ext-popup-text.png" alt="text" />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
