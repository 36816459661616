import Carousel from "nuka-carousel";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link, Redirect, useLocation } from "react-router-dom";

import { Accordion } from "./HelpView";
import { accordionCreditInfo } from "./HelpViewInfo";
import { Header } from "../components/Header";
import { SignUpInputField } from "../components/SignUpInputField";
import { VerticallyScrollingText } from "../components/VerticallyScrollingText";
import { AuthContext } from "../context/AuthContext";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
} from "../scripts/analytics";

import "../styles/MainView.scss";

interface MenuLink {
  text: string;
  href?: string;
  children?: MenuLink[];
  onClick?: any;
}

function renderMenuLinks(menuLinks: MenuLink[]): JSX.Element {
  if (menuLinks.length) {
    return (
      <ul
        style={{
          flexDirection: isMobile ? "column" : "row",
        }}>
        {menuLinks.map((link) => (
          <li key={link.text}>{renderSingleMenuLink(link)}</li>
        ))}
      </ul>
    );
  }

  return <></>;
}

function renderSingleMenuLink(link: MenuLink): JSX.Element {
  let linkNode;
  if ("href" in link && link.href) {
    const externalOrDifferentProtocol =
      link.href.startsWith("http:") ||
      link.href.startsWith("https:") ||
      link.href.startsWith("mailto:");
    if (externalOrDifferentProtocol) {
      linkNode = (
        <a
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={link.onClick ? link.onClick : () => {}}>
          {link.text}
        </a>
      );
    } else {
      linkNode = (
        <Link
          to={link.href}
          onClick={(ev) => {
            if (link.onClick) {
              link.onClick();
            }

            const linkNode: HTMLAnchorElement = ev.target as HTMLAnchorElement;
            if (linkNode.hash.length > 0) {
              setTimeout(() => {
                const id: string = linkNode.hash.replace("#", "");
                const targetElNode = document.getElementById(id);
                if (targetElNode) {
                  targetElNode.scrollIntoView();
                }
              }, 0);
            } else {
              window.scrollTo(0, 0);
            }
          }}>
          {link.text}
        </Link>
      );
    }
  } else {
    linkNode = <span>{link.text}</span>;
  }

  return (
    <>
      {linkNode}
      {link.children && link.children.length && renderMenuLinks(link.children)}
    </>
  );
}

const useQuery = () => new URLSearchParams(useLocation().search);

function onClickAddExtn() {
  sendAnalyticsData(ANALYTICS_EVENT.EXTENSION_DOWNLOAD, {});
}

const FOOTER_MENU_LINKS: MenuLink[] = [
  {
    text: "Company",
    children: [
      {
        text: "About Keye",
        href: "/about",
      },
      {
        text: "FAQ’s",
        href: "https://www.keye.co/how-it-works",
      },
      {
        text: "Terms & Conditions",
        href: "/terms",
      },
      {
        text: "Privacy Policy",
        href: "/privacy",
      },
    ],
  },
  {
    text: "Connect",
    children: [
      // {
      //   text: "Twitter",
      //   href: "",
      // },
      // {
      //   text: "Blog",
      //   href: "",
      // },
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/company/keye/",
        onClick: () => {
          sendAnalyticsData(
            ANALYTICS_EVENT.ON_CLICK_MAIN_PAGE_CONNECT,
            eventBundleProperties(null, EVENT_TYPE.UI)
          );
        },
      },
      {
        text: "Instagram",
        href: "https://www.instagram.com/keye.co/",
        onClick: () => {
          sendAnalyticsData(
            ANALYTICS_EVENT.ON_CLICK_MAIN_PAGE_CONNECT,
            eventBundleProperties(null, EVENT_TYPE.UI)
          );
        },
      },
      {
        text: "Email",
        href: "mailto:contact@keye.co",
        onClick: () => {
          sendAnalyticsData(
            ANALYTICS_EVENT.ON_CLICK_MAIN_PAGE_CONNECT,
            eventBundleProperties(null, EVENT_TYPE.UI)
          );
        },
      },
    ],
  },
];

export const MainView = () => {
  const { user } = React.useContext(AuthContext);
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");
  const ref = useRef(null);
  const refCarousel1 = useRef() as MutableRefObject<HTMLDivElement>;

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_MAIN_PAGE, {});
  }, [utm]);

  return user ? (
    <Redirect to="home" />
  ) : (
    <>
      <Header />

      <Row className={["section-1", "no-gutters"].join(" ")}>
        <Col xs={12} md={6}>
          <span className="rating">
            <span className="stars">★★★★★</span> BETA
          </span>
          <h1
            style={{
              marginBottom: "4rem",
            }}>
            The Easiest Way To
            <br />
            Access Premium{" "}
            {VerticallyScrollingText([
              "Articles",
              "Books",
              "Classes",
              "Data",
              "Podcasts",
              "Shows",
              "Tools",
            ])}
          </h1>
          <SignUpInputField />
          <Row
            style={{
              margin: "5rem 0 0 0",
            }}>
            <Col
              lg={4}
              style={{
                fontWeight: 300,
                fontSize: "1.175rem",
              }}>
              <h3
                style={{
                  fontSize: "1.5rem",
                }}>
                25+
              </h3>
              products included
            </Col>
            <Col
              lg={4}
              style={{
                fontWeight: 300,
                fontSize: "1.175rem",
              }}>
              <h3
                style={{
                  fontSize: "1.5rem",
                }}>
                7 days free
              </h3>
              no credit card needed
            </Col>
          </Row>
        </Col>
        {!isMobile && (
          <Col xs={0} md={6} style={{ position: "relative" }}>
            <img
              src="/images/landing-header.svg"
              alt=""
              style={{ width: 656 }}
            />
          </Col>
        )}
      </Row>

      <Row id="products" className="section-2 no-gutters">
        <Col xs={12} md={5} style={{ padding: "9rem 4.5rem" }}>
          <h2>Your all-inclusive pass to premium content</h2>
          <p
            style={{
              marginTop: "2rem",
              marginBottom: "4rem",
            }}>
            Discover unlimited news, books, tools and more. Products on Keye
            help you build skills, work better and learn about the world.
          </p>

          <a href="#more-about-keye">
            <Button>Learn more</Button>
          </a>
        </Col>
        <Col xs={12} md={7}>
          <Carousel
            speed={250}
            defaultControlsConfig={{
              prevButtonText: " ",
              prevButtonStyle: {
                width: "2.5rem",
                height: "2.5rem",
                background: `transparent url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' transform='rotate(-180 22 22)' fill='%23D9D9D9'/%3E%3Cpath d='M26.625 12.4C26.875 12.65 27 12.946 27 13.288C27 13.63 26.875 13.9257 26.625 14.175L19.3 21.5L26.65 28.85C26.8833 29.0833 27 29.375 27 29.725C27 30.075 26.875 30.375 26.625 30.625C26.375 30.875 26.079 31 25.737 31C25.395 31 25.0993 30.875 24.85 30.625L16.45 22.2C16.35 22.1 16.279 21.9917 16.237 21.875C16.195 21.7583 16.1743 21.6333 16.175 21.5C16.175 21.3667 16.196 21.2417 16.238 21.125C16.28 21.0083 16.3507 20.9 16.45 20.8L24.875 12.375C25.1083 12.1417 25.396 12.025 25.738 12.025C26.08 12.025 26.3757 12.15 26.625 12.4Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat`,
                backgroundSize: "cover",
              },
              nextButtonText: " ",
              nextButtonStyle: {
                width: "2.5rem",
                height: "2.5rem",
                background: `transparent url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M17.375 31.6C17.125 31.35 17 31.054 17 30.712C17 30.37 17.125 30.0743 17.375 29.825L24.7 22.5L17.35 15.15C17.1167 14.9167 17 14.625 17 14.275C17 13.925 17.125 13.625 17.375 13.375C17.625 13.125 17.921 13 18.263 13C18.605 13 18.9007 13.125 19.15 13.375L27.55 21.8C27.65 21.9 27.721 22.0083 27.763 22.125C27.805 22.2417 27.8257 22.3667 27.825 22.5C27.825 22.6333 27.804 22.7583 27.762 22.875C27.72 22.9917 27.6493 23.1 27.55 23.2L19.125 31.625C18.8917 31.8583 18.604 31.975 18.262 31.975C17.92 31.975 17.6243 31.85 17.375 31.6Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat`,
                backgroundSize: "cover",
              },
            }}>
            <img
              src="/images/products-slide-1.png"
              alt="Keye partners slide 1"
              style={{
                width: "100%",
              }}
            />
            <img
              src="/images/products-slide-2.png"
              alt="Keye partners slide 2"
              style={{
                width: "100%",
              }}
            />
          </Carousel>
        </Col>
      </Row>

      <Row id="more-about-keye" className="section-3 separated no-gutters">
        {!isMobile && (
          <Col
            xs={12}
            md={7}
            style={{
              background:
                "#111 url(images/landing-slide-image-1.jpg) no-repeat top right",
              backgroundSize: "cover",
            }}
            ref={ref}></Col>
        )}
        <Col xs={12} md={5}>
          <Carousel
            speed={250}
            defaultControlsConfig={{
              prevButtonText: " ",
              prevButtonStyle: {
                width: "2.5rem",
                height: "2.5rem",
                background: `transparent url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' transform='rotate(-180 22 22)' fill='%23D9D9D9'/%3E%3Cpath d='M26.625 12.4C26.875 12.65 27 12.946 27 13.288C27 13.63 26.875 13.9257 26.625 14.175L19.3 21.5L26.65 28.85C26.8833 29.0833 27 29.375 27 29.725C27 30.075 26.875 30.375 26.625 30.625C26.375 30.875 26.079 31 25.737 31C25.395 31 25.0993 30.875 24.85 30.625L16.45 22.2C16.35 22.1 16.279 21.9917 16.237 21.875C16.195 21.7583 16.1743 21.6333 16.175 21.5C16.175 21.3667 16.196 21.2417 16.238 21.125C16.28 21.0083 16.3507 20.9 16.45 20.8L24.875 12.375C25.1083 12.1417 25.396 12.025 25.738 12.025C26.08 12.025 26.3757 12.15 26.625 12.4Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat`,
                backgroundSize: "cover",
              },
              nextButtonText: " ",
              nextButtonStyle: {
                width: "2.5rem",
                height: "2.5rem",
                background: `transparent url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M17.375 31.6C17.125 31.35 17 31.054 17 30.712C17 30.37 17.125 30.0743 17.375 29.825L24.7 22.5L17.35 15.15C17.1167 14.9167 17 14.625 17 14.275C17 13.925 17.125 13.625 17.375 13.375C17.625 13.125 17.921 13 18.263 13C18.605 13 18.9007 13.125 19.15 13.375L27.55 21.8C27.65 21.9 27.721 22.0083 27.763 22.125C27.805 22.2417 27.8257 22.3667 27.825 22.5C27.825 22.6333 27.804 22.7583 27.762 22.875C27.72 22.9917 27.6493 23.1 27.55 23.2L19.125 31.625C18.8917 31.8583 18.604 31.975 18.262 31.975C17.92 31.975 17.6243 31.85 17.375 31.6Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat`,
                backgroundSize: "cover",
              },
            }}
            afterSlide={(slideIndex) => {
              if (ref.current) {
                (
                  ref.current as HTMLElement
                ).style.backgroundImage = `url(images/landing-slide-image-${
                  slideIndex + 1
                }.jpg)`;
              }
            }}
            innerRef={refCarousel1}>
            <div
              style={{
                padding: isMobile ? "4.5rem 6rem" : "200px 100px",
              }}>
              <h2>What is Keye?</h2>
              <p>
                Keye is where you get your favorite premium content, all in one
                place, with one click for one low price. Discover millions of
                books, articles, classes and more.
              </p>
              <Link
                to="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  (
                    (
                      refCarousel1.current.parentNode as HTMLElement
                    ).getElementsByTagName("button")[1] as HTMLElement
                  ).click();
                }}>
                How Keye works
              </Link>
            </div>
            <div
              style={{
                padding: isMobile ? "4.5rem 6rem" : "200px 100px",
              }}>
              <h2>How does Keye work?</h2>
              <p>
                You'll get credits that give you instant 24-hour access to our
                partner sites whenever you want it. You'll get unlimited credits
                during your trial!
              </p>
              <Link
                to="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  (
                    (
                      refCarousel1.current.parentNode as HTMLElement
                    ).getElementsByTagName("button")[1] as HTMLElement
                  ).click();
                }}>
                Why use Keye?
              </Link>
            </div>
            <div
              style={{
                padding: isMobile ? "4.5rem 6rem" : "200px 100px",
              }}>
              <h2>Why use Keye?</h2>
              <p>
                Keye offers an easy and affordable way to try premium products
                without the hassle of paywalls or subscriptions.
              </p>
              <Link to="/register">Try it free</Link>
            </div>
          </Carousel>
        </Col>
      </Row>

      {!isMobile && (
        <Row
          className="section-4 no-gutters"
          style={{
            paddingTop: "96px",
            paddingBottom: "96px",
          }}>
          <Col xs={1}></Col>
          <Col xs={5} className="px-4 py-5">
            <h2 className="mb-5">
              Get even more with the
              <br />
              <a
                href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph"
                target="_blank"
                onClick={onClickAddExtn}
                rel="noreferrer">
                Keye extension!
              </a>
            </h2>
            <a
              href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph"
              target="_blank"
              onClick={onClickAddExtn}
              rel="noreferrer">
              <Button>Add to Chrome — It’s free!</Button>
            </a>
          </Col>
          <Col xs={5} className="px-4 py-5">
            <ul className="list">
              <li>Save more time and money as you browse</li>
              <li>Breeze past paywalls without signing up</li>
              <li>Seamlessly use all of Keye’s helpful features</li>
            </ul>
          </Col>
          <Col xs={1}></Col>
        </Row>
      )}

      <Row className="footer no-gutters">
        <Col xs={12} md={6}>
          {FOOTER_MENU_LINKS.length && renderMenuLinks(FOOTER_MENU_LINKS)}
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={5}>
          <h2 className="blue">Sign up for free</h2>
          <p style={{ marginBottom: "3rem" }}>
            Get immediate access to over 20
            <br />
            premium sites &amp; apps! Try us free!
          </p>
          <SignUpInputField />
        </Col>
      </Row>
    </>
  );
};
