import React from "react";
import Spinner from "react-bootstrap/Spinner";

import "../styles/Loader.scss";

export function Loader(): JSX.Element {
  return (
    <Spinner animation="grow" variant="info" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export function CenterLoader(): JSX.Element {
  return (
    <div id="center-loader-div">
      <Loader />
    </div>
  );
}
