import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { accordionCreditInfo } from "./HelpViewInfo";
import ReactPlayer from "react-player";

import "../styles/HelpView.scss";

export function createMarkup(content: string | undefined) {
  return { __html: content || "" };
}

export const Accordion = ({
  title,
  content,
  image,
  imageAlt,
  imagePosition,
  multipleImages,
  video,
}: {
  title: string | undefined;
  content: string | undefined;
  image: string | undefined;
  video: string | undefined;
  imageAlt: string | undefined;
  imagePosition: string | undefined;
  multipleImages: boolean | undefined | null;
}) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div
          className={`accordion-tile-name ${
            isActive ? "accordion-tile-active" : "accordion-tile-nonactive"
          } `}>
          {title}
        </div>
        <div
          className={`accordion-icon ${
            isActive ? "accordion-active" : "accordion-nonactive"
          }`}>
          {isActive ? "-" : "+"}
        </div>
      </div>
      {isActive && (
        <Row className={"row-section"}>
          <Row className={"row-group d-flex flex-lg-row flex-column"}>
            {imagePosition === "right" ? (
              <>
                {content ? (
                  <Col className={"col-text"}>
                    <div
                      className="accordion-content"
                      dangerouslySetInnerHTML={createMarkup(content)}
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col>
                  {image ? (
                    <img className={"img-style"} src={image} alt={imageAlt} />
                  ) : video ? (
                    <ReactPlayer
                      url={video}
                      playing={true}
                      volume={1}
                      width={532}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </>
            ) : (
              <>
                <Col>
                  {image ? (
                    <img className={"img-style"} src={image} alt={imageAlt} />
                  ) : video ? (
                    <ReactPlayer
                      url={video}
                      playing={true}
                      volume={1}
                      width={532}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
                {content ? (
                  <Col className={"col-text"}>
                    <div
                      className="accordion-content"
                      dangerouslySetInnerHTML={createMarkup(content)}
                    />
                  </Col>
                ) : (
                  ""
                )}
              </>
            )}
          </Row>
        </Row>
      )}
    </div>
  );
};

export function HelpView(): JSX.Element {
  return (
    <Row id="help-view">
      <h2 id="welcome-message">Using Keye is easy as 1, 2, 3!</h2>
      <div className="accordion">
        {accordionCreditInfo.map(
          ({
            title,
            content,
            image,
            video,
            imageAlt,
            imagePosition,
            multipleImages,
          }) => (
            <Accordion
              title={title}
              content={content}
              image={image}
              video={video}
              imageAlt={imageAlt}
              imagePosition={imagePosition}
              multipleImages={multipleImages}
            />
          )
        )}
      </div>
    </Row>
  );
}

function WhyUseCreditsSection(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>3. Why use credits?</h4>
      </Row>
      <Row>
        <img
          className={"img-style"}
          src="/images/why-use-credits.png"
          alt="Why use credits?"
        />
      </Row>
    </Row>
  );
}

function CreditBalanceSection(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>4. Where to find credit balance?</h4>
      </Row>
      <Row>
        <img
          className={"img-style"}
          src="/images/where-to-find-credit-balance.png"
          alt="Where to find credit balance?"
        />
      </Row>
    </Row>
  );
}

function HowCreditsWorkSection(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>5. How credits work?</h4>
      </Row>
      <Row>
        <img
          className={"img-style"}
          src="/images/how-credits-work.png"
          alt="How credits work?"
        />
      </Row>
    </Row>
  );
}

function WhatIsKeyeSection(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>1. What is Keye?</h4>
      </Row>
      <Row className={"row-group d-flex flex-lg-row flex-column"}>
        <Col className={"col-text"}>
          <p>
            Keye lets you try out premium software products for a fraction of
            the subscription price.
          </p>
          <p>
            When you activate a Keye access, you can use a subscription product
            without creating an account or worrying about recurring charges.
          </p>
        </Col>
        <Col>
          <img
            className={"img-style"}
            src="/images/what-is-keye.png"
            alt="What is Keye?"
          />
        </Col>
      </Row>
    </Row>
  );
}

function HowKeyeWorksSection(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>2. How Keye Works?</h4>
      </Row>
      <Row className={"row-group d-flex flex-lg-row flex-column"}>
        <Col className={"col-text"}>
          <h5 className={"sub-section-header"}>Product Access Time</h5>
          <p>With Keye, you have two options for accessing this product.</p>
          <p>
            <strong>Option 01</strong>. Get access right away until 2pm EST.
            This option may not always be available.
          </p>
          <p>
            <strong>Option 02</strong>. Get access for 22 hours starting at 4pm
            EST and ending at 2pm EST the following day. This option will always
            be available.
          </p>
          <p>
            Accesses are not available between 2pm and 4pm EST (only during our
            Beta!)
          </p>
        </Col>
        <Col>
          <img
            className={"img-style"}
            src="/images/how-keye-works-1.png"
            alt="How Keye Works Part 1"
          />
          <img
            className={"img-style"}
            src="/images/how-keye-works-2.png"
            alt="How Keye Works Part 2"
          />
        </Col>
      </Row>
      <Row className={"row-group d-flex flex-lg-row flex-column"}>
        <Col>
          <img
            className={"img-style"}
            src="/images/buying-an-access.png"
            alt="Buying an access"
          />
        </Col>

        <Col className={"col-text"}>
          <h5 className={"sub-section-header"}>Buying an access</h5>
          <p>
            After you click "Activate Access," you will be able to choose one of
            the options.{" "}
          </p>
          <p>
            Once you make a{" "}
            <strong>
              selection, accept the terms and conditions, and answer a couple of
              quick questions
            </strong>
            , your access will be confirmed!
          </p>
          <p>
            Then, you will receive instructions for logging into your account to
            begin using the product.
          </p>
        </Col>
      </Row>
    </Row>
  );
}

function KeyeCostSection(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>3. How much does Keye cost?</h4>
      </Row>
      <Row className={"row-group d-flex flex-lg-row flex-column"}>
        <Col className={"col-text"}>
          <h5 className={"sub-section-header"}>
            Keye is free to use during our beta!
          </h5>
          <p>
            To help us keep Keye free, we will periodically send you surveys
            that will help us improve our product.
          </p>
          <p>
            Please respond to the surveys to help us continue providing you
            access to premium software subscriptions!
          </p>
          <p>
            Questions? Reach out to us at <strong>contact@keye.co</strong>
          </p>
        </Col>
        <Col>
          <img
            className={"img-style"}
            src="/images/keye-cost-1.png"
            alt="Keye Cost"
          />
        </Col>
      </Row>
    </Row>
  );
}

function KeyeCostSectionCred(): JSX.Element {
  return (
    <Row className={"row-section"}>
      <Row>
        <h4 className={"section-header"}>6. How much does Keye cost?</h4>
      </Row>
      <Row className={"row-group d-flex flex-lg-row flex-column"}>
        <Col className={"col-text"}>
          <h5 className={"sub-section-header"}>
            Keye is free to use during our beta!
          </h5>
          <p>
            To help us keep Keye free, we will periodically send you surveys
            that will help us improve our product.
          </p>
          <p>
            Please respond to the surveys to help us continue providing you
            access to premium software subscriptions!
          </p>
          <p>
            Questions? Reach out to us at <strong>contact@keye.co</strong>
          </p>
        </Col>
        <Col>
          <img
            className={"img-style"}
            src="/images/keye-cost-1.png"
            alt="Keye Cost"
          />
        </Col>
      </Row>
    </Row>
  );
}
