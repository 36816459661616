import React from "react";
import { useHistory } from "react-router-dom";

interface JoinBetaProps {
  invite: string;
}

export function JoinBetaBanner({ invite }: JoinBetaProps): JSX.Element {
  const history = useHistory();
  console.log("join beta ", invite);
  return (
    <div className="bg-blue-2">
      <div className="flex flex-col items-center pt-9 pb-9">
        <div className="flex flex-row items-center rounded-lg p-4 md:p-6 bg-blue-1 shadow-button ">
          <div className="flex flex-col">
            <h3 className="font-gilroy font-bold text-extraSmall md:text-large lg:text-larger">
              Register to Keye
            </h3>
            <p className="self-start text-mini md:text-small self-start py-3 md:py-4 leading-body max-w-2xl leading-body">
              Sick of trying to game your way into paying less for
              subscriptions? Stop making fake email addresses and join Keye now!
            </p>
            <button
              className="self-start font-semibold text-small py-1 px-4 text-white rounded-full bg-primaryOrange shadow-button"
              onClick={() => {
                history.push("/register?invite-id=" + invite);
              }}>
              Register
            </button>
          </div>
          <div>
            <img
              src="/images/join-waitlist.png"
              width={"330px"}
              height={"233px"}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const JoinBetaBannerMobile = ({ onPress }: { onPress: () => void }) => (
  <div className="bg-blue-2">
    <div className="flex flex-col items-center pt-6 pb-6 px-2">
      <div className="flex flex-row items-center rounded-lg p-4 md:p-6 bg-blue-1 shadow-button ">
        <div className="flex flex-col">
          <h3 className="font-gilroy font-bold text-extraSmall md:text-large lg:text-larger">
            Join the waitlist
          </h3>
          <p className="self-start text-mini md:text-small self-start py-3 md:py-4 max-w-2xl">
            Sick of trying to game your way into paying less for subscriptions?
            Stop making fake email addresses and join Keye now!
          </p>
          <button
            className="self-start font-semibold text-extraSmall py-2 px-3 text-white rounded-full bg-primaryOrange shadow-button"
            onClick={onPress}>
            Register to Keye
          </button>
        </div>
      </div>
    </div>
  </div>
);
