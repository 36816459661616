import {
  Product,
  ProductCreditCost,
  ProductReservationDetails,
} from "../../models/Product";
import {
  displayDate,
  getBackgroundColor,
  getTextColor,
  toTitleCase,
} from "../../scripts/util";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Button, Card } from "react-bootstrap";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  productDetailsBundle,
  sendAnalyticsData,
} from "../../scripts/analytics";

import "../../styles/ProductCardStyles/ShoppingProductCard.scss";

export function ShoppingProductCard({
  product,
  reservationDetails,
  selectedProduct,
  setSelectedProduct,
  creditCost,
}: {
  product: Product;
  reservationDetails: ProductReservationDetails | undefined;
  selectedProduct: Product | undefined;
  setSelectedProduct: (product: Product | undefined) => void;
  creditCost: ProductCreditCost | undefined;
}): JSX.Element | null {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  function sendProductAccessEvent() {
    return sendAnalyticsData(
      ANALYTICS_EVENT.ON_SUBMIT_PRODUCT_DETAILS_REQUEST,
      {
        ...eventBundleProperties(user, EVENT_TYPE.UA),
        ...productDetailsBundle(product, "ACCESSIBLE"),
      }
    );
  }

  function sendQuickViewClickEvent() {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_QUICK_VIEW, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, product.status.toString()),
    });
  }

  return (
    <Card
      id="product-card"
      className={`${
        selectedProduct === product ? "selected-product-card" : ""
      }`}>
      <Card.Img
        variant="top"
        src={product.imgUrl}
        alt={product.name}
        id="product-card-image"
        onClick={() => {
          if (selectedProduct === product) {
            setSelectedProduct(undefined);
          } else {
            sendQuickViewClickEvent();
            setSelectedProduct(product);
          }
        }}
      />

      <Card.Body className="d-flex flex-column" id="shopping-product-card-body">
        <div
          onClick={() => {
            if (selectedProduct === product) {
              setSelectedProduct(undefined);
            } else {
              sendQuickViewClickEvent();
              setSelectedProduct(product);
            }
          }}>
          <div id="shopping-product-card-top-row">
            <Card.Text as="h5" id="shopping-product-card-name">
              {product.name}
            </Card.Text>

            {/* if the product has a featured message display it here */}
            {product.featuredMetadata &&
              product.featuredMetadata.featuredMessage && (
                <Card.Text as="h5" id="shopping-product-flag">
                  {product.featuredMetadata.featuredMessage}
                </Card.Text>
              )}
          </div>

          <div id="shopping-product-card-bottom-row">
            <Card.Text>
              <Button
                disabled
                id="decorative-category-pills"
                style={{
                  backgroundColor: getBackgroundColor(String(product.category)),
                  color: getTextColor(String(product.category)),
                }}>
                {toTitleCase(String(product.category))}
              </Button>
            </Card.Text>
          </div>
        </div>
        {selectedProduct === product && (
          <>
            <Card.Text>{product.shortDescription}</Card.Text>
            <Card.Body className="d-flex justify-content-center">
              {/* it is unlikely that a user will ever see stuff related to reservations because a reservation is usually a very brief intermittent step between Waitlist and Active */}
              {reservationDetails ? (
                <Button
                  id="product-card-action-button"
                  variant="primary"
                  onClick={() => {
                    sendProductAccessEvent();
                    history.push(`/product/${product.id}`);
                  }}>
                  Access starts on{" "}
                  {displayDate(reservationDetails.scheduledTime)}
                </Button>
              ) : (
                <Button
                  id="product-card-action-button"
                  variant="primary"
                  style={{ background: "green" }}
                  onClick={() => {
                    sendProductAccessEvent();
                    history.push(`/product/${product.id}`);
                  }}>
                  Access
                </Button>
              )}
            </Card.Body>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
