import { Col, Row } from "react-bootstrap";
import { ProductCard } from "../components/ProductCard/ProductCard";
import { Product } from "../models/Product";

import "../styles/NewArrivalsView.scss";

export function NewArrivalsView({
  products,
  selectedProduct,
  setSelectedProduct,
}: {
  products: Product[];
  selectedProduct: Product | undefined;
  setSelectedProduct: (product: Product | undefined) => void;
}): JSX.Element {
  return (
    <div className="new-arrivals-products-view">
      {products.length > 0 && (
        <Row id="product-card-row">
          {products.map((x) => (
            <Col xs={12} md={6} xl={4} key={x.id} className="mb-3">
              <ProductCard
                key={x.id}
                product={x}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
