import React, { MutableRefObject, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import Carousel from "nuka-carousel";
import { Accordion } from "./HelpView";
import { accordionCreditInfo } from "./HelpViewInfo";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";
import { isMobile } from "react-device-detect";
import { Header } from "../components/Header";
import { SignUpInputField } from "../components/SignUpInputField";
import { VerticallyScrollingText } from "../components/VerticallyScrollingText";

import "../styles/LandingForIndependentProsView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

function onClickSignUp() {
  sendAnalyticsData(
    ANALYTICS_EVENT.ON_CLICK_LANDING_PAGE_SELF_EMPLOYED_SIGNUP,
    {}
  );
}

export const LandingForIndependentProsView = () => {
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_LANDING_PAGE_SELF_EMPLOYED, {});
  }, [utm]);

  return (
    <>
      <Navbar>
        <Navbar.Brand>
          <a href="https://www.unlockkeye.com/">
            <img
              src="/images/keye_logo.png"
              alt="Small blue Keye icon"
              id="keye-logo-nav"
              style={{
                width: "150px",
                marginBottom: isMobile ? "0" : "60px",
              }}
            />
          </a>
        </Navbar.Brand>
      </Navbar>

      <div
        className="section-1"
        style={{
          background: isMobile ? "#f4f8ff" : "_",
          padding: isMobile ? "3rem 2rem" : "4rem 2.5rem",
        }}>
        <Row>
          <Col xs="12" sm="6">
            <h1>
              Thousands of resources for a{" "}
              <span style={{ color: "#175FD3" }}>fraction of the cost</span>
            </h1>
            <p>
              Get unlimited access to the content & tools that help you and your
              clients succeed.
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                onClickSignUp();
                history.push("/register");
              }}>
              Try it FREE
            </Button>
          </Col>
          <Col xs="12" sm="6">
            <img
              src="images/landing-thousands-of-resources.svg"
              style={{
                display: isMobile ? "none" : "",
              }}
              alt=""
            />
          </Col>
        </Row>
      </div>

      {!isMobile && (
        <div className="section-1-5">
          <Row
            className="partners no-gutters"
            style={{ flexDirection: isMobile ? "column" : "row" }}>
            <Col>
              <img src="/images/partner_logo/crunchbase_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/masterclass_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/transkriptor_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/picsart_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/information_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/blinkist_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/techcrunch_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/many_more_landing.png" alt="" />
            </Col>
          </Row>
        </div>
      )}

      <div
        className={`section-2 ${isMobile ? "mobileHide" : ""}`}
        style={{
          background: isMobile
            ? "#f4f8ff"
            : `#f4f8ff url("data:image/svg+xml,%3Csvg width='501' height='595' viewBox='0 0 501 595' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='595' width='595' height='501' transform='rotate(-90 0 595)' fill='%23175FD3'/%3E%3Cpath d='M67 211.302V117H435.336V529H390.5' stroke='white' stroke-width='2' stroke-dasharray='10 10'/%3E%3C/svg%3E%0A") no-repeat center right`,
        }}>
        <Row
          style={{
            width: isMobile ? "90%" : "82%",
          }}>
          <Col>
            <h1>
              The ultimate
              <br />
              <span style={{ color: "#175FD3" }}>productivity partner</span>
            </h1>
          </Col>
        </Row>

        <Row
          style={{
            width: isMobile ? "90%" : "82%",
          }}>
          <Col xs="12" sm="12" md="4">
            <p
              style={{
                marginRight: isMobile ? "0" : "-8rem",
              }}>
              Don’t let cost get in the way of accessing cutting-edge tools and
              content. Stay ahead of the competition with Keye.
            </p>
          </Col>
          <Col xs="12" sm="12" md="8">
            <img
              src="images/landing-your-ultimate-productivity-partner.svg"
              className="graphic"
              alt=""
              style={{
                margin: isMobile ? "3rem 0" : "10rem 0 5rem 0",
              }}
            />
          </Col>
        </Row>
      </div>

      <div
        className="section-3"
        style={{
          padding: isMobile
            ? "3.5rem 1.5rem 7.5rem 1.5rem"
            : "5rem 7.5rem 7.5rem 7.5rem",
        }}>
        <h1>
          The must-have bundle for{" "}
          <span style={{ color: "#175FD3" }}>professionals</span>
        </h1>
        <h3>Get the largest collection of articles, data, tools and more</h3>

        <img src="images/landing-for-independent-pros-premium-content.png" />
      </div>

      <div className={isMobile ? "section-4 p-2" : "section-4 p-5"}>
        <h1>How It Works</h1>

        <div className="accordion">
          {accordionCreditInfo.map(
            ({
              title,
              content,
              image,
              video,
              imageAlt,
              imagePosition,
              multipleImages,
            }) => (
              <Accordion
                key={title}
                title={title}
                content={content}
                image={image}
                video={video}
                imageAlt={imageAlt}
                imagePosition={imagePosition}
                multipleImages={multipleImages}
              />
            )
          )}
        </div>
      </div>

      <div className="section-5">
        <h1>Work smarter, with Keye</h1>

        <Row
          className="footer-section"
          style={{
            padding: isMobile
              ? "1.5rem 1.5rem 7.5rem 1.5rem"
              : "7.5rem 7.5rem 2rem 7.5rem",
          }}>
          <Col
            xs="12"
            sm="12"
            md="6"
            style={{
              textAlign: isMobile ? "center" : "left",
            }}>
            <p>
              Sick of losing business because you’re out of the loop? Keye gets
              you up to speed, fast. Try us now!
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                window.scrollTo(0, 0);
                onClickSignUp();
                history.push("/register");
              }}>
              Sign up
            </Button>
          </Col>
          <Col
            md="6"
            style={{
              display: isMobile ? "none" : "block",
            }}>
            <img src="images/section-5.png" alt="" />
          </Col>
        </Row>
      </div>
    </>
  );
};
