import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Product, ProductSurvey } from "../models/Product";
import {
  getPendingSurveys,
  getWaitlistDetailsMyProducts,
} from "../scripts/createApiInstance";
import { CenterLoader } from "../components/Loader";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
  setAnalyticsUserId,
} from "../scripts/analytics";
import { getBackgroundColor, getTextColor, toTitleCase } from "../scripts/util";
import { NewArrivalsView } from "./NewArrivalsView";
import { FilterProductCategoryPills } from "../components/FilterProductCategoryPills";
import { SortProductsMenu } from "../components/SortProductsMenu";
import { PostAccessSurveyView } from "./PostAccessSurveyView";
import { Row, Col } from "react-bootstrap";
import { ProductContext } from "../context/ProductContext";
import { useToasts } from "react-toast-notifications";

import "../styles/HomeView.scss";

const categoriesOrder = [
  "DATA",
  "NEWS",
  "INFORMATION",
  "PRODUCTIVITY",
  "LEARNING",
  "EDUCATION",
  "MISCELLANEOUS",
  "HEALTH",
];

export function HomeView(): JSX.Element {
  const { apiInstance, user, creditSystemEnabled } =
    React.useContext(AuthContext);
  const { currentProducts, currentProductsLoading } =
    useContext(ProductContext);

  const categories = currentProducts.map((product) => product.category);
  // @ts-ignore
  const uniqueCategories = [...new Set(categories)];
  // Sort the unique categories
  uniqueCategories.sort((a, b) => {
    return (
      categoriesOrder.indexOf(toTitleCase(a).toUpperCase()) -
      categoriesOrder.indexOf(toTitleCase(b).toUpperCase())
    );
  });

  /** LOADERS */
  const [pendingSurveyLoader, setPendingSurveyLoader] =
    useState<boolean>(false);
  /** */

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [categoryFilter, setCategoryFilter] = useState<any>("");
  const [pendingSurveys, setPendingSurveys] = useState<ProductSurvey[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setAnalyticsUserId(user);
      sendAnalyticsData(
        ANALYTICS_EVENT.ON_LOAD_HOME_SCREEN,
        eventBundleProperties(user, EVENT_TYPE.UI)
      );
    }
  }, [user, refresh]);

  useEffect(() => {
    function populateSurveysOnLoad() {
      setPendingSurveyLoader(true);
      getPendingSurveys(apiInstance)
        .then(function (resp) {
          setPendingSurveys(resp.data);
        })
        .catch(function (err) {
          console.log("Pending survey error", err);
        })
        .finally(function () {
          setPendingSurveyLoader(false);
        });
    }
    populateSurveysOnLoad();
  }, [apiInstance, refresh]);

  const { addToast } = useToasts();
  useEffect(() => {
    async function loadWaitListToasts() {
      try {
        const productIds = currentProducts.map((product) => product.id);
        const allWaitlistDetails = await getWaitlistDetailsMyProducts(
          apiInstance,
          productIds
        );
        if (allWaitlistDetails.data?.length) {
          let message = "";
          allWaitlistDetails.data.forEach(
            (element, i) =>
              (message += `<div>K <b>10</b> was deducted for <b> ${
                element.productName
              } </b> access activated on <b> ${element.scheduledTime} </b> ${
                allWaitlistDetails.data.length == i + 1 ? "" : "<hr/>"
              }</div>`)
          );
          addToast(message, { appearance: "success", autoDismiss: true });
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (creditSystemEnabled) loadWaitListToasts();
  }, [apiInstance, creditSystemEnabled]);

  //just ticks a refresh flag to force a re-render and data fetch
  const afterSurveySubmit = () => setRefresh(!refresh);

  const filteredFeaturedProducts = currentProducts.filter(
    (x) => x.category.toString() === categoryFilter || categoryFilter === ""
  );

  const isLoading = currentProductsLoading || pendingSurveyLoader || !user;
  document.body.style.backgroundColor = "white";

  return (
    <Row id="home-view">
      {isLoading ? (
        <CenterLoader />
      ) : pendingSurveys.length > 0 && false ? (
        //right now we just show the first survey, then when the user completes that survey gets removed from the list.
        //This repeats until there are no surveys left.
        //This is a temporary solution until we have a design work complete on how to handle presenting multiple surveys to the user.
        <>
          <Col md={2}>
            {pendingSurveys.map((x) => (
              <p>
                <i
                  className="fa fa-check-circle"
                  id="access-product-copy-un-icon"
                  aria-hidden="true"></i>
                {currentProducts.filter((a) => a.id === x.productId)[0].name}
              </p>
            ))}
          </Col>
          <Col md={10}>
            <PostAccessSurveyView
              survey={pendingSurveys[0]}
              afterSubmit={afterSurveySubmit}
            />
          </Col>
        </>
      ) : (
        <>
          <Col>
            <Row className="justify-content-baseline align-items-start">
              <Col xs={12} md={10}>
                <FilterProductCategoryPills
                  setCategoryFilter={setCategoryFilter}
                />
              </Col>
            </Row>
            {categoryFilter && (
              <>
                <div>
                  <button
                    id="category-pills"
                    className="mt-5 mb-4 display-only"
                    style={{
                      backgroundColor: "transparent",
                      color: "transparent",
                            fontSize: "2rem",
                    }}>
                    &nbsp;
                  </button>
                </div>
                <NewArrivalsView
                  products={currentProducts.filter(
                    (p) => p.category.toString() === categoryFilter
                  )}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
              </>
            )}
            {!categoryFilter && (
              <>
                {uniqueCategories.map((category) => (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <button
                        id="category-pills"
                        className="mt-5 mb-4 display-only"
                        key={category}
                        style={{
                          backgroundColor: getBackgroundColor(category),
                          color: getTextColor(category),
                          fontSize: "2rem",
                        }}>
                        {toTitleCase(category)}
                      </button>
                    </div>
                    <NewArrivalsView
                      products={currentProducts.filter(
                        (p) =>
                          p.category.toString() ===
                          toTitleCase(category).toUpperCase()
                      )}
                      selectedProduct={selectedProduct}
                      setSelectedProduct={setSelectedProduct}
                    />
                  </>
                ))}
              </>
            )}
          </Col>
        </>
      )}
    </Row>
  );
}
