import React from "react";
import { Product } from "../../models/Product";
import Accordion from "react-bootstrap/Accordion";
import { displayFormattedText } from "../../utils/Format";

export function ProductDescription({
  product,
}: {
  product: Product;
}): JSX.Element {
  return (
    <Accordion id="about-product-accordion" flush defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Product Description</Accordion.Header>
        <Accordion.Body>
          {displayFormattedText(product.longDescription)}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What you get with Keye</Accordion.Header>
        <Accordion.Body>
          {displayFormattedText(product.keyeBenefits)}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How a Keye Access Works</Accordion.Header>
        <Accordion.Body>
          <p>
            This page has information about a Keye partner product, including
            the number of Keye credits you need for a 24 hour access. After you
            click "Get Product now," you will be able to review the terms and
            conditions, and then click “Activate Access” to start using the
            product right away! Credits will automatically be deducted from your
            wallet, and if you have the Chrome extension installed, you should
            be automatically logged into the product you requested.
          </p>
          <p>
            Questions? Reach out to us at{" "}
            <a href="mailto:contact@keye.co">contact@keye.co</a>
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
