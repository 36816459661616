import moment from "moment/moment";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { CenterLoader } from "../components/Loader";
import { AuthContext } from "../context/AuthContext";
import {
  AccountAccess,
  CATEGORY,
  Product,
  ProductAccount,
  ProductReservationDetails,
  ProductScreenshot,
  ProductServiceUseStats,
  ProductStatus,
  SearchQuery,
  WaitlistDetails,
} from "../models/Product";
import { User, UserCredit } from "../models/User";
import { InvitationLink } from "../models/InvitationLink";
import {
  adminAddWhitelistUsers,
  adminCreateProductAccounts,
  adminDeactivateAccountAccess,
  adminDeactivateProductAccounts,
  adminForceStartReservation,
  adminGetAllAccountAccesses,
  adminGetAllUserCredits,
  adminGetAllUsers,
  adminGetAllUsersWhoRequestedCredits,
  adminGetProductAccount,
  adminGetReservation,
  adminGetServiceUseStats,
  adminGetWaitlist,
  adminGetWhitelistUsers,
  adminRemoveUsersWhoRequestedCredits,
  adminSendEmailWithTemplate,
  adminSetProductScreenshots,
  adminSetUserCredits,
  adminUpdateProductAccounts,
  adminUpdateProductData,
  deactivateRecentlyUsedAccounts,
  getComingSoonProductsForUser,
  getCreditsForProduct,
  getProductScreenshots,
  getProductsForUser,
  processPostAccess,
  processReservations,
  processWaitlist,
  getInvitationLinks,
} from "../scripts/createApiInstance";
import { displayDate, isEmpty } from "../scripts/util";

import "../styles/AdminView.scss";

const ButtonActionStyle = {
  margin: "10px",
};

function refreshPage() {
  window.location.reload();
}

export const AdminView = () => {
  const { apiInstance } = useContext(AuthContext);

  const [products, setProducts] = useState<Product[]>([]);
  const [comingSoonProducts, setComingSoonProducts] = useState<Product[]>([]);
  const [comingSoonLoader, setComingSoonLoader] = useState<boolean>(false);
  const [whitelistUsers, setWhitelistUsers] = useState<string[]>([]);
  const [whitelistLoader, setWhitelistLoader] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [productLoader, setProductLoader] = useState<boolean>(false);
  const [deactivateAccountLoader, setDeactivateAccountLoader] =
    useState<boolean>(false);
  const [processWaitlistLoader, setProcessWaitlistLoader] =
    useState<boolean>(false);
  const [processReservationLoader, setProcessReservationLoader] =
    useState<boolean>(false);
  const [showUploadAccountsModel, setShowUploadAccountsModal] =
    useState<boolean>(false);
  const [showWhitelistModal, setShowWhitelistModal] = useState<boolean>(false);
  const [showInivitationLinksModal, setShowInivitationLinksModal] =
    useState<boolean>(false);
  const [showCreditsModal, setShowCreditsModal] = useState<boolean>(false);
  const [showProductEditModal, setShowProductEditModal] =
    useState<boolean>(false);
  const [showEditScreenshotModal, setShowEditScreenshotModal] =
    useState<boolean>(false);
  const [showManageCreditRequestModal, setShowManageCreditRequestModal] =
    useState<boolean>(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false);
  const [selectedProductCredits, setSelectedProductCredits] =
    useState<string>("0");
  const [creditsLoader, setCreditsLoader] = useState<boolean>(false);
  const [changeState, setChangeState] = useState<boolean>(true);

  useEffect(() => {
    setProductLoader(true);
    getProductsForUser(apiInstance)
      .then(function (resp) {
        setProducts(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setProductLoader(false);
      });
  }, [apiInstance]);

  useEffect(() => {
    setComingSoonLoader(true);
    getComingSoonProductsForUser(apiInstance)
      .then(function (resp) {
        setComingSoonProducts(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setComingSoonLoader(false);
      });
  }, [apiInstance]);

  useEffect(() => {
    setWhitelistLoader(true);
    adminGetWhitelistUsers(apiInstance)
      .then(function (resp) {
        setWhitelistUsers(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setWhitelistLoader(false);
      });
  }, [apiInstance, changeState]);

  if (products.length > 0 && isEmpty(selectedProductId)) {
    setSelectedProductId(products[0].id);
  }

  useEffect(() => {
    if (selectedProductId) {
      setCreditsLoader(true);
      getCreditsForProduct(apiInstance, selectedProductId)
        .then(function (resp) {
          setSelectedProductCredits(resp.data);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setCreditsLoader(false);
        });
    }
  }, [apiInstance, selectedProductId]);

  const deactivateAccounts = function (productId: string) {
    setDeactivateAccountLoader(true);
    deactivateRecentlyUsedAccounts(apiInstance, productId)
      .then(function () {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDeactivateAccountLoader(false);
      });
  };

  const processWaitlistAction = function (productId: string) {
    setProcessWaitlistLoader(true);
    processWaitlist(apiInstance, productId)
      .then(function () {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setProcessWaitlistLoader(false);
      });
  };

  const processReservationAction = function (productId: string) {
    setProcessReservationLoader(true);
    processReservations(apiInstance, productId)
      .then(function () {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setProcessReservationLoader(false);
      });
  };

  var selectedProduct: Product | null = null;
  if (selectedProductId && products) {
    const filteredProducts = products.filter(
      (x) => x.id.toString() === selectedProductId.toString()
    );
    if (filteredProducts.length != 0) {
      selectedProduct = filteredProducts[0];
    } else {
      selectedProduct = comingSoonProducts.filter(
        (x) => x.id.toString() === selectedProductId.toString()
      )[0];
    }
  }

  return productLoader ||
    whitelistLoader ||
    creditsLoader ||
    comingSoonLoader ? (
    <CenterLoader />
  ) : (
    <Row>
      <UploadAccountsModal
        product={selectedProduct!}
        show={showUploadAccountsModel}
        handleClose={() => setShowUploadAccountsModal(false)}
        handleConfirm={() => {
          setShowUploadAccountsModal(false);
          setChangeState(!changeState);
        }}
      />
      <UploadWhitelistModal
        whitelistUsers={whitelistUsers}
        show={showWhitelistModal}
        handleClose={() => setShowWhitelistModal(false)}
        handleConfirm={() => {
          setShowWhitelistModal(false);
          setChangeState(!changeState);
        }}
      />
      <UpdateCreditsModal
        show={showCreditsModal}
        handleClose={() => setShowCreditsModal(false)}
        handleConfirm={() => {
          setShowCreditsModal(false);
          setChangeState(!changeState);
        }}
      />
      <ManageCreditsRequestModal
        show={showManageCreditRequestModal}
        handleClose={() => setShowManageCreditRequestModal(false)}
        handleConfirm={() => {
          setShowManageCreditRequestModal(false);
          setChangeState(!changeState);
        }}
      />
      <SendEmailModal
        show={showSendEmailModal}
        handleClose={() => setShowSendEmailModal(false)}
        handleConfirm={() => {
          setShowSendEmailModal(false);
          setChangeState(!changeState);
        }}
      />
      <InvitationLinksList
        show={showInivitationLinksModal}
        handleClose={() => setShowInivitationLinksModal(false)}
        handleConfirm={() => {
          setShowInivitationLinksModal(false);
          setChangeState(!changeState);
        }}
      />
      {selectedProduct && (
        <UpdateProductModal
          key={selectedProduct.id}
          show={showProductEditModal}
          handleClose={() => setShowProductEditModal(false)}
          handleConfirm={() => {
            setShowProductEditModal(false);
            refreshPage();
          }}
          originalProduct={selectedProduct!}
        />
      )}
      {selectedProduct && (
        <UpdateProductScreenshotModal
          key={`screenshot-${selectedProduct.id}`}
          show={showEditScreenshotModal}
          handleClose={() => setShowEditScreenshotModal(false)}
          handleConfirm={() => {
            setShowEditScreenshotModal(false);
            refreshPage();
          }}
          product={selectedProduct!}
        />
      )}
      <Col xs={10}>
        <div>
          <div className="form-group">
            <label htmlFor="productSelect" className="form-label mt-4">
              Product
            </label>
            <select
              value={selectedProductId}
              onChange={(e) => setSelectedProductId(e.target.value)}
              className="form-select"
              id="productSelect">
              {products.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.id} - {x.name}
                </option>
              ))}
              {comingSoonProducts.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.id} - (Coming Soon) {x.name}
                </option>
              ))}
            </select>
            <p>Credits: {selectedProductCredits}</p>
          </div>
          <div style={{ background: "blue" }}>
            {/*<button*/}
            {/*  disabled={deactivateAccountLoader}*/}
            {/*  type={"button"}*/}
            {/*  style={ButtonActionStyle}*/}
            {/*  className="btn btn-primary"*/}
            {/*  onClick={() => deactivateAccounts(selectedProductId)}>*/}
            {/*  Deactivate Recently Used Accounts*/}
            {/*</button>*/}
            <button
              disabled={processWaitlistLoader}
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => processWaitlistAction(selectedProductId)}>
              {" "}
              Process Waitlist
            </button>
            <button
              disabled={processReservationLoader}
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => processReservationAction(selectedProductId)}>
              Process Reservations
            </button>
            <button
              disabled={processReservationLoader}
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => processPostAccess(apiInstance, selectedProductId)}>
              Process Post Access
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowUploadAccountsModal(true)}>
              Upload Accounts
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowWhitelistModal(true)}>
              Manage Whitelist
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowCreditsModal(true)}>
              Manage Credits
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowManageCreditRequestModal(true)}>
              Manage Credit Requests
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowSendEmailModal(true)}>
              Send Email
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowInivitationLinksModal(true)}>
              Show Invitation Links
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowProductEditModal(true)}>
              Edit Product
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className="btn btn-primary"
              onClick={() => setShowEditScreenshotModal(true)}>
              Edit Screenshots
            </button>
            <button
              type={"button"}
              style={ButtonActionStyle}
              className={"btn btn-primary"}
              onClick={() => setChangeState(!changeState)}>
              Refresh
            </button>
          </div>
          <div>
            {!isEmpty(selectedProductId) ? (
              <div>
                <AccountsView
                  productId={selectedProductId}
                  changeState={changeState}
                  setChangeState={setChangeState}
                />
                <WaitlistView
                  productId={selectedProductId}
                  changeState={changeState}
                  setChangeState={setChangeState}
                />
                <ReservationView
                  productId={selectedProductId}
                  changeState={changeState}
                  setChangeState={setChangeState}
                />
                <AccountAccessesView
                  productId={selectedProductId}
                  changeState={changeState}
                  setChangeState={setChangeState}
                />
                <ServiceUseView
                  productId={selectedProductId}
                  changeState={changeState}
                  setChangeState={setChangeState}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export function UploadAccountsModal({
  product,
  show,
  handleClose,
  handleConfirm,
}: {
  product: Product;
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(true);
  const [textContent, setTextContent] = useState<string>();
  const [accountsToAdd, setAccountsToAdd] = useState<ProductAccount[]>([]);

  useEffect(() => {
    if (accountsToAdd.length > 0) {
      setDisableConfirmButton(false);
    } else {
      setDisableConfirmButton(true);
    }
  }, [accountsToAdd]);

  const saveAccounts = () => {
    setDisableConfirmButton(true);
    adminCreateProductAccounts(apiInstance, product.id, accountsToAdd)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Load accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Account,Password (CSV format)</p>
          <textarea
            className="text-area-modal"
            value={textContent}
            onChange={(event) => {
              setTextContent(event.target.value);
              setAccountsToAdd(
                getProductAccountParsedTextContent(event.target.value)
              );
            }}
          />
          {accountsToAdd.length > 0 && (
            <p>Going to save {accountsToAdd.length} accounts</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={saveAccounts}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function UploadWhitelistModal({
  whitelistUsers,
  show,
  handleClose,
  handleConfirm,
}: {
  whitelistUsers: string[];
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(true);
  const [textContent, setTextContent] = useState<string>();
  const [accountsToAdd, setAccountsToAdd] = useState<string[]>([]);

  useEffect(() => {
    if (accountsToAdd.length > 0) {
      setDisableConfirmButton(false);
    } else {
      setDisableConfirmButton(true);
    }
  }, [accountsToAdd]);

  const saveAccounts = () => {
    setDisableConfirmButton(true);
    adminAddWhitelistUsers(apiInstance, accountsToAdd)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update whitelist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Emails</p>
          <textarea
            className="text-area-modal"
            value={textContent}
            onChange={(event) => {
              setTextContent(event.target.value);
              setAccountsToAdd(
                getWhitelistParsedTextContent(event.target.value)
              );
            }}
          />
          {accountsToAdd.length > 0 && (
            <p>Going to save {accountsToAdd.length} accounts</p>
          )}
          <div id="whitelist-email">
            {whitelistUsers.map((x) => (
              <p>{x}</p>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={saveAccounts}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function UpdateCreditsModal({
  show,
  handleClose,
  handleConfirm,
}: {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(false);
  const [userCredits, setUserCredits] = useState<UserCredit[]>([]);
  const [userCreditsLoader, setUserCreditsLoader] = useState<boolean>(false);
  const [allCreditsValue, setAllCreditsValue] = useState<string>("0");

  useEffect(() => {
    if (show) {
      setUserCreditsLoader(true);
      adminGetAllUserCredits(apiInstance)
        .then(function (resp) {
          setUserCredits(resp.data);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setUserCreditsLoader(false);
        });
    }
  }, [show]);

  const saveCredits = () => {
    setDisableConfirmButton(true);
    adminSetUserCredits(apiInstance, userCredits)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  const tableHeader = (
    <tr>
      <th scope="col">User id</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Total Credits</th>
    </tr>
  );

  const setAllCredits = (val: string) => {
    const updatedCredits = userCredits.map((a) => {
      return {
        ...a,
        totalCredits: val,
        changed: true,
      };
    });
    // @ts-ignore
    setUserCredits(updatedCredits);
  };

  return (
    <Modal dialogClassName="credits-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update credits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userCreditsLoader ? (
          <CenterLoader />
        ) : (
          <div>
            <label className="input-label">
              Set All Credits
              <input
                className="product-edit-input"
                type="number"
                value={allCreditsValue}
                onChange={(e) => setAllCreditsValue(e.target.value)}
              />
            </label>
            <Button
              variant="primary"
              onClick={() => setAllCredits(allCreditsValue)}>
              Update Credits
            </Button>

            <table className="table table-hover">
              <thead>{tableHeader}</thead>
              <tbody>
                {userCredits.map((x) => (
                  <tr className="table-active">
                    <th scope="row">{x.user.id}</th>
                    <td>{`${x.user.firstName} ${x.user.lastName}`}</td>
                    <td>{x.user.email}</td>
                    <td>
                      <input
                        type="number"
                        value={x.totalCredits}
                        min="0"
                        max="10000000"
                        onChange={(e) =>
                          setUserCredits((prevState) =>
                            prevState.map((a) =>
                              a.user.id === x.user.id
                                ? {
                                    ...a,
                                    totalCredits: parseInt(e.target.value),
                                    changed: true,
                                  }
                                : { ...a }
                            )
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={saveCredits}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ManageCreditsRequestModal({
  show,
  handleClose,
  handleConfirm,
}: {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [creditRequestUsers, setCreditRequestUsers] = useState<string[]>([]);
  const [usersLoader, setUsersLoader] = useState<boolean>(false);
  const [creditRequestUsersLoader, setCreditRequestUsersLoader] =
    useState<boolean>(false);
  const [usersToRemove, setUsersToRemove] = useState<string[]>([]);

  useEffect(() => {
    if (show) {
      setUsersLoader(true);
      adminGetAllUsers(apiInstance)
        .then(function (resp) {
          setUsers(resp.data);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setUsersLoader(false);
        });
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setCreditRequestUsersLoader(true);
      adminGetAllUsersWhoRequestedCredits(apiInstance)
        .then(function (resp) {
          setCreditRequestUsers(resp.data);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setCreditRequestUsersLoader(false);
        });
    }
  }, [show]);

  const removeRequests = () => {
    setDisableConfirmButton(true);
    adminRemoveUsersWhoRequestedCredits(apiInstance, usersToRemove)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  const tableHeader = (
    <tr>
      <th scope="col">User id</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Remove</th>
    </tr>
  );

  var userMap = new Map<string, User>();
  users.forEach((element) => {
    userMap.set(element.id, element);
  });

  console.log("This is users to remove");
  console.log(usersToRemove);

  return (
    <Modal dialogClassName="credits-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manage credits increase requests</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {usersLoader || creditRequestUsersLoader ? (
          <CenterLoader />
        ) : (
          <div>
            <table className="table table-hover">
              <thead>{tableHeader}</thead>
              <tbody>
                {creditRequestUsers.map((x) => (
                  <tr className="table-active">
                    <th scope="row">{x}</th>
                    <td>{`${userMap.get(x)?.firstName} ${
                      userMap.get(x)?.lastName
                    }`}</td>
                    <td>{userMap.get(x)?.email}</td>
                    <td>
                      <input
                        type="checkbox"
                        className={"form-check-input"}
                        checked={usersToRemove.includes(x)}
                        onChange={(e) =>
                          setUsersToRemove((prevState) =>
                            prevState.includes(x)
                              ? prevState.filter((a) => a !== x)
                              : [...prevState, x]
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={removeRequests}>
          Remove Requests
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function SendEmailModal({
  show,
  handleClose,
  handleConfirm,
}: {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [templateId, setTemplateId] = useState<string>("");
  const [usersLoader, setUsersLoader] = useState<boolean>(false);
  const [userEmails, setUserEmails] = useState<string[]>([]);

  useEffect(() => {
    if (show) {
      setUsersLoader(true);
      adminGetAllUsers(apiInstance)
        .then(function (resp) {
          setUsers(resp.data);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setUsersLoader(false);
        });
    }
  }, [show]);

  const sendEmails = () => {
    if (isEmpty(templateId)) {
      return;
    }

    setDisableConfirmButton(true);
    adminSendEmailWithTemplate(apiInstance, templateId, userEmails)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  const tableHeader = (
    <tr>
      <th scope="col">User id</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Send</th>
    </tr>
  );

  console.log("This is emails to send");
  console.log(userEmails);

  return (
    <Modal dialogClassName="credits-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {usersLoader ? (
          <CenterLoader />
        ) : (
          <div>
            <TextInputForm
              labelText={"Sengrid Template Id:"}
              val={templateId}
              onChangeHandler={(e) => setTemplateId(e.target.value)}
            />
            <p>
              To send to all users, just put the template id and don't check any
              box
            </p>
            <table className="table table-hover">
              <thead>{tableHeader}</thead>
              <tbody>
                {users.map((x) => (
                  <tr className="table-active">
                    <th scope="row">{x.id}</th>
                    <td>{`${x.firstName} ${x.lastName}`}</td>
                    <td>{x.email}</td>
                    <td>
                      <input
                        type="checkbox"
                        className={"form-check-input"}
                        checked={userEmails.includes(x.email)}
                        onChange={(e) =>
                          setUserEmails((prevState) =>
                            prevState.includes(x.email)
                              ? prevState.filter((a) => a !== x.email)
                              : [...prevState, x.email]
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={sendEmails}>
          Send Email
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function UpdateProductModal({
  originalProduct,
  show,
  handleClose,
  handleConfirm,
}: {
  originalProduct: Product;
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(false);
  const [product, setProduct] = useState<Product>(originalProduct);

  const saveProductData = () => {
    setDisableConfirmButton(true);
    adminUpdateProductData(apiInstance, product)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  const categories = Object.keys(CATEGORY).filter(
    // @ts-ignore
    (key) => !isNaN(Number(CATEGORY[key]))
  );

  const statuses = Object.keys(ProductStatus).filter(
    // @ts-ignore
    (key) => !isNaN(Number(ProductStatus[key]))
  );

  return (
    <Modal dialogClassName="credits-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update product {product.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextInputForm
            labelText={"Name:"}
            val={product.name}
            onChangeHandler={(e) =>
              setProduct({ ...product, name: e.target.value })
            }
          />
          <div>
            <label>
              Category:
              <select
                value={product.category}
                onChange={(e) => {
                  // @ts-ignore
                  setProduct({ ...product, category: e.target.value });
                }}
                className="form-select">
                {categories.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <TextInputForm
            labelText={"Short Description:"}
            val={product.shortDescription}
            onChangeHandler={(e) =>
              setProduct({ ...product, shortDescription: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Credit Price:"}
            val={product?.creditPrice?.toString()}
            onChangeHandler={(e) =>
              setProduct({ ...product, creditPrice: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Retail Price:"}
            val={product.retailPrice.toString()}
            onChangeHandler={(e) =>
              setProduct({ ...product, retailPrice: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Retail Price:"}
            val={product.retailFrequency.toString()}
            onChangeHandler={(e) =>
              setProduct({ ...product, retailFrequency: e.target.value })
            }
          />
          <TextAreaForm
            labelText={"Long Description:"}
            val={product.longDescription}
            onChangeHandler={(e) =>
              setProduct({ ...product, longDescription: e.target.value })
            }
          />
          <TextAreaForm
            labelText={"Keye Benefits:"}
            val={product.keyeBenefits}
            onChangeHandler={(e) =>
              setProduct({ ...product, keyeBenefits: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Url:"}
            val={product.url}
            onChangeHandler={(e) =>
              setProduct({ ...product, url: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Login Url:"}
            val={product.loginUrl}
            onChangeHandler={(e) =>
              setProduct({ ...product, loginUrl: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Img Url:"}
            val={product.imgUrl}
            onChangeHandler={(e) =>
              setProduct({ ...product, imgUrl: e.target.value })
            }
          />
          <TextAreaForm
            labelText={"Additional Terms:"}
            val={product.additionalTerms}
            onChangeHandler={(e) =>
              setProduct({ ...product, additionalTerms: e.target.value })
            }
          />
          <TextInputForm
            labelText={"Coming Soon Date:"}
            val={product.comingSoonDate}
            onChangeHandler={(e) =>
              setProduct({ ...product, comingSoonDate: e.target.value })
            }
          />
          <div>
            <label>
              Status:
              <select
                value={product.status}
                onChange={(e) => {
                  // @ts-ignore
                  setProduct({ ...product, status: e.target.value });
                }}
                className="form-select">
                {statuses.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <ProductFeaturedMetadata product={product} setProduct={setProduct} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={saveProductData}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function UpdateProductScreenshotModal({
  product,
  show,
  handleClose,
  handleConfirm,
}: {
  product: Product;
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(false);
  const [productScreenshots, setProductScreenshots] = useState<
    ProductScreenshot[]
  >([]);
  const [screenshotLoader, setScreenshotLoader] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>("-1");

  useEffect(() => {
    setScreenshotLoader(true);
    getProductScreenshots(apiInstance, product.id)
      .then(function (resp) {
        setProductScreenshots(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setScreenshotLoader(false);
      });
  }, [apiInstance, product.id]);

  const saveProductScreenshots = () => {
    setDisableConfirmButton(true);
    const screenshotsToSave = productScreenshots.filter(
      (x) => !isEmpty(x.imgUrl)
    );
    adminSetProductScreenshots(apiInstance, product.id, screenshotsToSave)
      .then(function () {
        handleConfirm();
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDisableConfirmButton(false);
      });
  };

  return (
    <Modal dialogClassName="credits-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update product {product.name} screenshots</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Editing screenshots</p>
          {productScreenshots.map((x) => (
            <div>
              <input
                className="product-edit-input"
                type="text"
                value={x.imgUrl}
                onChange={(e) =>
                  setProductScreenshots((prevState) =>
                    prevState.map((y) =>
                      y.id === x.id
                        ? { ...y, imgUrl: e.target.value }
                        : { ...y }
                    )
                  )
                }
              />
              <Button
                className="btn btn-danger"
                onClick={() =>
                  setProductScreenshots((prevState) =>
                    prevState.filter((item) => item.id !== x.id)
                  )
                }>
                Delete
              </Button>
            </div>
          ))}

          <Button
            variant="btn btn-info"
            onClick={() => {
              // @ts-ignore
              setProductScreenshots((prevState) => [
                ...prevState,
                { imgUrl: "", id: currentId },
              ]);
              setCurrentId(`${parseInt(currentId) - 1}`);
            }}>
            Add
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={saveProductScreenshots}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const ProductFeaturedMetadata = ({
  product,
  setProduct,
}: {
  product: Product;
  setProduct: (e: any) => void;
}): JSX.Element => {
  const featuredMetadata = product.featuredMetadata
    ? product.featuredMetadata
    : {
        featured: false,
        featuredMessage: "",
        extraDescription: "",
        contentUrl: "",
      };
  return (
    <div>
      <label className="input-label">
        Featured
        <input
          type="checkbox"
          className={"form-check-input"}
          checked={featuredMetadata.featured}
          onChange={(e) =>
            setProduct({
              ...product,
              featuredMetadata: {
                ...featuredMetadata,
                featured: !featuredMetadata.featured,
              },
            })
          }
        />
      </label>
      <TextInputForm
        labelText={"Featured Message:"}
        val={featuredMetadata.featuredMessage}
        onChangeHandler={(e) =>
          setProduct({
            ...product,
            featuredMetadata: {
              ...featuredMetadata,
              featuredMessage: e.target.value,
            },
          })
        }
      />
    </div>
  );
};

export const TextInputForm = ({
  labelText,
  val,
  onChangeHandler,
}: {
  labelText: string;
  val: string;
  onChangeHandler: (e: any) => void;
}): JSX.Element => {
  return (
    <div>
      <label className="input-label">
        {labelText}
        <input
          className="product-edit-input"
          type="text"
          value={val}
          onChange={onChangeHandler}
        />
      </label>
    </div>
  );
};

export const TextAreaForm = ({
  labelText,
  val,
  onChangeHandler,
}: {
  labelText: string;
  val: string;
  onChangeHandler: (e: any) => void;
}): JSX.Element => {
  return (
    <div>
      <label className="input-label">
        {labelText}
        <textarea
          className="product-edit-input"
          value={val}
          onChange={onChangeHandler}
        />
      </label>
    </div>
  );
};

const getProductAccountParsedTextContent = (
  textContent: string
): ProductAccount[] => {
  var accountsToAdd: ProductAccount[] = [];
  const rows = textContent.split("\n");
  for (var i = 0; i < rows.length; i++) {
    var creds;
    if (rows[i].includes("\t")) {
      creds = rows[i].split("\t");
    } else if (rows[i].includes(",")) {
      creds = rows[i].split(",");
    } else {
      creds = rows[i].split("s+");
    }
    // @ts-ignore
    accountsToAdd.push({ username: creds[0], password: creds[1] });
  }
  return accountsToAdd;
};

const getWhitelistParsedTextContent = (textContent: string): string[] => {
  var accountsToAdd: string[] = [];
  const rows = textContent.split("\n");
  for (var i = 0; i < rows.length; i++) {
    const email = rows[i].replaceAll(" ", "");
    accountsToAdd.push(email);
  }
  return accountsToAdd;
};

const TableStyle = {
  margin: "20px",
};

export const AccountsView = ({
  productId,
  changeState,
  setChangeState,
}: {
  productId: string;
  changeState: boolean;
  setChangeState: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiInstance } = useContext(AuthContext);
  const [availableAccounts, setAvailableAccounts] = useState<ProductAccount[]>(
    []
  );
  const [availableLoader, setAvailableLoader] = useState<boolean>(false);
  const [allAccounts, setAllAccounts] = useState<ProductAccount[]>([]);
  const [accountsLoader, setAccountsLoader] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [updatingAccountsLoader, setUpdatingAccountsLoader] =
    useState<boolean>(false);
  const [deactivatingAccountsLoader, setDeactivatingAccountsLoader] =
    useState<boolean>(false);

  useEffect(() => {
    setAvailableLoader(true);
    adminGetProductAccount(apiInstance, productId, true)
      .then(function (resp) {
        setAvailableAccounts(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setAvailableLoader(false);
      });
  }, [productId, apiInstance, changeState]);
  //
  useEffect(() => {
    setAccountsLoader(true);
    adminGetProductAccount(apiInstance, productId, false)
      .then(function (resp) {
        setAllAccounts(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setAccountsLoader(false);
      });
  }, [productId, apiInstance, changeState]);

  const updateProductAccounts = function (productAccounts: ProductAccount[]) {
    const changedAccounts = productAccounts.filter((x) => x.changed);
    if (changedAccounts.length === 0) {
      return;
    }
    setUpdatingAccountsLoader(true);
    adminUpdateProductAccounts(apiInstance, changedAccounts, true)
      .then(function (resp) {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setUpdatingAccountsLoader(false);
      });
  };

  const deactivateProductAccounts = function (
    productAccounts: ProductAccount[]
  ) {
    const deactivateAccounts = productAccounts
      .filter((x) => x.shouldDeactivate)
      .map((x) => x.productAccountId);
    if (deactivateAccounts.length === 0) {
      return;
    }
    setDeactivatingAccountsLoader(true);
    adminDeactivateProductAccounts(apiInstance, deactivateAccounts)
      .then(function (resp) {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setDeactivatingAccountsLoader(false);
      });
  };

  const tableHeader = (
    <tr>
      <th scope="col">Account Id</th>
      <th scope="col">Product Id</th>
      <th scope="col">Active</th>
      <th scope="col">Created</th>
      <th scope="col">Last Password Change</th>
      <th scope="col">Username</th>
      <th scope="col">Password</th>
      <th scope="col">Concurrent Limit</th>
      <th scope="col">Remaining Access</th>
      <th scope="col">Deactivate</th>
    </tr>
  );

  return !availableLoader && !accountsLoader ? (
    <div>
      <div style={TableStyle}>
        <h3>Available accounts</h3>
        <table className="table table-hover">
          <thead>{tableHeader}</thead>
          <tbody>
            {availableAccounts?.map((x) => (
              <tr className="table-active">
                <th scope="row">{x.productAccountId}</th>
                <td>{x.productId}</td>
                <td>{JSON.stringify(x.active)}</td>
                <td>{displayDate(x.created)}</td>
                <td>{displayDate(x.updated)}</td>
                <td>{x.username}</td>
                <td>{x.password}</td>
                <td>{x.concurrentLimit}</td>
                <td>{x.remainingAccess}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={TableStyle}>
        <h3>All accounts</h3>
        {editing ? (
          <div>
            <button
              style={ButtonActionStyle}
              type={"button"}
              className={"btn btn-info"}
              onClick={() => {
                setEditing(false);
                updateProductAccounts(allAccounts);
              }}>
              Save
            </button>
            <button
              style={ButtonActionStyle}
              type={"button"}
              className={"btn btn-info"}
              onClick={() => {
                setEditing(false);
                deactivateProductAccounts(allAccounts);
              }}>
              Bulk Deactivate
            </button>
          </div>
        ) : (
          <button
            type={"button"}
            className={"btn btn-info"}
            onClick={() => setEditing(true)}>
            Edit
          </button>
        )}
        {updatingAccountsLoader && <CenterLoader />}
        {deactivatingAccountsLoader && <CenterLoader />}
        <table className="table table-hover">
          <thead>{tableHeader}</thead>
          <tbody>
            {allAccounts?.map((x) => (
              <tr className="table-active">
                <th scope="row">{x.productAccountId}</th>
                <td>{x.productId}</td>
                <td>{JSON.stringify(x.active)}</td>
                <td>{displayDate(x.created)}</td>
                <td>{displayDate(x.updated)}</td>
                <td>{x.username}</td>
                {editing ? (
                  <td>
                    <input
                      type="text"
                      placeholder="Password"
                      value={x.password}
                      onChange={(e) =>
                        setAllAccounts((prevState) =>
                          prevState.map((a) =>
                            a.productAccountId === x.productAccountId
                              ? {
                                  ...a,
                                  password: e.target.value,
                                  changed: true,
                                }
                              : { ...a }
                          )
                        )
                      }
                    />
                  </td>
                ) : (
                  <td>{x.password}</td>
                )}
                {editing ? (
                  <td>
                    <input
                      type="number"
                      value={x.concurrentLimit}
                      min="0"
                      max="10000"
                      onChange={(e) =>
                        setAllAccounts((prevState) =>
                          prevState.map((a) =>
                            a.productAccountId === x.productAccountId
                              ? {
                                  ...a,
                                  concurrentLimit: parseInt(e.target.value),
                                  changed: true,
                                }
                              : { ...a }
                          )
                        )
                      }
                    />
                  </td>
                ) : (
                  <td>{x.concurrentLimit}</td>
                )}
                <td>--</td>
                {editing ? (
                  <td>
                    <input
                      type="checkbox"
                      className={"form-check-input"}
                      checked={x.shouldDeactivate}
                      onChange={(e) =>
                        setAllAccounts((prevState) =>
                          prevState.map((a) =>
                            a.productAccountId === x.productAccountId
                              ? { ...a, shouldDeactivate: !x.shouldDeactivate }
                              : { ...a }
                          )
                        )
                      }
                    />
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <CenterLoader />
  );
};

export const WaitlistView = ({
  productId,
  changeState,
  setChangeState,
}: {
  productId: string;
  changeState: boolean;
  setChangeState: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiInstance } = useContext(AuthContext);
  const [waitlistAccounts, setWaitlistAccounts] = useState<WaitlistDetails[]>(
    []
  );
  const [waitlistLoader, setWaitlistLoader] = useState<boolean>(false);

  useEffect(() => {
    const query: SearchQuery = {
      page: 0,
      limit: 100,
    };
    setWaitlistLoader(true);
    adminGetWaitlist(apiInstance, productId, query)
      .then(function (resp) {
        setWaitlistAccounts(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setWaitlistLoader(false);
      });
  }, [productId, apiInstance, changeState]);

  return (
    <div style={TableStyle}>
      <h3>Waitlist</h3>
      {waitlistLoader ? (
        <CenterLoader />
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Waitlist Id</th>
              <th scope="col">User Id</th>
              <th scope="col">Request Time</th>
              <th scope="col">Scheduled Time</th>
              <th scope="col">Access Start</th>
              <th scope="col">Waitlist Status</th>
              <th scope="col">Status Change</th>
            </tr>
          </thead>
          <tbody>
            {waitlistAccounts?.map((x) => (
              <tr className="table-active">
                <th scope="row">{x.id}</th>
                <td>{x.userId}</td>
                <td>{displayDate(x.requestTime)}</td>
                <td>{displayDate(x.scheduledTime)}</td>
                <td>{displayDate(x.accessStart)}</td>
                <td>{x.waitlistStatus}</td>
                <td>{displayDate(x.statusChange)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const ReservationView = ({
  productId,
  changeState,
  setChangeState,
}: {
  productId: string;
  changeState: boolean;
  setChangeState: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiInstance } = useContext(AuthContext);
  const [reservationDetails, setReservationDetails] = useState<
    ProductReservationDetails[]
  >([]);
  const [reservationLoader, setReservationLoader] = useState<boolean>(false);

  useEffect(() => {
    setReservationLoader(true);
    adminGetReservation(apiInstance, productId)
      .then(function (resp) {
        setReservationDetails(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setReservationLoader(false);
      });
  }, [productId, apiInstance, changeState]);

  const forceStartRegistrationAction = (id: string) => {
    adminForceStartReservation(apiInstance, id)
      .then(function (resp) {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {});
  };

  return (
    <div style={TableStyle}>
      <h3>Reservations</h3>
      {reservationLoader ? (
        <CenterLoader />
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Reservation Id</th>
              <th scope="col">User Id</th>
              <th scope="col">Product Account Id</th>
              <th scope="col">Scheduled Time</th>
              <th scope="col">Active</th>
              <th scope="col">Created</th>
              <th scope="col">Updated</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {reservationDetails?.map((x) => (
              <tr className="table-active">
                <th scope="row">{x.id}</th>
                <td>{x.userId}</td>
                <td>{x.productAccountId}</td>
                <td>{displayDate(x.scheduledTime)}</td>
                <td>{x.active}</td>
                <td>{displayDate(x.created)}</td>
                <td>{displayDate(x.updated)}</td>
                <td>
                  {x.active && (
                    <button
                      type={"button"}
                      className={"btn btn-info"}
                      onClick={() => forceStartRegistrationAction(x.id)}>
                      Start
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const AccountAccessesView = ({
  productId,
  changeState,
  setChangeState,
}: {
  productId: string;
  changeState: boolean;
  setChangeState: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiInstance } = useContext(AuthContext);
  const [accountAccesses, setAccountAccesses] = useState<AccountAccess[]>([]);
  const [accessesLoader, setAccessesLoader] = useState<boolean>(false);

  useEffect(() => {
    setAccessesLoader(true);
    adminGetAllAccountAccesses(apiInstance, productId)
      .then(function (resp) {
        setAccountAccesses(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setAccessesLoader(false);
      });
  }, [productId, apiInstance, changeState]);

  const deactivateAccountAccess = (id: string) => {
    adminDeactivateAccountAccess(apiInstance, id)
      .then(function (resp) {
        setChangeState(!changeState);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {});
  };

  return (
    <div style={TableStyle}>
      <h3>Account Accesses</h3>
      {accessesLoader ? (
        <CenterLoader />
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Accessed Date</th>
              <th scope="col">Expiration Date</th>
              <th scope="col">Price</th>
              <th scope="col">Active</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {accountAccesses?.map((x) => (
              <tr className="table-active">
                <th scope="row">{displayDate(x.accessed)}</th>
                <td>{displayDate(x.expiration)}</td>
                <td>{x.price}</td>
                <td>{JSON.stringify(x.active)}</td>
                <td>
                  {x.active && moment.utc(x.expiration) > moment.utc() && (
                    <button
                      type={"button"}
                      className={"btn btn-danger"}
                      onClick={() => deactivateAccountAccess(x.id)}>
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const ServiceUseView = ({
  productId,
  changeState,
  setChangeState,
}: {
  productId: string;
  changeState: boolean;
  setChangeState: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiInstance } = useContext(AuthContext);
  const [serviceUseStats, setServiceUseStats] = useState<
    ProductServiceUseStats[]
  >([]);
  const [accessesLoader, setAccessesLoader] = useState<boolean>(false);

  useEffect(() => {
    setAccessesLoader(true);
    adminGetServiceUseStats(apiInstance)
      .then(function (resp) {
        setServiceUseStats(resp.data);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setAccessesLoader(false);
      });
  }, [productId, apiInstance, changeState]);

  return (
    <div style={TableStyle}>
      <h3>Service Use</h3>
      {accessesLoader ? (
        <CenterLoader />
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Name of Service</th>
              <th scope="col">Total Accounts</th>
              <th scope="col">Total Accesses</th>
              <th scope="col">Users currently using the service</th>
              <th scope="col">Accounts remaining to be alloted</th>
            </tr>
          </thead>
          <tbody>
            {serviceUseStats?.map((x) => (
              <tr className="table-active">
                <th scope="row">{x.productName}</th>
                <td>{x.totalAccounts}</td>
                <td>{x.totalAccesses}</td>
                <td>{x.activeAccesses}</td>
                <td>{x.remainingAccesses}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export function InvitationLinksList({
  show,
  handleClose,
  handleConfirm,
}: {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [disableConfirmButton, setDisableConfirmButton] =
    useState<boolean>(false);
  const [invites, setInvites] = useState<InvitationLink[]>([]);
  const [templateId, setTemplateId] = useState<string>("");
  const [invitationLoader, setInvitationLoader] = useState<boolean>(false);
  const [userEmails, setUserEmails] = useState<string[]>([]);

  useEffect(() => {
    if (show) {
      setInvitationLoader(true);
      getInvitationLinks(apiInstance)
        .then(function (resp) {
          console.log(resp.data);
          setInvites(resp.data);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          setInvitationLoader(false);
        });
    }
  }, [show]);

  // const sendEmails = () => {
  //   if (isEmpty(templateId)) {
  //     return;
  //   }

  //   setDisableConfirmButton(true);
  //   adminSendEmailWithTemplate(apiInstance, templateId, userEmails)
  //     .then(function () {
  //       handleConfirm();
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     })
  //     .finally(function () {
  //       setDisableConfirmButton(false);
  //     });
  // };

  const tableHeader = (
    <tr>
      <th scope="col">id</th>
      <th scope="col">url</th>
      <th scope="col">tags</th>
      <th scope="col">expires on</th>
      <th scope="col">Usage Threshold</th>
      <th scope="col">Usage Count</th>
      <th scope="col">is Active</th>
    </tr>
  );

  console.log("This is emails to send");
  console.log(userEmails);

  return (
    <Modal dialogClassName="credits-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invitation Links</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {invitationLoader ? (
          <CenterLoader />
        ) : (
          <div>
            <table className="table table-hover">
              <thead>{tableHeader}</thead>
              <tbody>
                {invites.map((x) => (
                  <tr className="table-active">
                    <th scope="row">{x.id}</th>
                    <td>{x.url}</td>
                    <td>{JSON.stringify(x.tags)}</td>
                    <td>{x.expiresOn}</td>
                    <td>{x.usageThreashold}</td>
                    <td>{x.usageCount}</td>
                    <td>{String(x.active)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          variant="primary"
          disabled={disableConfirmButton}
          onClick={sendEmails}>
          Send Email
        </Button> */}
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
