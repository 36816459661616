import * as FullStory from "@fullstory/browser";
import { User } from "../models/User";
import { isDev } from "./createApiInstance";

export const initFullstory = (orgIdentifier: string) => {
  FullStory.init({ orgId: orgIdentifier });
};

/**
 * We want to call this once and at the root level, so on the HomeView.
 * This associates our user's userID with the fullstory session.
 * @param user
 */
export const identifyFSUser = (user: User) => {
  console.log("{user.id}", user.id);
  const isDevelopment = isDev();
  const userId = isDevelopment ? `${user.id}` : user.id;
  const displayName = isDevelopment
    ? `${user.firstName} ${user.lastName}`
    : `${user.firstName} ${user.lastName}`;
  const email = isDevelopment ? `${user.email}` : user.email;
  FullStory.setUserVars({
    displayName: displayName,
    email: email,
  });
  FullStory.identify(userId, {
    displayName: displayName,
    email: email,
  });
};
