import {
  Product,
  ProductCreditCost,
  WaitlistDetails,
  WaitlistStatus,
} from "../../models/Product";
import {
  getBackgroundColor,
  getTextColor,
  toTitleCase,
} from "../../scripts/util";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { updateWaitlistStatusForUser } from "../../scripts/createApiInstance";
import { useHistory } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  productDetailsBundle,
  sendAnalyticsData,
} from "../../scripts/analytics";

import "../../styles/ProductCardStyles/WaitlistProductCard.scss";

export function WaitlistProductCard({
  product,
  waitlistDetails,
  selectedProduct,
  setSelectedProduct,
  creditCost,
}: {
  product: Product;
  waitlistDetails: WaitlistDetails;
  selectedProduct: Product | undefined;
  setSelectedProduct: (product: Product | undefined) => void;
  creditCost: ProductCreditCost | undefined;
}): JSX.Element | null {
  const { apiInstance } = useContext(AuthContext);
  // const [showWaitlistStatus, setShowWaitlistStatus] = useState(false);
  const history = useHistory();
  const { user } = useContext(AuthContext);

  async function handleLeaveWaitlist(): Promise<void> {
    if (waitlistDetails) {
      try {
        const response = await updateWaitlistStatusForUser(
          apiInstance,
          waitlistDetails.id,
          WaitlistStatus.WITHDRAWN
        );
        if (response.status === 204) {
          history.push(`/waitlist/${product.id}`);
        }
      } catch (e) {
        console.log("Error leaving waitlist: ", e);
      }
    }
  }

  function sendQuickViewClickEvent() {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_QUICK_VIEW, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, product.status.toString()),
    });
  }

  return (
    <Card
      id="product-card"
      className={`${
        selectedProduct === product ? "selected-product-card" : ""
      }`}>
      <Card.Img
        variant="top"
        src={product.imgUrl}
        alt={product.name}
        id="product-card-image"
        onClick={() => {
          if (selectedProduct === product) {
            setSelectedProduct(undefined);
          } else {
            sendQuickViewClickEvent();

            setSelectedProduct(product);
          }
        }}
      />

      <Card.Body className="d-flex flex-column" id="waitlist-product-card-body">
        <div
          onClick={() => {
            if (selectedProduct === product) {
              setSelectedProduct(undefined);
            } else {
              sendQuickViewClickEvent();
              setSelectedProduct(product);
            }
          }}>
          <div id="waitlist-product-card-top-row">
            <Card.Text as="h5" id="waitlist-product-card-name">
              {product.name}
            </Card.Text>
            <Card.Text as="h5" id="waitlist-product-flag">
              WAITLISTED
            </Card.Text>
          </div>
          <div id="waitlist-product-card-bottom-row">
            <Card.Text>
              <Button
                disabled
                id="decorative-category-pills"
                style={{
                  backgroundColor: getBackgroundColor(String(product.category)),
                  color: getTextColor(String(product.category)),
                }}>
                {toTitleCase(String(product.category))}
              </Button>
            </Card.Text>
          </div>
        </div>
        {selectedProduct === product && (
          <>
            <Card.Text> {product.shortDescription} </Card.Text>

            <Card.Body className="d-flex flex-row justify-content-between ">
              <Button disabled variant="light" id="product-card-action-button">
                Waitlisted
              </Button>
              {/* {!showWaitlistStatus && ( */}
              <Button
                id="product-card-action-button"
                variant="success"
                onClick={() => {
                  // As of right now, we just want to redirect the user to the waitlist page
                  // setShowWaitlistStatus(true);
                  history.push(`/product/${product.id}`);
                }}>
                View Status
              </Button>
              {/* )} */}
              {/* if / when we decide to go back to this model, we should make it look nicer */}
              {/* {showWaitlistStatus && (
          <div id="waitlist-status-div">
            <p id="waitlist-status-header-text">Viewing Status</p>
            <p id="waitlist-next-available-header-text">Next Available Slot</p>
            {nextAvailableTime && (
              <p id="waitlist-next-available-time-text">
                {nextAvailableTime}
                <br />
              </p>
            )}
            <div id="leave-waitlist-button">
              <button
                className={"btn btn-danger"}
                id="waitlisted-button"
                onClick={() => {
                  handleLeaveWaitlist();
                }}>
                <span id="leave-waitlist-text">Leave Waitlist</span>
              </button>

              <button
                id="waitlist-status-close-button"
                className={"btn"}
                onClick={() => setShowWaitlistStatus(false)}>
                X
              </button>
            </div>
          </div>
        )} */}
            </Card.Body>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
