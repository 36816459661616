import { isEmpty } from "../scripts/util";

export function displayFormattedText(text: string) {
  if (isEmpty(text)) {
    return null;
  }
  return text.split("\\n").map((x) => {
    if (isEmpty(x)) {
      return <br />;
    }
    return <p key={x}>{x}</p>;
  });
}
