import moment from "moment/moment";
import { CATEGORY, Frequency, Product, ProductSurvey } from "../models/Product";

export function isEmpty(str: string) {
  return !str || str.length === 0;
}

export interface TabLink {
  name: string;
  link: string;
  event_name: string;
}

export function displayDate(str: string) {
  if (isEmpty(str)) {
    return "--";
  }
  return moment.utc(str).local().format("MM/DD/YY h:mm A");
}

export function displayDateOnly(str: string) {
  if (isEmpty(str)) {
    return "--";
  }
  return moment.utc(str).local().format("MM/DD/YY");
}

export function displayDateOnlyFromMoment(date: moment.Moment | undefined) {
  if (date === undefined) {
    return "--";
  }
  return date.format("MM/DD/YY");
}

export function getUTCDate(str: string) {
  return moment.utc(str);
}

export function getHoursLeft(str: string): number {
  const targetTime = moment.utc(str).local();
  const duration = moment.duration(targetTime.diff(moment.now()));
  return duration.get("hours");
}

export function getCurrentEpoch() {
  return moment.utc().valueOf();
}

export function currentTime() {
  return moment.utc();
}

export function getRecurringDescription(frequency: Frequency) {
  // @ts-ignore
  const convertedFrequency: Frequency = Frequency[frequency];
  if (convertedFrequency === Frequency.DAILY) {
    return "Recurring daily subscription";
  }
  if (convertedFrequency === Frequency.WEEKLY) {
    return "Recurring weekly subscription";
  }
  if (convertedFrequency === Frequency.MONTHLY) {
    return "Recurring monthly subscription";
  }
  if (convertedFrequency === Frequency.YEARLY) {
    return "Recurring yearly subscription";
  }

  return "Unlimited subscription";
}

export function getKeyeAccessDescription(frequency: Frequency) {
  // @ts-ignore
  const convertedFrequency: Frequency = Frequency[frequency];
  if (convertedFrequency === Frequency.DAILY) {
    return "24 hours";
  }
  if (convertedFrequency === Frequency.WEEKLY) {
    return "1 week";
  }
  if (convertedFrequency === Frequency.MONTHLY) {
    return "1 month";
  }
  if (convertedFrequency === Frequency.YEARLY) {
    return "1 year";
  }

  return "Unlimited hours";
}
export function getBackgroundColor(category: any) {
  switch (category) {
    case "HEALTH":
      return "rgba(235, 87, 87, 0.1)";
    case "EDUCATION":
      return "rgba(242, 153, 74, 0.1)";
    case "NEWS":
      return "rgba(155, 81, 224, 0.1)";
    case "DATA":
      return "rgba(47, 128, 237, 0.1)";
    case "ENTERTAINMENT":
      return "rgba(25, 203, 10, 0.1)";
    case "PRODUCTIVITY":
      return "rgba(231, 224, 3, 0.1)";
    case "None":
      return "rgba(51, 51, 51, 1)";
  }
}

export function getTextColor(category: any) {
  switch (category) {
    case "HEALTH":
      return "#EB5757";
    case "EDUCATION":
      return "#F2994A";
    case "NEWS":
      return "#9B51E0";
    case "DATA":
      return "#2F80ED";
    case "ENTERTAINMENT":
      return "#168d0f";
    case "PRODUCTIVITY":
      return "#b99b2c";
    case "None" || null:
      return "#FFFFFF";
  }
}

export function getCategory(category: string): CATEGORY {
  switch (category) {
    case "HEALTH":
      return CATEGORY.HEALTH;
    case "EDUCATION":
      return CATEGORY.EDUCATION;
    case "NEWS":
      return CATEGORY.NEWS;
    case "DATA":
      return CATEGORY.DATA;
    case "ENTERTAINMENT":
      return CATEGORY.ENTERTAINMENT;
    case "PRODUCTIVITY":
      return CATEGORY.PRODUCTIVITY;
  }
  // Default to data
  return CATEGORY.DATA;
}

export function toTitleCase(str: any) {
  return str.replace(/\w\S*/g, function (txt: any) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function getDisplayTimeFrame(frequency: Frequency) {
  // @ts-ignore
  const convertedFrequency: Frequency = Frequency[frequency];
  if (convertedFrequency === Frequency.DAILY) {
    return "Once";
  }
  if (convertedFrequency === Frequency.WEEKLY) {
    return "Weekly";
  }
  if (convertedFrequency === Frequency.MONTHLY) {
    return "Monthly";
  }
  if (convertedFrequency === Frequency.YEARLY) {
    return "Yearly";
  }

  return "Unlimited";
}

//TODO: Make this look better on homepage
export const expiration_message = (duration: moment.Duration) => {
  if (duration.get("years") > 0) {
    return "Unlimited Access";
  }
  if (duration.get("weeks") > 0) {
    return `Time Left ${duration.get("weeks")}W ${duration.get("days")}D`;
  }
  if (duration.get("days") > 0) {
    return `Time Left ${duration.get("days")}D ${duration.get("hours")}H`;
  }
  return `Time Left ${duration.get("hours")} hr ${duration.get("minutes")} min`;
};

export const access_start_wait_time = (duration: moment.Duration) => {
  if (duration.get("years") > 0) {
    return "never";
  }
  if (duration.get("weeks") > 0) {
    return `${duration.get("weeks")} weeks ${duration.get("days")} days`;
  }
  if (duration.get("days") > 0) {
    return `${duration.get("days")} days ${duration.get("hours")} hours`;
  }
  return `${duration.get("hours")} hours ${duration.get("minutes")} minutes`;
};

export function sortProductsAlphabetically(products: Product[]) {
  return products.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}

export function sortProductsFeatured(products: Product[]) {
  return products.sort((a, b) => {
    const aFeatured = a.featuredMetadata ? a.featuredMetadata.featured : null;
    const bFeatured = b.featuredMetadata ? b.featuredMetadata.featured : null;
    if (aFeatured && !bFeatured) {
      return -1;
    }
    if (bFeatured && !aFeatured) {
      return 1;
    }
    return 0;
  });
}

export function sortProductsByDateAdded(products: Product[]) {
  return products.sort((a, b) => {
    if (a.created < b.created) {
      return 1;
    }
    if (a.created > b.created) {
      return -1;
    }
    return 0;
  });
}

export function updatePendingPostAccessProductSurveyState(
  prevState: ProductSurvey | null,
  targetQuestion: string,
  opt: string
) {
  if (!prevState) {
    return null;
  }
  return {
    ...prevState,
    survey: {
      ...prevState.survey,
      components: prevState.survey.components.map((a) =>
        a.question === targetQuestion
          ? {
              ...a,

              selectedOption: opt,
            }
          : { ...a }
      ),
    },
  };
}
