import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import Carousel from "nuka-carousel";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";

import { Header } from "../components/Header";
import { SignUpInputField } from "../components/SignUpInputField";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
} from "../scripts/analytics";

import "../styles/OnboardingView.scss";

interface MenuLink {
  text: string;
  href?: string;
  children?: MenuLink[];
  onClick?: any;
}

function renderMenuLinks(menuLinks: MenuLink[]): JSX.Element {
  if (menuLinks.length) {
    return (
      <ul>
        {menuLinks.map((link) => (
          <li key={link.text}>{renderSingleMenuLink(link)}</li>
        ))}
      </ul>
    );
  }

  return <></>;
}

function renderSingleMenuLink(link: MenuLink): JSX.Element {
  let linkNode;
  if ("href" in link && link.href) {
    const externalOrDifferentProtocol =
      link.href.startsWith("http:") ||
      link.href.startsWith("https:") ||
      link.href.startsWith("mailto:");
    if (externalOrDifferentProtocol) {
      linkNode = (
        <a
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={link.onClick ? link.onClick : () => {}}>
          {link.text}
        </a>
      );
    } else {
      linkNode = (
        <Link
          to={link.href}
          onClick={(ev) => {
            if (link.onClick) {
              link.onClick();
            }

            const linkNode: HTMLAnchorElement = ev.target as HTMLAnchorElement;
            if (linkNode.hash.length > 0) {
              setTimeout(() => {
                const id: string = linkNode.hash.replace("#", "");
                const targetElNode = document.getElementById(id);
                if (targetElNode) {
                  targetElNode.scrollIntoView();
                }
              }, 0);
            } else {
              window.scrollTo(0, 0);
            }
          }}>
          {link.text}
        </Link>
      );
    }
  } else {
    linkNode = <span>{link.text}</span>;
  }

  return (
    <>
      {linkNode}
      {link.children && link.children.length && renderMenuLinks(link.children)}
    </>
  );
}

const useQuery = () => new URLSearchParams(useLocation().search);

const FOOTER_MENU_LINKS: MenuLink[] = [
  {
    text: "Company",
    children: [
      {
        text: "About Keye",
        href: "/about",
      },
      {
        text: "FAQ’s",
        href: "https://www.keye.co/how-it-works",
      },
      {
        text: "Terms & Conditions",
        href: "/terms",
      },
      {
        text: "Privacy Policy",
        href: "/privacy",
      },
    ],
  },
  {
    text: "Connect",
    children: [
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/company/keye/",
      },
      {
        text: "Instagram",
        href: "https://www.instagram.com/keye.co/",
      },
      {
        text: "Email",
        href: "mailto:contact@keye.co",
      },
    ],
  },
];

export const OnboardingView = () => {
  const history = useHistory();
  const query = useQuery();
  const ref = useRef(null);
  const refCarousel1 = useRef() as MutableRefObject<HTMLDivElement>;

  {
    let utm: any;
    const [utmSource, setUtmSource] = useState<String>("");

    for (var pair of query.entries()) {
      if (pair[0] === "utm_source") {
        utm = pair[1];
      }
    }

    useEffect(() => {
      setUtmSource(utm);
      sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_ONBOARDING_PAGE, {});
    }, [utm]);
  }

  function onClickAddExtn() {
    // Log analytics
    sendAnalyticsData(ANALYTICS_EVENT.EXTENSION_DOWNLOAD, {});

    // Redirect to homepage
    history.push("/home");
  }

  return (
    <>
      <Header />

      <div className="section-1">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <strong>
              <img src="/images/k.svg" alt="" /> ★★★★★ | BETA
            </strong>

            <p>Finish setting up your account:</p>

            <h1>Add the Keye Chrome extension</h1>

            <ul>
              <li>Log into your favorite sites in just 1 click</li>
              <li>Get access to our full offering of content providers</li>
              <li>
                Get real-time notifications where Keye access is available
              </li>
            </ul>

            <Button
              onClick={() => {
                onClickAddExtn();
                window.open(
                  "https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph",
                  "_blank"
                );
              }}>
              Add to Chrome — it’s free!
            </Button>

            <span className="guarantee">
              Keye guarantee: the extension does not collect or use your data
            </span>

            <form>
              <fieldset>
                <legend>Not recommended</legend>
                <Link
                  to="/home"
                  onClick={() => {
                    sendAnalyticsData(
                      ANALYTICS_EVENT.ON_CLICK_ONBOARDING_PAGE_SKIP,
                      {}
                    );
                  }}>
                  Skip and continue to Keye
                </Link>
              </fieldset>
            </form>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <ReactPlayer
              url="/videos/CE-02.mp4"
              playing={true}
              volume={0}
              width={"100%"}
              height={"auto"}
              loop={true}
            />
          </Col>
        </Row>
      </div>

      <div className={`section-1-5`}>
        {!isMobile && (
          <Row
            className="partners no-gutters"
            style={{ flexDirection: isMobile ? "column" : "row" }}>
            <Col>
              <img src="/images/partner_logo/crunchbase_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/information_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/masterclass_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/perlego_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/techcrunch_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/grammarly_landing.png" alt="" />
            </Col>
          </Row>
        )}
      </div>

      <div className="section-2">
        <Row>
          <Col xs={12} md={6}>
            <ReactPlayer
              url="/videos/Pinning video.mp4"
              playing={true}
              volume={0}
              width={"100%"}
              height={"auto"}
              loop={true}
            />
          </Col>
          <Col xs={12} md={6}>
            <h1>Save over $250/month when you browse with Keye</h1>
            <p>
              The Keye extension unlocks premium content as you use the internet
              like normal. Just pin the extension to your browser bar, and we’ll
              help save you time and money!
            </p>
            <a
              href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph"
              target="_blank"
              onClick={() => {
                onClickAddExtn();
              }}
              rel="noreferrer">
              Experience Seamless Browsing
            </a>
          </Col>
        </Row>
      </div>

      <Row className="section-3 separated no-gutters">
        <Col
          xs={0}
          sm={12}
          md={7}
          style={{
            background:
              "#111 url(images/onboarding-slide-image-1.jpg) no-repeat 50% 50%",
            backgroundSize: "cover",
          }}
          ref={ref}></Col>
        <Col xs={12} sm={12} md={5}>
          <Carousel
            speed={250}
            defaultControlsConfig={{
              prevButtonText: " ",
              prevButtonStyle: {
                width: "2.5rem",
                height: "2.5rem",
                background: `transparent url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' transform='rotate(-180 22 22)' fill='%23D9D9D9'/%3E%3Cpath d='M26.625 12.4C26.875 12.65 27 12.946 27 13.288C27 13.63 26.875 13.9257 26.625 14.175L19.3 21.5L26.65 28.85C26.8833 29.0833 27 29.375 27 29.725C27 30.075 26.875 30.375 26.625 30.625C26.375 30.875 26.079 31 25.737 31C25.395 31 25.0993 30.875 24.85 30.625L16.45 22.2C16.35 22.1 16.279 21.9917 16.237 21.875C16.195 21.7583 16.1743 21.6333 16.175 21.5C16.175 21.3667 16.196 21.2417 16.238 21.125C16.28 21.0083 16.3507 20.9 16.45 20.8L24.875 12.375C25.1083 12.1417 25.396 12.025 25.738 12.025C26.08 12.025 26.3757 12.15 26.625 12.4Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat`,
                backgroundSize: "cover",
              },
              nextButtonText: " ",
              nextButtonStyle: {
                width: "2.5rem",
                height: "2.5rem",
                background: `transparent url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M17.375 31.6C17.125 31.35 17 31.054 17 30.712C17 30.37 17.125 30.0743 17.375 29.825L24.7 22.5L17.35 15.15C17.1167 14.9167 17 14.625 17 14.275C17 13.925 17.125 13.625 17.375 13.375C17.625 13.125 17.921 13 18.263 13C18.605 13 18.9007 13.125 19.15 13.375L27.55 21.8C27.65 21.9 27.721 22.0083 27.763 22.125C27.805 22.2417 27.8257 22.3667 27.825 22.5C27.825 22.6333 27.804 22.7583 27.762 22.875C27.72 22.9917 27.6493 23.1 27.55 23.2L19.125 31.625C18.8917 31.8583 18.604 31.975 18.262 31.975C17.92 31.975 17.6243 31.85 17.375 31.6Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat`,
                backgroundSize: "cover",
              },
            }}
            afterSlide={(slideIndex) => {
              if (ref.current) {
                (
                  ref.current as HTMLElement
                ).style.backgroundImage = `url(images/onboarding-slide-image-${
                  slideIndex + 1
                }.jpg)`;
              }
            }}
            innerRef={refCarousel1}>
            <div
              style={{
                padding: "200px 100px",
              }}>
              <h2>What happens next?</h2>
              <p>
                After adding the extension, you’ll be able to browse our full
                list of content providers and start using them right away.
              </p>
              <Link
                to="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  (
                    (
                      refCarousel1.current.parentNode as HTMLElement
                    ).getElementsByTagName("button")[1] as HTMLElement
                  ).click();
                }}>
                What can I do on Keye?
              </Link>
            </div>
            <div
              style={{
                padding: "200px 100px",
              }}>
              <h2>What can I do on Keye?</h2>
              <p>
                Whether you’re here to get the latest news, learn from the best
                books and podcasts, or access premium data and tools for work,
                Keye has something for you.
              </p>
              <Link
                to="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  (
                    (
                      refCarousel1.current.parentNode as HTMLElement
                    ).getElementsByTagName("button")[1] as HTMLElement
                  ).click();
                }}>
                How do I get access?
              </Link>
            </div>
            <div
              style={{
                padding: "200px 100px",
              }}>
              <h2>Using Keye is easy</h2>
              <p>
                After adding and pinning the extension to your browser bar,
                you’ll be taken to our home screen, where you simply click on
                the product you want and hit “access” to log in immediately.
              </p>
              <a
                href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph"
                target="_blank"
                onClick={onClickAddExtn}
                rel="noreferrer">
                Sounds great, take me there!
              </a>
            </div>
          </Carousel>
        </Col>
      </Row>

      <Row className="footer no-gutters">
        <Col xs={12} md={6}>
          {FOOTER_MENU_LINKS.length && renderMenuLinks(FOOTER_MENU_LINKS)}
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={5}>
          <h2>Experience Keye’s full potential!</h2>
          <Button
            onClick={() => {
              onClickAddExtn();
              window.open(
                "https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph",
                "_blank"
              );
            }}>
            Get the free extension
          </Button>
        </Col>
      </Row>
    </>
  );
};
