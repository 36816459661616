import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { ProductContext } from "../../context/ProductContext";
import {
  CompletedProductSurvey,
  Product,
  ProductAccount,
  ProductCreditCost,
  ProductSurvey,
} from "../../models/Product";
import {
  getDisplayTimeFrame,
  getKeyeAccessDescription,
  getRecurringDescription,
} from "../../scripts/util";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  productDetailsBundle,
  sendAnalyticsData,
  VISIT_TIMEFRAME,
} from "../../scripts/analytics";

import { Button, Col, Row } from "react-bootstrap";
import {
  getAccessToken,
  getAccessTokenAPI,
  getActiveProductSurveys,
  getCompletedProductSurveys,
  getCreditsForUser,
  getCurrentAccessesForUser,
  getNextAvailableTime,
  getProductsForUser,
  getWaitlistDetailsForUserForManyProducts,
  hasUserRequestedMoreCredits,
  requestCreditsForUser,
  startAccessForUser,
} from "../../scripts/createApiInstance";
import { ProductCheckoutFlow } from "./ProductCheckoutFlow";
import { UserCreditContext } from "../../context/UserCreditContext";

import "../../styles/ProductAccountShoppingPage.scss";

export function ProductAccountShoppingPage({
  product,
  productCreditCost,
  productId,
  setProductAccount,
  refresh,
  setRefresh,
}: {
  product: Product;
  productCreditCost: ProductCreditCost | undefined;
  productId: string;
  setProductAccount: (productAccount: ProductAccount | undefined) => void;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}) {
  const { user, apiInstance, creditSystemEnabled } = useContext(AuthContext);
  const {
    currentProducts,
    setCurrentProducts,
    setAllProductWaitlistDetails,
    setCurrentProductAccesses,
  } = useContext(ProductContext);
  const history = useHistory();

  const { setUserCreditBalance, userCreditBalance } =
    useContext(UserCreditContext);
  const [access, setAccess] = useState(true);
  const [showCheckoutFlow, setShowCheckoutFlow] = useState(false);
  const [userCreditRequestPending, setUserCreditRequestPending] =
    useState(false);
  const [productSurveys, setProductSurveys] = useState<ProductSurvey[]>([]);
  const [productSurveyLoader, setProductSurveyLoader] =
    useState<boolean>(false);
  const [completedSurveys, setCompletedSurveys] = useState<
    CompletedProductSurvey[]
  >([]);

  const [nextAvailableTime, setNextAvailableTime] = useState<string>("");
  const [nextAvailableTimeLoader, setNextAvailableTimeLoader] =
    useState<boolean>(false);

  const [completedSurveyLoader, setCompletedSurveyLoader] =
    useState<boolean>(false);

  useEffect(() => {
    function getActiveProductSurveysOnLoad() {
      setProductSurveyLoader(true);
      getActiveProductSurveys(apiInstance, productId)
        .then(function (resp) {
          setProductSurveys(resp.data);
        })
        .catch(function (err) {
          console.log("This is error", err);
        })
        .finally(function () {
          setProductSurveyLoader(false);
        });
    }
    getActiveProductSurveysOnLoad();
  }, [apiInstance, productId]);

  useEffect(() => {
    function getCompletedProductSurveysOnLoad() {
      setCompletedSurveyLoader(true);
      getCompletedProductSurveys(apiInstance, productId)
        .then(function (resp) {
          setCompletedSurveys(resp.data);
        })
        .catch(function (err) {
          console.log("This is error", err);
        })
        .finally(function () {
          setCompletedSurveyLoader(false);
        });
    }
    getCompletedProductSurveysOnLoad();
  }, [apiInstance, productId]);

  useEffect(() => {
    async function checkIfUserHasRequestedCredits() {
      try {
        const hasRequestedCredits = await hasUserRequestedMoreCredits(
          apiInstance
        );
        if (hasRequestedCredits) {
          setUserCreditRequestPending(true);
        } else {
          setUserCreditRequestPending(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (creditSystemEnabled) {
      checkIfUserHasRequestedCredits();
    }
  }, [apiInstance, creditSystemEnabled]);

  useEffect(() => {
    async function getNextAvailableTimeOnLoad() {
      try {
        setNextAvailableTimeLoader(true);
        const nextAvailableTime = await getNextAvailableTime(
          apiInstance,
          productId
        );
        setNextAvailableTime(nextAvailableTime.data);
      } catch (error) {
        console.log("Error getting next available time", error);
      } finally {
        setNextAvailableTimeLoader(false);
      }
    }
    getNextAvailableTimeOnLoad();
  }, [apiInstance, productId, refresh]);
  const handleBackClick = () => setShowCheckoutFlow(false);

  const GreenIcon = <div id="green-circle-access-icon"></div>;

  const getAuthAccess = async () => {
    const aceessInfo = await getAccessToken(apiInstance, productId);
    if (aceessInfo.data) {
      window.location.href = `/partnersite?auth=${aceessInfo.data.authToken}`;
    }
  };

  const sendVisitProductWebsiteEvent = () =>
    sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_GO_TO_PRODUCT_SITE, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, "ACCESSIBLE"),
      price: product.retailPrice,
      url: product.url,
      timeframe: VISIT_TIMEFRAME.PREACCESS,
    });

  const sendAccessRequest = () =>
    sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_ACCESS_REQUEST, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, "ACCESSIBLE"),
      price: product.price,
    });

  let canAfford = true;
  if (productCreditCost && creditSystemEnabled) {
    canAfford = productCreditCost.creditCost <= userCreditBalance;
  }

  async function handleRequestCredits() {
    const response = await requestCreditsForUser(apiInstance);
    if (response.status === 204) {
      setUserCreditRequestPending(true);
    } else {
      console.log("Error requesting credits");
    }
  }

  const purchaseButtonOptions = (
    <div>
      {access && canAfford ? (
        <>
          <Button
            variant="success"
            id="access-button"
            className="btn btn-primary"
            style={{ background: "green" }}
            onClick={() => {
              sendAccessRequest();
              setShowCheckoutFlow(true);
            }}>
            Get {product.name} now
          </Button>
          {product.oauth && (
            <Button
              variant="success"
              id="access-button"
              onClick={() => {
                getAuthAccess();
              }}>
              Login With OAuth
            </Button>
          )}
        </>
      ) : access && !canAfford && !userCreditRequestPending ? (
        <Button
          variant="warning"
          id="access-button"
          onClick={handleRequestCredits}>
          Not Enough Credits - Request More
        </Button>
      ) : access && !canAfford && userCreditRequestPending ? (
        <Button disabled variant="warning" id="access-button">
          Not Enough Credits - Request Pending
        </Button>
      ) : (
        <button
          className="btn btn-primary"
          id="access-button"
          onClick={() => {
            sendVisitProductWebsiteEvent();
            window.open(product.url, "_blank");
          }}>
          Visit {product.name}
        </button>
      )}
    </div>
  );

  // adding a user to waitlist just means starting access to the product with a false boolean
  async function startWaitlistAccess() {
    try {
      sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_ACCEPT_TERMS, {
        ...eventBundleProperties(user, EVENT_TYPE.UA),
      });
      const productAccount = await startAccessForUser(
        apiInstance,
        productId,
        false
      );

      sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_CONFIRM_ACCESS, {
        ...eventBundleProperties(user, EVENT_TYPE.UA),
        ...productDetailsBundle(product!, "ACCESSIBLE"),
      });

      // we need to update the product context with the waitlist details and new current products
      // we don't need to update the userCreditContext because adding a product to waitlist doesn't cost credits (they will get deducted when the actual access is granted)
      const newProducts = await getProductsForUser(apiInstance);
      setCurrentProducts(newProducts.data);
      const allProductIds = currentProducts.map((product) => product.id);
      const allWaitlistDetails = await getWaitlistDetailsForUserForManyProducts(
        apiInstance,
        allProductIds
      );
      setAllProductWaitlistDetails(allWaitlistDetails.data);
      // update the product account in the ProductView which will trigger a re-render
      // this means that ProductView will now see that there is a ProductAccount active, and will return the WaitlistPage
      if (productAccount.data) {
        setProductAccount(productAccount.data);
      } else {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log("Error starting waitlist access", error);
    } finally {
      setShowCheckoutFlow(false);
    }
  }

  async function startImmediateAccess() {
    try {
      sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_ACCEPT_TERMS, {
        ...eventBundleProperties(user, EVENT_TYPE.UA),
      });
      const productAccount = await startAccessForUser(
        apiInstance,
        productId,
        true
      );

      sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_CONFIRM_ACCESS, {
        ...eventBundleProperties(user, EVENT_TYPE.UA),
        ...productDetailsBundle(product!, "ACCESSIBLE"),
      });

      if (creditSystemEnabled) {
        // we need to update the product context with the new balance and new current products
        const newBalance = await getCreditsForUser(apiInstance);
        setUserCreditBalance(newBalance.data);
      }
      const newProducts = await getProductsForUser(apiInstance);
      setCurrentProducts(newProducts.data);
      const currentProductAccesses = await getCurrentAccessesForUser(
        apiInstance
      );
      setCurrentProductAccesses(currentProductAccesses.data);
      // update the product account in the ProductView which will trigger a re-render
      // this means that ProductView will now see that there is a ProductAccount active, and will return the AccessPage
      if (productAccount.data) {
        setProductAccount(productAccount.data);
      } else {
        setRefresh(!refresh);
      }
    } catch (error) {
      switch ((error as any).response.data.errorCode) {
        case "NO_ACTIVE_SUBSCRIPTION":
          /// Redirect to payment page if the user currently has no active subscription
          history.push("/subscribe");
          break;
        case "PRODUCT_UNAVAILABLE_IN_TRIAL_PERIOD":
          /// Redirect to payment page and mention this product if the user currently has no active subscription
          history.push(`/subscribe?productId=${productId}`);
          break;
      }
      console.log("Error starting immediate access", error);
    } finally {
      setShowCheckoutFlow(false);
    }
  }

  return (
    <>
      {showCheckoutFlow ? (
        <ProductCheckoutFlow
          product={product}
          handleClose={handleBackClick}
          handleStartImmediateAccess={startImmediateAccess}
          handleStartWaitlistAccess={startWaitlistAccess}
          productSurveys={productSurveys}
          completedSurveys={completedSurveys}
          nextAvailabletime={nextAvailableTime}
          productCreditCost={productCreditCost}
        />
      ) : (
        <>
          <Row className="p-3">
            <Col>{purchaseButtonOptions}</Col>
          </Row>
        </>
      )}
    </>
  );
}
