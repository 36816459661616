import { Product, ProductCreditCost } from "../../models/Product";
import {
  expiration_message,
  getBackgroundColor,
  getTextColor,
  toTitleCase,
} from "../../scripts/util";
import moment from "moment/moment";
import { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import { LoginCredentials } from "../ProductPage/LoginCredentials";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  productDetailsBundle,
  sendAnalyticsData,
  VISIT_TIMEFRAME,
} from "../../scripts/analytics";
import { useHistory } from "react-router-dom";
import { ProductContext } from "../../context/ProductContext";
import { isMobile } from "react-device-detect";

import "../../styles/ProductCardStyles/AccessProductCard.scss";
import "../../styles/ProductCardStyles/ProductCards.scss";

export function AccessProductCard({
  product,
  setSelectedProduct,
  selectedProduct,
  creditCost,
}: {
  product: Product;
  setSelectedProduct: (product: Product | undefined) => void;
  selectedProduct: Product | undefined;
  creditCost: ProductCreditCost | undefined;
}): JSX.Element {
  const { user } = useContext(AuthContext);
  const { currentProductAccesses } = useContext(ProductContext);

  const productAccountAccess = currentProductAccesses.find(
    (productAccount) => productAccount.product.id === product.id
  );

  const history = useHistory();

  const expiration = moment
    .utc(productAccountAccess?.productAccount.expiration)
    .local();
  const duration = moment.duration(expiration.diff(moment.now()));

  function sendVisitProductWebsiteEvent() {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_GO_TO_PRODUCT_SITE, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, "ACCESSIBLE"),
      price: product.retailPrice,
      url: product.url,
      timeframe: VISIT_TIMEFRAME.HOMEPAGE,
    });
  }

  return (
    <Card
      id="product-card"
      className={`${
        selectedProduct === product ? "selected-product-card" : ""
      }`}>
      <Card.Img
        variant="top"
        src={product.imgUrl}
        alt={product.name}
        id="product-card-image"
        onClick={() => {
          if (selectedProduct === product) {
            setSelectedProduct(undefined);
          } else {
            setSelectedProduct(product);
          }
        }}
      />

      <Card.Body className="d-flex flex-column" id="access-product-card-body">
        <div
          onClick={() => {
            if (selectedProduct === product) {
              setSelectedProduct(undefined);
            } else {
              setSelectedProduct(product);
            }
          }}>
          <div id="access-product-card-top-row">
            <Card.Text as="h5" id="access-product-card-name">
              {product.name}
            </Card.Text>
            <Card.Text as="h5" id="access-product-flag">
              CURRENTLY ACTIVE
            </Card.Text>
          </div>

          <div id="access-product-card-bottom-row">
            <Card.Text>
              <Button
                disabled
                id="decorative-category-pills"
                style={{
                  backgroundColor: getBackgroundColor(String(product.category)),
                  color: getTextColor(String(product.category)),
                }}>
                {toTitleCase(String(product.category))}
              </Button>
            </Card.Text>
          </div>
        </div>
        {selectedProduct === product && (
          <>
            <Card.Text>
              <p id="access-expiration">{expiration_message(duration)}</p>
            </Card.Text>

            {productAccountAccess?.productAccount != undefined &&
              (productAccountAccess?.productAccount.credentialWhitelisted ||
                isMobile) && (
                <LoginCredentials
                  productAccount={productAccountAccess.productAccount}
                />
              )}

            <Card.Body className="d-flex flex-row justify-content-between ">
              <Button
                variant="success"
                id="product-card-action-button"
                style={{ background: "green" }}
                onClick={() => {
                  history.push(`/product/${product.id}`);
                  sendVisitProductWebsiteEvent();
                }}>
                Product Info
              </Button>

              <Button
                variant="info"
                id="product-card-action-button"
                style={{ background: "blue" }}
                onClick={() => {
                  sendVisitProductWebsiteEvent();
                  if (
                    productAccountAccess?.productAccount.credentialWhitelisted
                  ) {
                    window.open(product.url, "_blank");
                  } else {
                    var data = {
                      productId: product.id,
                      eventType: "login",
                      product: product.name,
                      url: product.url,
                      currentAccess: {
                        productAccount: {
                          username:
                            productAccountAccess?.productAccount.username,
                          password:
                            productAccountAccess?.productAccount.password,
                          expiration:
                            productAccountAccess?.productAccount.expiration,
                        },
                      },
                    };
                    console.log("sending message : ", data);
                    window.postMessage(data, "*");
                  }
                }}>
                To {product.name}.com
              </Button>
            </Card.Body>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
