/*
export const accordionInfo = [
  {
    title: "1. What is Keye?",
    content: `<p>
      Keye lets you try out premium software products for a fraction of
      the subscription price.
    </p>
    <p>
      When you activate a Keye access, you can use a subscription product
      without creating an account or worrying about recurring charges.
    </p>`,
    imagePosition: "right",
    image: "/images/What_is_Keye.png",
    video: "",
    imageAlt: "What is Keye?",
    multipleImages: false,
  },
  {
    title: "2. How does Keye work?",
    content: `<h5 class="sub-section-header">Product Access Time</h5>
     <p>With Keye, you can access digital content and tools right away for 24 hours at a time.
     Use your Keye credits to activate the products you want at any time. 
     Make sure you <a href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph" target="_blank">install our Chrome extension</a>,
     log into the Chrome extension with your Keye account, and pin the extension for the best experience. 
    </p>`,
    imagePosition: "right",
    image: "",
    video: "/videos/help-chrome-extension.mp4",
    imageAlt: "How Keye Works?",
    multipleImages: false,
  },
  {
    title: "3. Why do I need the Keye Chrome extension?",
    content: ` <p>
  
    <p>The Chrome Extension experience is essential for seamless access to various services. <p>
    <ol>
    <li>Keye Extension simplifies the login process, allowing you to access content with just one click, without the need to sign up or provide any unnecessary information.</li>
    <li>Keye Extension is a mini version of the website that lets you access services directly from its interface, without even visiting our website.</li>
    <li>Additionally, our Extension will recommend similar services every time you encounter a paywall, helping you discover the right content on Keye and get the most value for your money.</li>
    </ol>
    <p>And the best part? It takes just a few seconds to add and pin the extension to your toolbar – faster than it takes to blink (depending on your blink speed, of course!)".  <a href="https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph" target="_blank"> Click here to get started.</a>.
   <p>`,
    imagePosition: "left",
    image: "",
    video: "/videos/chrome-Extension-Use-Video.mp4",
    imageAlt: "Why do I need the Keye Chrome extension",
    multipleImages: false,
  },
  {
    title: "4.How do I access a product on Keye?",
    content: ` 
        <p>
        <p>Select the product you are interested in to learn more about it and view the number of credits you need for a 24 hour access. After you click "Get Product now," you will be able to review the terms and conditions, and then click “Activate Access” to start using the product right away. Our amazing extension will automate your entire workflow and within seconds, you will be logged into the product automatically.
        Credits will also be automatically deducted from your wallet, and if you have the Chrome extension installed, you should be automatically logged into the product you requested. 
        </p>
        <p><b>** NOTE: </b>  Please do not save anything on your credential manager apps as we update credentials regularly and it will lead to an inferior experience for you, which we obviously do not want! 
          </p>
       </p>`,
    imagePosition: "left",
    image: "/images/buying-an-access-latest.png",
    video: "",
    imageAlt: "Buying an access",
    multipleImages: false,
  },
  {
    title: "5. How much does Keye cost?",
    content: ` <h5 class="sub-section-header">
      Keye is free to use during our beta!
    </h5>
    <p>
      To help us keep Keye free, we will periodically send you surveys
      that will help us improve our product.
    </p>
    <p>
      Please respond to the surveys to help us continue providing you
      access to premium software subscriptions!
    </p>
    <p>
      Questions? Reach out to us at <strong>contact@keye.co</strong>
    </p>`,
    imagePosition: "right",
    image: "/images/keye-cost-1.png",
    video: "",
    imageAlt: "How much does Keye cost?",
    multipleImages: false,
  },
];
*/

export const accordionCreditInfo = [
  {
    title: "1. What do I get with my Keye membership?",
    content: `<p>
      Instant access to the best content on the internet, including over 20 productivity tools, 500 streaming classes, and 1000+ paywalled articles.
    </p>`,
    imagePosition: "right",
    image: "/images/What_is_Keye.png",
    video: "",
    imageAlt: "What is Keye?",
    multipleImages: false,
  },
  {
    title: "2. Sounds great, but why is Keye so affordable?",
    content: `<p>Keye uses a credit system to make sure you can use all the services you want. When you use your Keye credits to get access to a site, you’ll be able to use it for 24 hours. If you want to keep using it after that, you can simply spend more credits to extend your access.</p>`,
    imagePosition: "right",
    image: "/images/tab-view.png",
    video: "",
    imageAlt: "How Keye Works?",
    multipleImages: false,
  },
  {
    title: "3. Are there any limits to how much content I can access?",
    content: `<p>As often as you want, until you run out of credits.<p>`,
    imagePosition: "right",
    image: "",
    video: "",
    imageAlt: "Why do I need the Keye Chrome extension",
    multipleImages: false,
  },
  {
    title: "4. What’s the best way to experience Keye?",
    content: ` 
        <p>
        Today, we recommend using Keye with the Google Chrome browser on your desktop or laptop. We are coming to other devices soon!
       </p>`,
    imagePosition: "right",
    image: "",
    video: "",
    imageAlt: "",
    multipleImages: false,
  },
  {
    title: "5. How much does Keye cost?",
    content: `
      <p>For a limited time, Keye is free for 7 days and $9.99/month recurring after that. No credit card is required when signing up for the free trial. You can cancel at any time.
      </p>`,
    imagePosition: "right",
    image: "",
    video: "",
    imageAlt: "",
    multipleImages: false,
  },
];
