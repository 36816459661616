import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
} from "../scripts/analytics";

import "../styles/SignUpInputField.scss";

export function SignUpInputField(): JSX.Element {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");

  return (
    <form
      className="signup-input-field"
      onSubmit={(ev) => {
        ev.preventDefault();

        sendAnalyticsData(
          ANALYTICS_EVENT.ON_CLICK_MAIN_PAGE_GET_KEYE_FREE,
          eventBundleProperties(null, EVENT_TYPE.UI)
        );

        history.push("/register", { email });
      }}>
      <Button type="submit">Get Keye free</Button>
    </form>
  );
}
