import React, { MutableRefObject, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";

import "../styles/PaymentCompleteView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

export const PaymentCompleteView = () => {
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");
  const [ok, setOk] = useState<boolean | null>(null);

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_PAYMENT_COMPLETE_PAGE, {});
  }, [utm]);

  return (
    <>
      <p>
        Thank you for your payment.
        <br />
        <Link to="/home">
          <Button>Continue to Keye</Button>
        </Link>
      </p>{" "}
    </>
  );
};
