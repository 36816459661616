import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Product, ProductScreenshot } from "../../models/Product";

export const ScreenshotThumbnail = ({
  idx,
  imgUrl,
}: {
  idx: number;
  imgUrl: string;
}) => {
  return <img key={idx} src={imgUrl} alt={imgUrl} id="screenshot-thumbnail" />;
};

export const DisplayImage = ({
  imgUrl,
  altName,
}: {
  imgUrl: string;
  altName: string;
}) => {
  return <img src={imgUrl} alt={altName} id="screenshot-display" />;
};
export function ProductImages({
  product,
  screenshots,
}: {
  product: Product;
  screenshots: ProductScreenshot[];
}): JSX.Element {
  const [activeImage, setActiveImage] = useState(0);

  function newImage(idx: React.SetStateAction<number>) {
    setActiveImage(idx);
    disableProductImage();
  }

  const [productImageActive, setProductImageActive] = useState(true);

  const activeProductImage = () => setProductImageActive(true);
  const disableProductImage = () => setProductImageActive(false);

  const ThumbnailDivStyle = {
    marginBottom: "10px",
  };

  // Add 1 to index since the first thumbnail is the primary screenshot
  const screenshotImages = screenshots.map((x, idx) => (
    <div style={ThumbnailDivStyle} onClick={() => newImage(idx)}>
      <ScreenshotThumbnail idx={idx + 1} imgUrl={x.imgUrl} />
    </div>
  ));
  return (
    <>
      <Col md={2}>
        <div style={ThumbnailDivStyle} onClick={activeProductImage}>
          <ScreenshotThumbnail idx={0} imgUrl={product.imgUrl} />
        </div>
        {screenshotImages}
      </Col>
      <Col md={10}>
        {productImageActive ? (
          <DisplayImage imgUrl={product.imgUrl} altName={product.name} />
        ) : (
          <DisplayImage
            imgUrl={screenshots[activeImage].imgUrl}
            altName={screenshots[activeImage].imgUrl}
          />
        )}
      </Col>
    </>
  );
}
