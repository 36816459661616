import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "typeface-roboto";
import { initAnalytics } from "./scripts/analytics";
import { initFlagsmith } from "./scripts/flagsmith";
import { initFullstory } from "./scripts/fullstory";
import ProductProvider from "./context/ProductContext";

initAnalytics();
initFlagsmith();
initFullstory("150HZQ");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <AuthProvider>
          <ProductProvider>
            <App />
          </ProductProvider>
        </AuthProvider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
