import React, { MutableRefObject, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import Carousel from "nuka-carousel";
import { Accordion } from "./HelpView";
import { accordionCreditInfo } from "./HelpViewInfo";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";
import { isMobile } from "react-device-detect";
import { Header } from "../components/Header";
import { SignUpInputField } from "../components/SignUpInputField";
import { VerticallyScrollingText } from "../components/VerticallyScrollingText";

import "../styles/LandingForProfessionalsView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

function onClickSignUp() {
  sendAnalyticsData(
    ANALYTICS_EVENT.ON_CLICK_LANDING_PAGE_FULLTIME_EMPLOYED_SIGNUP,
    {}
  );
}

export const LandingForProfessionalsView = () => {
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  const [utmSource, setUtmSource] = useState<String>("");

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    }
  }

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(
      ANALYTICS_EVENT.ON_LOAD_LANDING_PAGE_FULLTIME_EMPLOYED,
      {}
    );
  }, [utm]);

  return (
    <>
      <Navbar>
        <Navbar.Brand>
          <a href="https://www.unlockkeye.com/">
            <img
              src="/images/keye-logo-white.png"
              alt="Small blue Keye icon"
              id="keye-logo-nav"
              style={{
                width: "150px",
                marginBottom: isMobile ? "0" : "60px",
              }}
            />
          </a>
        </Navbar.Brand>
      </Navbar>

      <div
        className="section-1"
        style={{
          background: isMobile ? "#185ed1" : "_",
          padding: isMobile ? "3rem 2rem" : "4rem 2.5rem",
        }}>
        <h1
          style={{
            paddingRight: isMobile ? 0 : "40%",
          }}>
          Must-have market trends, <span>all in one place</span>
        </h1>
        <Row>
          <Col xs="12" sm="6">
            <p>
              Access all the news, data and tools you need. Read articles in one
              click, no subscription required.
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                onClickSignUp();
                history.push("/register");
              }}>
              Try it FREE
            </Button>
          </Col>
          <Col xs="12" sm="6">
            <img
              src="images/landing-must-have-market-trends.svg"
              style={{
                display: isMobile ? "none" : "",
              }}
              alt=""
            />
          </Col>
        </Row>
      </div>

      <div className={`section-2 ${isMobile ? "mobileHide" : ""}`}>
        <h1>
          Cutting-edge{" "}
          <span style={{ color: "#175FD3" }}>publications & tools</span>
        </h1>

        <p style={{ width: isMobile ? "auto" : "50%" }}>
          Get unlimited access to premium tools that help you achieve better
          results and stay ahead of the competition.
        </p>

        <img
          src="images/landing-cutting-edge-publications-and-tools.svg"
          className="graphic"
          alt=""
        />

        {!isMobile && (
          <Row
            className="partners no-gutters"
            style={{ flexDirection: isMobile ? "column" : "row" }}>
            <Col>
              <img src="/images/partner_logo/crunchbase_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/masterclass_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/transkriptor_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/picsart_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/information_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/blinkist_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/techcrunch_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/many_more_landing.png" alt="" />
            </Col>
          </Row>
        )}
      </div>

      <div
        className="section-3"
        style={{
          padding: isMobile
            ? "3.5rem 1.5rem 7.5rem 1.5rem"
            : "5rem 3.5rem 3.5rem 3.5rem",
        }}>
        <Row style={{ alignItems: "center" }}>
          <Col xs="12" sm="12" md="5">
            <h1>
              Stay Ahead with <span style={{ color: "#175FD3" }}>Keye</span>
            </h1>
            <h3>
              Develop your skills and stay at the cutting edge with our
              industry-leading collection of classes
            </h3>
          </Col>
          <Col xs="12" sm="12" md="7">
            <img src="images/landing-for-professionals-premium-content.png" />
          </Col>
        </Row>
      </div>

      <div className={isMobile ? "section-4 p-2" : "section-4 p-5"}>
        <h1>How It Works</h1>

        <div className="accordion">
          {accordionCreditInfo.map(
            ({
              title,
              content,
              image,
              video,
              imageAlt,
              imagePosition,
              multipleImages,
            }) => (
              <Accordion
                key={title}
                title={title}
                content={content}
                image={image}
                video={video}
                imageAlt={imageAlt}
                imagePosition={imagePosition}
                multipleImages={multipleImages}
              />
            )
          )}
        </div>
      </div>

      <div className="section-5">
        <h1>Work smarter, with Keye</h1>

        <Row
          className="footer-section"
          style={{
            padding: isMobile
              ? "1.5rem 1.5rem 7.5rem 1.5rem"
              : "7.5rem 7.5rem 2rem 7.5rem",
          }}>
          <Col
            xs="12"
            sm="12"
            md="6"
            style={{
              textAlign: isMobile ? "center" : "left",
            }}>
            <p>
              Sick of getting stuck in sign-up screens and corporate approvals?
              Breeze past paywalls with Keye!
            </p>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                window.scrollTo(0, 0);
                onClickSignUp();
                history.push("/register");
              }}>
              Sign up
            </Button>
          </Col>
          <Col
            md="6"
            style={{
              display: isMobile ? "none" : "block",
            }}>
            <img src="images/section-5.png" alt="" />
          </Col>
        </Row>
      </div>
    </>
  );
};
