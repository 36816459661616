import { useContext, useState } from "react";
import { Product } from "../models/Product";
import { FilterProductCategoryPills } from "../components/FilterProductCategoryPills";
import { CenterLoader } from "../components/Loader";
import { AccessProductCard } from "../components/ProductCard/AccessProductCard";
import { Col, Row } from "react-bootstrap";
import { ProductContext } from "../context/ProductContext";

export function ActiveAccessesView(): JSX.Element {
  const {
    currentProducts,
    currentProductsLoading,
    currentProductAccesses,
    currentProductAccessesLoading,
    allProductCreditCosts,
    allProductCreditCostsLoading,
  } = useContext(ProductContext);

  const [categoryFilter, setCategoryFilter] = useState<any>("");
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  // look at currentProducts and filter out products that are not in currentProductAccesses
  let filteredCurrentAccessProducts = currentProducts
    .filter(
      (product) =>
        currentProductAccesses.find(
          (access) => access.product.id === product.id
        )!
    )
    .filter(
      (product) =>
        product.category.toString() === categoryFilter || categoryFilter === ""
    );

  const loading =
    currentProductsLoading ||
    currentProductAccessesLoading ||
    allProductCreditCostsLoading;

  return (
    <div id="active-accesses-view">
      {loading ? (
        <CenterLoader />
      ) : (
        <>
          <Row className="justify-content-baseline align-items-start">
            <Col xs={12} md={10}>
              <FilterProductCategoryPills
                setCategoryFilter={setCategoryFilter}
              />
            </Col>
          </Row>

          <Row id="product-card-row" style={{ minHeight: "500px" }}>
            <div className="mt-3 d-flex">
              {filteredCurrentAccessProducts.length > 0
                ? ""
                : "No active products"}
            </div>
            {filteredCurrentAccessProducts.map((product) => (
              <Col xs={12} md={6} xl={4} key={product.id} className="mb-3">
                <AccessProductCard
                  key={product.id}
                  product={product}
                  setSelectedProduct={setSelectedProduct}
                  selectedProduct={selectedProduct}
                  creditCost={allProductCreditCosts.find((credit) => {
                    // eslint-disable-next-line eqeqeq
                    return credit.productId == product.id;
                  })}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
}
