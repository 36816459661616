import { Product } from "../../models/Product";
import {
  getBackgroundColor,
  getTextColor,
  toTitleCase,
} from "../../scripts/util";

export default function ProductHeader({
  product,
}: {
  product: Product;
}): JSX.Element {
  return (
    <>
      <h3 id="name-text">{product.name}</h3>
      <div>
        <button
          id="category-pills"
          disabled
          style={{
            backgroundColor: getBackgroundColor(String(product.category)),
            color: getTextColor(String(product.category)),
          }}>
          {toTitleCase(String(product.category))}
        </button>
      </div>
    </>
  );
}
