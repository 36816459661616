import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance } from "axios";
import { isMobile } from "react-device-detect";
import { useHistory, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactPlayer from "react-player";
import { Button, Modal } from "react-bootstrap";
import flagsmith from "flagsmith";

import { User } from "../models/User";
import {
  createApiInstance,
  getCurrentUser,
} from "../scripts/createApiInstance";
import { isEmpty } from "../scripts/util";
import {
  setAnalyticsUserId,
  sendEvent,
  sendAnalyticsData,
  ANALYTICS_EVENT,
} from "../scripts/analytics";
import { FLAGS, identifyUser } from "../scripts/flagsmith";

interface AuthContextType {
  jwt: string;
  apiInstance: AxiosInstance;
  loader: boolean;
  user: User | null;
  setUserJWT: (jwt: string, firstLogin: boolean) => void;
  creditSystemEnabled: boolean;
  disableCreditSystem: () => void;
}

export const AuthContext = React.createContext({
  jwt: "",
  loader: true,
  user: {},
  setUserJWT: (string, boolean) => {},
  creditSystemEnabled: false,
  disableCreditSystem: () => {},
} as AuthContextType);

// @ts-ignore
export default function AuthProvider({ children }) {
  const [cookies, setCookies] = useCookies(["jwt"]);
  const [creditSystemEnabled, setCreditSystemEnabled] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const jwtCookie = isEmpty(cookies.jwt) ? "" : cookies.jwt;
  const [jwt, setJWT] = useState<string>(jwtCookie);
  const history = useHistory();
  const [firstLogin, setFirstLogin] = useState<boolean>(false);
  const [redirectToOnboardingPage, setRedirectToOnboardingPage] =
    useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [wasLoggedIn, setWasLoggedIn] = useState<boolean | null>(null);

  function disableCreditSystem() {
    setCreditSystemEnabled(false);
  }

  const handleClose = () => {
    setRedirectToOnboardingPage(false);
  };

  const onClickGetStarted = () => {
    setRedirectToOnboardingPage(false);
  };

  const extensionEvent = () => {
    sendAnalyticsData(ANALYTICS_EVENT.EXTENSION_DOWNLOAD, {});
  };

  const setUserJWT = (jwt: string, firstTimeLogin: boolean) => {
    setCookies("jwt", jwt, { path: "/" });

    if (isEmpty(jwt)) {
      setUser(null);
      setJWT("");
      setLoader(false);
      setFirstLogin(false);
      setIsLoggedIn(false);
    } else {
      // Get JWT user
      setJWT(jwt);
      setFirstLogin(firstTimeLogin);
      getCurrentUser(createApiInstance(jwt))
        .then(function (resp) {
          setUser(resp.data);
          setWasLoggedIn(true);

          if (
            window.location.pathname === "/login" ||
            window.location.pathname === "/home" ||
            window.location.pathname === "/register"
          ) {
            identifyUser(resp.data!).then(function (output) {
              setAnalyticsUserId(resp.data);
              setIsLoggedIn(true);
              if (firstTimeLogin && !isMobile) {
                setRedirectToOnboardingPage(true);
              } else {
                history.push("/home");
              }
            });
          }
        })
        .catch(function (resp) {
          console.log("Failure", resp);
          setUser(null);
        })
        .finally(function () {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (isEmpty(jwt)) {
      setUserJWT("", false);
    } else {
      setUserJWT(jwt, firstLogin);
    }
  }, [jwt]);

  useEffect(() => {
    if (typeof isLoggedIn === "boolean") {
      if (isLoggedIn) {
        sendEvent(ANALYTICS_EVENT.LOGGED_IN);
      } else {
        if (wasLoggedIn) {
          sendEvent(ANALYTICS_EVENT.SIGNED_OUT);
          setWasLoggedIn(false);
          history.push("/login");
        }
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    function setupFlagsmith() {
      if (user && jwt) {
        identifyUser(user).then(function () {
          setCreditSystemEnabled(flagsmith.hasFeature(FLAGS.CREDIT_SYSTEM));
        });
      }
    }
    setupFlagsmith();
  }, [jwt, user]);

  // Redirect user accounts with unverified emails to /verifyemail after 24 hours of existence
  useEffect(() => {
    if (user && !user.active) {
      const currentDateMinus24Hours = new Date();
      currentDateMinus24Hours.setDate(currentDateMinus24Hours.getDate() - 1);

      if (new Date(user.created) < currentDateMinus24Hours) {
        history.push("/verifyemail");
      }
    }
  }, [user]);

  // Redirect user accounts without active subscription to /subscribe after 7 days of existence
  /*
  useEffect(() => {
    if (user) {
      const currentDateMinus7Days = new Date();
      currentDateMinus7Days.setDate(currentDateMinus7Days.getDate() - 7);

      if (new Date(user.created) < currentDateMinus7Days) {
        if (
          !user.subscription ||
          !(user.subscription.stripeSubscriptionStatus === "active")
        ) {
          history.push("/subscribe");
        }
      }
    }
  }, [user]);
  */

  return (
    <AuthContext.Provider
      value={{
        jwt: jwt,
        apiInstance: createApiInstance(jwt),
        loader: loader,
        user: user,
        setUserJWT: setUserJWT,
        creditSystemEnabled: creditSystemEnabled,
        disableCreditSystem: disableCreditSystem,
      }}>
      <>
        {redirectToOnboardingPage && <Redirect to="/onboarding" />}
        {children}
      </>
    </AuthContext.Provider>
  );
}
