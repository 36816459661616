import React, { useState } from "react";

const Accordion = ({ question, answer }: { question: string; answer: any }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="border-b cursor-pointer"
      onClick={() => setExpanded((expanded) => !expanded)}>
      <div className="py-4 md:py-6 flex justify-between items-stretch mx-auto max-w-screen-1920">
        <p className="text-mini md:text-extraSmall font-bold pr-2">
          {question}
        </p>
        <div className="text-superMini flex items-center justify-center rounded-full text-white">
          <button
            className="md:w-4 md:h-4 w-3 h-3 flex items-center justify-center rounded-full text-white md:text-extraSmall text-mini"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded((expanded) => !expanded);
            }}>
            {expanded ? (
              <img src="/images/minus-icon.png" alt="" />
            ) : (
              <img src="/images/plus-icon.png" alt="" />
            )}
          </button>
        </div>
      </div>
      {expanded ? (
        <div className="pb-6 mx-auto max-w-screen-1920">{answer}</div>
      ) : null}
    </div>
  );
};

const Faqs = () => {
  return (
    <div className="rounded bg-white px-4 lg:px-8 mt-6 flex flex-col">
      <div className="max-w-screen-1920 self-center w-full">
        <h1 className="font-gilroy 1440:text-largest xl:text-54 md:text-48 text-small mb-5 font-bold">
          Some FAQs
        </h1>
      </div>
      <Accordion
        question="1. What does the Keye Beta include?"
        answer={
          <div className="flex md:flex-row flex-col items-center">
            <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
              Our beta gives you short-term access (24 hours) to your favorite
              subscription services. We currently have dozens of subscription
              partners, and we’re constantly adding more!
            </p>
            <div className="w-11">
              <img src="/images/faq1.png" alt="" />
            </div>
          </div>
        }
      />
      <Accordion
        question="2. Sounds great – how do I activate my Keye access?"
        answer={
          <div className="flex md:flex-row flex-col items-center">
            <div className="mr-4">
              <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
                1. Find the subscription service you want
              </p>
              <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
                2. Click “Activate Access”
              </p>
              <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
                3. Accept the terms and conditions, answer a couple of questions
              </p>
              <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
                4. Then, you’ll receive instructions on how to log into the
                subscription service directly
              </p>
            </div>
            <div className="w-11">
              <img src="/images/faq1.png" alt="" />
            </div>
          </div>
        }
      />
      <Accordion
        question="3. How many times can I use Keye in a row?"
        answer={
          <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
            Depends on your credit availability.
          </p>
        }
      />
      <Accordion
        question="4. I requested access, but never received an email with instructions on how to log in. What should I do?"
        answer={
          <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
            You can check your spam box and also add Keye email to your contact
            list.
          </p>
        }
      />

      <Accordion
        question="5. How much does Keye cost?"
        answer={
          <div className="w-11 md:w-13">
            <img src="/images/faq6.png" alt="" />
          </div>
        }
      />

      <Accordion
        question="6.  I’m interested in partnering with Keye. How do I do that?"
        answer={
          <p className="text-mini md:text-extraSmall text-textGray max-w-5xl leading-40">
            To find out more about partnership compatibility connect with the
            team via: partnerships@keye.co.
          </p>
        }
      />
    </div>
  );
};

export default Faqs;
