import "../styles/KeyeLandingPageHeader.scss";

export function KeyeLandingPageHeader(): JSX.Element {
  return (
    <>
      <img
        src="../images/logo_new.svg"
        alt="Keye - Subscriptions Unlocked logo"
        id="keye-logo-landing"
      />
    </>
  );
}
