import React, { useContext, useEffect, useState } from "react";
import {
  getAllAccessesForUser,
  getComingSoonProductNotificationsForUser,
  getComingSoonProductsForUser,
  getCreditsForManyProducts,
  getCurrentAccessesForUser,
  getProductsForUser,
  getReservationDetailsForUserForManyProducts,
  getWaitlistDetailsForUserForManyProducts,
} from "../scripts/createApiInstance";

import {
  AccountAccess,
  Product,
  ProductAccountAccess,
  ProductCreditCost,
  ProductReservationDetails,
  WaitlistDetails,
} from "../models/Product";
import { AuthContext } from "./AuthContext";

interface ProductContextType {
  currentProducts: Product[];
  currentProductsLoading: boolean;
  currentProductAccesses: ProductAccountAccess[];
  currentProductAccessesLoading: boolean;
  allProductAccesses: AccountAccess[];
  allProductAccessesLoading: boolean;
  comingSoonProducts: Product[];
  comingSoonProductsLoading: boolean;
  comingSoonProductNotifications: String[];
  comingSoonProductNotificationsLoading: boolean;
  allProductWaitlistDetails: WaitlistDetails[];
  allProductWaitlistDetailsLoading: boolean;
  allProductReservationDetails: ProductReservationDetails[];
  allProductReservationDetailsLoading: boolean;
  allProductCreditCosts: ProductCreditCost[];
  allProductCreditCostsLoading: boolean;
  // SETTERS
  setCurrentProducts: (products: Product[]) => void;
  setCurrentProductAccesses: (accesses: ProductAccountAccess[]) => void;
  setComingSoonProductNotifications: (notifications: String[]) => void;
  setAllProductWaitlistDetails: (waitlistDetails: WaitlistDetails[]) => void;
}

export const ProductContext = React.createContext({
  currentProducts: [],
  currentProductsLoading: false,
  currentProductAccesses: [],
  currentProductAccessesLoading: false,
  allProductAccesses: [],
  allProductAccessesLoading: false,
  comingSoonProducts: [],
  comingSoonProductsLoading: false,
  comingSoonProductNotifications: [],
  comingSoonProductNotificationsLoading: false,
  allProductWaitlistDetails: [],
  allProductWaitlistDetailsLoading: false,
  allProductReservationDetails: [],
  allProductReservationDetailsLoading: false,
  allProductCreditCosts: [],
  allProductCreditCostsLoading: false,
  // SETTERS
  setCurrentProducts: () => {},
  setCurrentProductAccesses: () => {},
  setComingSoonProductNotifications: () => {},
  setAllProductWaitlistDetails: () => {},
} as ProductContextType);

// @ts-ignore
export default function ProductProvider({ children }) {
  // LOADERS
  const [currentProductsLoading, setCurrentProductsLoading] =
    useState<boolean>(false);
  const [currentProductAccessesLoading, setCurrentProductAccessesLoading] =
    useState<boolean>(false);
  const [allProductAccessesLoading, setAllProductAccessesLoading] =
    useState<boolean>(false);
  const [comingSoonProductsLoading, setComingSoonProductsLoading] =
    useState<boolean>(false);
  const [
    comingSoonProductNotificationsLoading,
    setComingSoonProductNotificationsLoading,
  ] = useState<boolean>(false);
  const [
    allProductWaitlistDetailsLoading,
    setAllProductWaitlistDetailsLoading,
  ] = useState<boolean>(false);
  const [
    allProductReservationDetailsLoading,
    setAllProductReservationDetailsLoading,
  ] = useState<boolean>(false);
  const [allProductCreditCostsLoading, setAllProductCreditCostsLoading] =
    useState<boolean>(false);

  // END LOADERS

  const [currentProducts, setCurrentProducts] = useState<Product[]>([]);
  const [currentProductAccesses, setCurrentProductAccesses] = useState<
    ProductAccountAccess[]
  >([]);
  const [allProductAccesses, setAllProductAccesses] = useState<AccountAccess[]>(
    []
  );
  const [comingSoonProducts, setComingSoonProducts] = useState<Product[]>([]);
  const [comingSoonProductNotifications, setComingSoonProductNotifications] =
    useState<String[]>([]);
  const [waitlistDetails, setWaitlistDetails] = useState<WaitlistDetails[]>([]);
  const [reservationDetails, setReservationDetails] = useState<
    ProductReservationDetails[]
  >([]);
  const [allProductCreditCosts, setAllProductCreditCosts] = useState<
    ProductCreditCost[]
  >([]);

  const { apiInstance, user, creditSystemEnabled, disableCreditSystem } =
    useContext(AuthContext);

  useEffect(() => {
    async function getCurrentProducts() {
      try {
        setCurrentProductsLoading(true);
        const products = await getProductsForUser(apiInstance);
        setCurrentProducts(products.data);
      } catch (err) {
        console.log("Error getting current products: ", err);
      } finally {
        setCurrentProductsLoading(false);
      }
    }
    if (user) {
      getCurrentProducts();
    }
  }, [apiInstance, user]);

  useEffect(() => {
    async function getCurrentAccesses() {
      try {
        setCurrentProductAccessesLoading(true);
        const currentAccesses = await getCurrentAccessesForUser(apiInstance);
        setCurrentProductAccesses(currentAccesses.data);
      } catch (err) {
        console.log("Error getting current accesses: ", err);
      } finally {
        setCurrentProductAccessesLoading(false);
      }
    }
    if (user) {
      getCurrentAccesses();
    }
  }, [apiInstance, user]);

  useEffect(() => {
    async function getAllAccesses() {
      try {
        setAllProductAccessesLoading(true);
        const allAccesses = await getAllAccessesForUser(apiInstance);
        setAllProductAccesses(allAccesses.data);
      } catch (err) {
        console.log("Error getting all accesses: ", err);
      } finally {
        setAllProductAccessesLoading(false);
      }
    }
    if (user) {
      getAllAccesses();
    }
  }, [apiInstance, user]);

  useEffect(() => {
    async function getComingSoonProducts() {
      try {
        setComingSoonProductsLoading(true);
        const products = await getComingSoonProductsForUser(apiInstance);
        setComingSoonProducts(products.data);
      } catch (err) {
        console.log("Error getting coming soon products: ", err);
      } finally {
        setComingSoonProductsLoading(false);
      }
    }
    if (user) {
      getComingSoonProducts();
    }
  }, [apiInstance, user]);

  useEffect(() => {
    async function getComingSoonProductNotifications() {
      try {
        setComingSoonProductNotificationsLoading(true);
        const notifications = await getComingSoonProductNotificationsForUser(
          apiInstance
        );
        setComingSoonProductNotifications(notifications.data);
      } catch (err) {
        console.log("Error getting coming soon product notifications: ", err);
      } finally {
        setComingSoonProductNotificationsLoading(false);
      }
    }
    if (user) {
      getComingSoonProductNotifications();
    }
  }, [apiInstance, user]);

  useEffect(() => {
    async function getAllProductWaitlistDetails() {
      try {
        setAllProductWaitlistDetailsLoading(true);
        const productIds = currentProducts.map((product) => product.id);
        const allWaitlistDetails =
          await getWaitlistDetailsForUserForManyProducts(
            apiInstance,
            productIds
          );
        setWaitlistDetails(allWaitlistDetails.data);
      } catch (err) {
        console.log("Error getting waitlist details: ", err);
      } finally {
        setAllProductWaitlistDetailsLoading(false);
      }
    }
    if (user) {
      getAllProductWaitlistDetails();
    }
  }, [apiInstance, currentProducts, user]);

  useEffect(() => {
    async function getAllProductReservationDetails() {
      try {
        setAllProductReservationDetailsLoading(true);
        const productIds = currentProducts.map((product) => product.id);
        const allReservationDetails =
          await getReservationDetailsForUserForManyProducts(
            apiInstance,
            productIds
          );
        setReservationDetails(allReservationDetails.data);
      } catch (err) {
        console.log("Error getting reservation details: ", err);
      } finally {
        setAllProductReservationDetailsLoading(false);
      }
    }
    if (user) {
      getAllProductReservationDetails();
    }
  }, [apiInstance, currentProducts, user]);

  useEffect(() => {
    async function getAllProductCreditCosts() {
      try {
        setAllProductCreditCostsLoading(true);
        const productIds = currentProducts.map((product) => product.id);

        const allCreditCosts = await getCreditsForManyProducts(
          apiInstance,
          productIds
        );
        setAllProductCreditCosts(allCreditCosts.data);
      } catch (err) {
        console.log("Error getting credit costs: ", err);
      } finally {
        setAllProductCreditCostsLoading(false);
      }
    }
    if (user && creditSystemEnabled) {
      getAllProductCreditCosts();
    } else {
      setAllProductCreditCosts([]);
      disableCreditSystem();
    }
  }, [
    apiInstance,
    creditSystemEnabled,
    currentProducts,
    disableCreditSystem,
    user,
  ]);

  return (
    <ProductContext.Provider
      value={{
        currentProducts: currentProducts,
        currentProductsLoading: currentProductsLoading,
        currentProductAccesses: currentProductAccesses,
        currentProductAccessesLoading: currentProductAccessesLoading,
        allProductAccesses: allProductAccesses,
        allProductAccessesLoading: allProductAccessesLoading,
        comingSoonProducts: comingSoonProducts,
        comingSoonProductsLoading: comingSoonProductsLoading,
        comingSoonProductNotifications: comingSoonProductNotifications,
        comingSoonProductNotificationsLoading:
          comingSoonProductNotificationsLoading,
        allProductWaitlistDetails: waitlistDetails,
        allProductWaitlistDetailsLoading: allProductWaitlistDetailsLoading,
        allProductReservationDetails: reservationDetails,
        allProductReservationDetailsLoading:
          allProductReservationDetailsLoading,
        allProductCreditCosts: allProductCreditCosts,
        allProductCreditCostsLoading: allProductCreditCostsLoading,
        // SETTERS
        setCurrentProducts: setCurrentProducts,
        setCurrentProductAccesses: setCurrentProductAccesses,
        setComingSoonProductNotifications: setComingSoonProductNotifications,
        setAllProductWaitlistDetails: setWaitlistDetails,
      }}>
      {children}
    </ProductContext.Provider>
  );
}
