import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_EVENT, sendAnalyticsData } from "../scripts/analytics";
import { AuthContext } from "../context/AuthContext";
import { PricingTable } from "../components/PricingTable";
import { Product, ProductAccount } from "../models/Product";
import {
  getProduct,
  getProductAccountForUser,
} from "../scripts/createApiInstance";

import "../styles/SubscribeView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

export const SubscribeView = () => {
  const { apiInstance, user } = useContext(AuthContext);
  const history = useHistory();
  const query = useQuery();
  let utm: any;
  let productId: string = "";
  const [product, setProduct] = useState<Product>();
  const [productAccount, setProductAccount] = useState<ProductAccount>();
  const [utmSource, setUtmSource] = useState<String>("");

  for (var pair of query.entries()) {
    if (pair[0] === "utm_source") {
      utm = pair[1];
    } else if (pair[0] === "productId") {
      productId = pair[1];
    }
  }

  useEffect(() => {
    if (productId) {
      (async () => {
        const product = await getProduct(apiInstance, productId);
        setProduct(product.data);
        const productAccountResponse = await getProductAccountForUser(
          apiInstance,
          productId
        );
        setProductAccount(productAccountResponse.data);
      })();
    }
  }, [productId]);

  useEffect(() => {
    setUtmSource(utm);
    sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_SUBSCRIBE_PAGE, {});
  }, [utm]);

  useEffect(() => {
    document.body.style.background =
      "linear-gradient(to bottom, #fff, #effeff)";
    return () => {
      document.body.style.background = "";
    };
  }, []);

  return (
    <>
      <Row className="section-1">
        <Col xs={0} md={2}></Col>
        <Col xs={12} md={8}>
          <Row>
            <Col xs={12} md={10}>
              {productId && !productAccount && (
                <>
                  <h1>
                    {product ? <span>{product.name}</span> : <>This product</>}{" "}
                    is only available on Keye Pro. Want access now?
                  </h1>
                  <h2>
                    Subscribe to get unlimited premium content for a low monthly
                    fee.
                  </h2>
                </>
              )}
              {!(productId && !productAccount) && (
                <>
                  <h1>Your trial is up. Want to keep using Keye?</h1>
                  <h2>
                    Subscribe to get unlimited premium content for a low monthly
                    fee.
                  </h2>
                </>
              )}
            </Col>
            <Col xs={12} md={2}>
              <div className="just-button">
                <PricingTable />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={2}></Col>
      </Row>

      {!isMobile && (
        <div className="section-2">
          <Row
            className="partners no-gutters"
            style={{ flexDirection: isMobile ? "column" : "row" }}>
            <Col>
              <span>Featured partners:</span>
            </Col>
            <Col>
              <img src="/images/partner_logo/crunchbase_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/information_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/masterclass_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/perlego_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/techcrunch_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/grammarly_landing.png" alt="" />
            </Col>
            <Col>
              <img src="/images/partner_logo/blinkist_landing.png" alt="" />
            </Col>
          </Row>
        </div>
      )}

      <div className="section-3">
        <Row>
          <Col xs={isMobile ? 12 : 6}>
            <div
              className="purple"
              style={{ margin: isMobile ? "5%" : "0 10% 0 20%" }}>
              <h3>Unlimited</h3>

              <span className="crossed">
                <sup>$</sup>20 / mo.
              </span>
              <span className="upper">Limited Time Deal</span>

              <p>
                <sup>$</sup>9.99 / mo.
              </p>

              <ul>
                <li>Unlimited credits refreshed every month</li>
                <li>All inclusive access to over 25 premium sites</li>
                <li>No commitment, cancel anytime</li>
              </ul>
              <PricingTable />
            </div>
          </Col>
          <Col xs={isMobile ? 12 : 6}>
            <div className="plan-features">
              <h3>This plan includes:</h3>

              <ul>
                <li>Exclusive data for decision making</li>
                <li>Learning tools for career development</li>
                <li>News & info for staying up to date</li>
                <li>Productivity tools for working better</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
