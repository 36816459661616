import React, { useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory, useParams } from "react-router-dom";
import { ResetPasswordDetails } from "../models/User";
import { resetPassword } from "../scripts/createApiInstance";

import { CenterLoader } from "../components/Loader";
import { KeyeLandingPageHeader } from "../components/KeyeLandingPageHeader";
import { Row, Col } from "react-bootstrap";
import { isEmpty } from "../scripts/util";

import "../styles/ResetView.scss";

interface ResetPasswordViewRouterParamTypes {
  resetPasswordJWT: string;
}

export function ResetPasswordView(): JSX.Element {
  const { apiInstance, loader } = React.useContext(AuthContext);
  const { resetPasswordJWT } = useParams<ResetPasswordViewRouterParamTypes>();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const history = useHistory();

  const passwordTooShort =
    password.length > 0 && confirmPassword.length > 0 && password.length < 8;
  const mismatchingPasswords =
    password.length > 0 &&
    confirmPassword.length > 0 &&
    password !== confirmPassword;

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>): void {
    evt.preventDefault();

    if (isEmpty(password) || password.length < 8) {
      return;
    }

    setFormDisabled(true);
    const resetPasswordDetails: ResetPasswordDetails = {
      resetPasswordJWT: resetPasswordJWT,
      password: password,
    };
    resetPassword(apiInstance, resetPasswordDetails)
      .then(function (resp) {
        setShowSuccess(true);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {
        setFormDisabled(false);
      });
  }

  const formGroupClass = `form-group`;

  return loader ? (
    <CenterLoader />
  ) : (
    <div className="reset-password-view-old p-5">
      <Row>
        <Col>
          <KeyeLandingPageHeader />
        </Col>
      </Row>
      <Row>
        <Col xs={10}>
          <legend id="reset-message">Reset Password</legend>
        </Col>
      </Row>
      <Row>
        <Col xs={10} md={8} lg={6}>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={formDisabled}>
              <div className={formGroupClass}>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password-form-field"
                />
              </div>
              <div className={formGroupClass}>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id="confirm-password-form-field"
                />
              </div>
              {mismatchingPasswords && (
                <p className="invalid-feedback d-block">
                  Passwords are not matching
                </p>
              )}
              {!mismatchingPasswords && passwordTooShort && (
                <p className="invalid-feedback d-block">
                  Password must be at least 8 characters long
                </p>
              )}
              <div className={"m-2"}>
                <button
                  disabled={mismatchingPasswords || passwordTooShort}
                  type="submit"
                  className="btn btn-primary"
                  id="submit-signup-btns">
                  Confirm
                </button>
              </div>
              {showSuccess && (
                <>
                  <p className="invalid-feedback d-block">Success!</p>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    id="submit-signup-btns"
                    onClick={() => {
                      history.push("/login");
                    }}>
                    Login
                  </button>
                </>
              )}
            </fieldset>
          </form>
        </Col>
      </Row>
    </div>
  );
}
