import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Nav,
  Navbar,
  NavLink,
  Row,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserCreditContext } from "../context/UserCreditContext";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  sendAnalyticsData,
} from "../scripts/analytics";
import {
  hasUserRequestedMoreCredits,
  referralUser,
  requestCreditsForUser,
} from "../scripts/createApiInstance";
import { identifyFSUser } from "../scripts/fullstory";
import { TabLink } from "../scripts/util";
import posthog from "posthog-js";

import "../styles/NavigationBar.scss";

interface NavigationBarProps {
  tabs: TabLink[];
}

export function NavigationBar({ tabs }: NavigationBarProps): JSX.Element {
  const history = useHistory();
  const { userCreditBalance } = useContext(UserCreditContext);
  const { setUserJWT, user, creditSystemEnabled, apiInstance } =
    React.useContext(AuthContext);

  const [userCreditRequestPending, setUserCreditRequestPending] =
    useState<boolean>(false);
  const [isRefiral, setReferal] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  useState<string>("");
  const [error, setErrorMessage] = useState<string>("");
  const [referStatus, setSuccesRefer] = useState<boolean>(false);
  const [referalInfo, setReferalInfo] = useState<any>("");
  useEffect(() => {
    if (user) {
      identifyFSUser(user);
    }
  }, [user]);

  const [showChromeExtModal, setChromeExtModal] = useState(false);

  function checkExtension(callback: Function) {
    const msg = { eventType: "exists", user: user };
    console.log("sending exists message : ", msg);
    window.postMessage(msg, "*");
    window.addEventListener("message", (event) => {
      if (
        event.data.eventType === "existsResponse" &&
        event.source === window
      ) {
        console.log("web msg rec : ", event.data);
        callback(true);
        return;
      }
    });
    callback(false);
  }

  useEffect(() => {
    if (!isMobile) {
      checkExtension((ok: boolean) => {
        console.log("Extension: %s", ok ? "installed" : "not installed");
        if (!ok) {
          setChromeExtModal(true);
        } else {
          setChromeExtModal(false);
          clearInterval(interval);
        }
      });

      const interval = setInterval(() => {
        checkExtension((ok: boolean) => {
          console.log("Extension: %s", ok ? "installed" : "not installed");
          if (!ok) {
            setChromeExtModal(true);
          } else {
            setChromeExtModal(false);
            clearInterval(interval);
          }
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [isMobile]);

  useEffect(() => {
    async function checkIfUserHasRequestedCredits() {
      try {
        const hasRequestedCredits = await hasUserRequestedMoreCredits(
          apiInstance
        );
        if (hasRequestedCredits.data) {
          setUserCreditRequestPending(true);
        } else {
          setUserCreditRequestPending(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (creditSystemEnabled) {
      checkIfUserHasRequestedCredits();
    }
  }, [apiInstance, creditSystemEnabled]);

  async function handleRequestCredits() {
    const response = await requestCreditsForUser(apiInstance);
    if (response.status === 204) {
      setUserCreditRequestPending(true);
    } else {
      console.log("Error requesting credits");
    }
  }

  function sendTabClickedEvent(tab: string) {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_TAB, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      tab_name: tab,
    });
  }
  const onClickAddExtn = () => {
    sendAnalyticsData(ANALYTICS_EVENT.EXTENSION_DOWNLOAD, {});
    window.open(
      "https://chrome.google.com/webstore/detail/keye-extension/mpfopflpokkojnllbmdnnfiakokdeaph",
      "_blank"
    );
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      setErrorMessage("Please enter valid Email!");
    } else {
      const query: any = [{ email: email }];
      try {
        const response = await referralUser(apiInstance, query);
        if (response.data) {
          let data = response.data[0];
          if (data.referred) {
            setErrorMessage("");
            setSuccesRefer(true);
            setReferalInfo(response.data);
          } else {
            setErrorMessage(data.message);
          }
        } else {
          console.log(error);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const nameOrEmail = (
    <span className="name-or-email" style={{ marginRight: "1rem" }}>
      {user ? (user.name ? user.name : user.email) : ""}
    </span>
  );

  return (
    <Row id="navigation-bar">
      {isRefiral ? (
        <>
          <Modal
            show={isRefiral}
            className="model-popup"
            onHide={() => {
              setReferal(false);
              setSuccesRefer(false);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Refer a friend</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} md={6}>
                  {referStatus ? (
                    <div className="mb-5">
                      <h3 className="refer-header-text">
                        Hooray! You have invited a friend to Keye.
                      </h3>
                      <p className="refer-sub-text">
                        Keye will send invited friend an email invitation with
                        instructions on how to sign up + code{" "}
                        <b>
                          {referalInfo &&
                            referalInfo[0].referred &&
                            referalInfo[0].referralCode}
                        </b>{" "}
                        to use when signing up!
                      </p>
                      <div className="format-credit-message">
                        <p>
                          <b
                            className={`${
                              referStatus
                                ? "refer-success-code"
                                : "refer-sub-text"
                            }`}>
                            +10
                          </b>{" "}
                          <img
                            src="/images/small_k.png"
                            id="nav-credit-k-logo"
                            alt="small keye logo"
                          />{" "}
                          {"  "}You get it when your friend signs up!
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mb-5">
                        <h3 className="refer-header-text">
                          Invite Friends, Earn Credits.
                        </h3>
                        <p className="refer-sub-text">
                          Everything gets a little better when shared with
                          friends!
                        </p>
                      </div>
                      <div>
                        <Form onSubmit={handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail">
                            <Form.Control
                              type="email"
                              placeholder="Enter friend's email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                          {error ? (
                            <p style={{ color: "red" }}> {error} </p>
                          ) : (
                            ""
                          )}
                          <Button className="btn-bg-color w-100" type="submit">
                            INVITE FRIENDS
                          </Button>
                        </Form>
                      </div>
                    </>
                  )}
                </Col>
                <Col xs={12} md={6} className="img-fluid">
                  <img
                    src={
                      referStatus
                        ? "/images/referral_2.png"
                        : "/images/referral_1.png"
                    }
                    alt="Keye - Refer friends"
                    className="refer-image1"
                  />
                  {!referStatus ? (
                    <h5 className="refer-header-sub-text">
                      Give 20 credits, get 20 credits
                    </h5>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
      <Col>
        <Row id="logo-and-signout-btn">
          <Col>
            <Navbar>
              <Navbar.Brand href="/home" id="nav-keye-header">
                <img
                  src="/images/keye_logo.png"
                  alt="a small blue keye icon"
                  id="keye-logo-nav"
                  style={{ width: "150px" }}
                />
              </Navbar.Brand>
              <Navbar.Collapse className="justify-content-end">
                {!isMobile && showChromeExtModal && (
                  <Button
                    className="btn btn-primary add-extn"
                    onClick={onClickAddExtn}>
                    Add Keye extension
                  </Button>
                )}
                <Navbar.Text
                  onClick={() => {
                    setUserJWT("", false);
                    sendAnalyticsData(ANALYTICS_EVENT.ON_CLICK_SIGN_OUT, {});
                    posthog.reset();
                    history.push("/");
                  }}
                  id="sign-out-text">
                  Sign Out
                  <i
                    className="logout-button fa fa-sign-out"
                    onClick={() => {
                      setUserJWT("", false);
                      history.push("/home");
                    }}></i>
                </Navbar.Text>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>

        <Row id="nav-options">
          <Col>
            <Navbar
              sticky="top"
              collapseOnSelect
              expand="md"
              bg="black"
              variant="light">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse>
                <Nav>
                  {tabs.map((tab: TabLink) => (
                    <LinkContainer
                      key={tab.name}
                      to={tab.link}
                      onClick={() => sendTabClickedEvent(tab.event_name)}>
                      <NavLink
                        key={tab.name}
                        className={`nav-link ${tab.event_name}`}>
                        {tab.name}
                      </NavLink>
                    </LinkContainer>
                  ))}
                </Nav>
              </Navbar.Collapse>
              {nameOrEmail}
            </Navbar>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
