import { useContext, useState } from "react";
import { ProductRequestForm } from "../components/ProductRequestForm";
import { FilterProductCategoryPills } from "../components/FilterProductCategoryPills";
import { CenterLoader } from "../components/Loader";
import { Col, Row } from "react-bootstrap";
import { ComingSoonProductCard } from "../components/ProductCard/ComingSoonProductCard";
import { ProductContext } from "../context/ProductContext";

import "../styles/ComingSoonView.scss";

export function ComingSoonView(): JSX.Element {
  const {
    comingSoonProducts,
    comingSoonProductsLoading,
    comingSoonProductNotifications,
    comingSoonProductNotificationsLoading,
  } = useContext(ProductContext);
  const [categoryFilter, setCategoryFilter] = useState<any>("");

  const filteredComingSoonProducts = comingSoonProducts.filter(
    (product) =>
      product.category.toString() === categoryFilter || categoryFilter === ""
  );

  const arrivalDates = comingSoonProducts.map((product) => {
    const arrivalDate = new Date(product.comingSoonDate);
    const month = arrivalDate.toLocaleString("default", {
      month: "long",
    });
    const day = arrivalDate.getDate();
    return `${month} ${day}`;
  });

  //@ts-ignore
  const uniqueArrivalDates = [...new Set(arrivalDates)];

  const loading =
    comingSoonProductsLoading || comingSoonProductNotificationsLoading;

  return (
    <Row id="coming-soon-view">
      {loading ? (
        <CenterLoader />
      ) : (
        <>
          <Col>
            <Row className="justify-content-baseline align-items-start">
              <Col xs={12} md={10}>
                <FilterProductCategoryPills
                  setCategoryFilter={setCategoryFilter}
                />
              </Col>
            </Row>

            {uniqueArrivalDates.map((arrivalDate, index) => {
              return (
                <div key={arrivalDate + index}>
                  <h3 key={arrivalDate}>Arriving week of {arrivalDate}</h3>
                  <Row
                    id="product-card-row"
                    style={{ minHeight: "500px" }}
                    key={arrivalDate + index}>
                    {filteredComingSoonProducts
                      .filter((product) => {
                        const prodDate = new Date(product.comingSoonDate);
                        const month = prodDate.toLocaleString("default", {
                          month: "long",
                        });
                        const day = prodDate.getDate();
                        return `${month} ${day}` === arrivalDate.toString();
                      })
                      .map((product) => {
                        return (
                          <Col
                            xs={12}
                            md={6}
                            xl={4}
                            key={product.id}
                            className="mb-3 d-flex">
                            <ComingSoonProductCard
                              product={product}
                              key={product.id}
                              notification={
                                comingSoonProductNotifications
                                  ? comingSoonProductNotifications.filter(
                                      (notification) => {
                                        return notification === product.id;
                                      }
                                    )
                                  : []
                              }
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              );
            })}
          </Col>
        </>
      )}

      <Row>
        <Col xs={12} lg={8} xxl={6}>
          <ProductRequestForm />
        </Col>
      </Row>
    </Row>
  );
}
