import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";

import "../styles/VerifyEmailView.scss";

export function VerifyEmailView(): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  return user ? (
    <div className="verify-email-view">
      <img src="/images/keye_logo.png" alt="Keye logo" />

      {!user.active && (
        <>
          <h1>Welcome back to Keye!</h1>
          <Row>
            <Col xs={12}>
              <p>
                To continue, please verify your email address by clicking on the
                link we sent you.
              </p>
            </Col>
            <Col xs={12}>
              <Button
                onClick={() => {
                  apiInstance
                    .post(
                      [
                        `${process.env.REACT_APP_API_SERVER_URL}`,
                        `/user/activation-email/resend`,
                      ]
                        .map((p) => p.replace(/^\/|\/$/g, ""))
                        .join("/")
                    )
                    .then((response) => {
                      setIsEmailSent(true);
                    })
                    .catch((err) => {
                      setIsEmailSent(false);
                    });
                }}
                disabled={isEmailSent}>
                Resend Email
              </Button>
            </Col>
            <Col xs={12}>
              <Link to="/home">I’ve already verified, take me to Keye</Link>
            </Col>
          </Row>
        </>
      )}
      {user.active && (
        <>
          <p>Your email address has been successfully verified.</p>
          <Link to="/home">
            <Button>Continue to Keye</Button>
          </Link>
        </>
      )}
    </div>
  ) : (
    <></>
  );
}
