import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { Header } from "../components/Header";
import { KeyeLandingPageHeader } from "../components/KeyeLandingPageHeader";
import { AuthContext } from "../context/AuthContext";
import { LoginCredentials, NewUser } from "../models/User";
import {
  createUser,
  loginUser,
  searchEmailAlreadyExist,
} from "../scripts/createApiInstance";
import { isEmpty } from "../scripts/util";
import Container from "react-bootstrap/Container";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  sendAnalyticsData,
  setAnalyticsUserId,
} from "../scripts/analytics";

import "../styles/RegisterView.scss";

const useQuery = () => new URLSearchParams(useLocation().search);

export function RegisterView(): JSX.Element {
  const { apiInstance, setUserJWT } = useContext(AuthContext);
  const history = useHistory();
  let query = useQuery();
  let email: any;
  let code: any;
  let invite: any;
  const [emailValue, setEmailValue] = useState<string>(
    (history.location?.state as any)?.email || ""
  );
  const [referal, setRefererCode] = useState<string>("");
  const [inviteId, setInviteId] = useState<string>("");
  const [userCategory, setUserCategory] = useState<string>("");
  const [step, setStep] = useState<number>(1);

  for (var pair of query.entries()) {
    if (pair[0] === "email") {
      email = pair[1];
    } else if (pair[0] === "code") {
      code = pair[1];
    } else if (pair[0] == "invite-id") {
      invite = pair[1];
    }
  }

  useEffect(() => {
    if (email && code) {
      setEmailValue(email);
      setRefererCode(code);
      console.log("email >>", email, code);
    }
  }, [email, code]);

  useEffect(() => {
    setInviteId(invite);
    console.log("invite : ", invite);
  }, [invite]);

  useEffect(() => {
    sendAnalyticsData(
      ANALYTICS_EVENT.ON_LOAD_SIGNUP_PAGE,
      eventBundleProperties(null, EVENT_TYPE.UI)
    );
  }, []);

  useEffect(() => {
    if (step === 2) {
      sendAnalyticsData(
        ANALYTICS_EVENT.ON_LOAD_SIGNUP_PAGE_STAGE_2,
        eventBundleProperties(null, EVENT_TYPE.UI)
      );
    }
  }, [step]);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    setErrorMessage("");

    if (step === 1) {
      if (event.currentTarget.checkValidity()) {
        setStep(2);
      }
    } else if (step === 2) {
      if (!event.currentTarget.checkValidity()) {
        return;
      }

      setDisabled(true);

      const newUserRequest: NewUser = {
        firstName: firstName,
        lastName: lastName,
        email: emailValue ?? (emailValue as string).toLowerCase(),
        password: password,
        referralCode: referal,
        inviteId: inviteId,
        userCategory: userCategory,
      };
      createUser(apiInstance, newUserRequest)
        .then(function (resp) {
          setAnalyticsUserId(resp.data);
          sendAnalyticsData(ANALYTICS_EVENT.ON_SIGNUP_USER, {
            referralId: newUserRequest.referralCode,
            referalName: newUserRequest.email,
            inviteId: newUserRequest.inviteId,
            inviteTags: resp.data.inviteTags,
          });
        })
        .then(() => {
          const loginCreds: LoginCredentials = {
            email: newUserRequest.email,
            password: newUserRequest.password,
          };
          loginUser(apiInstance, loginCreds).then(function (resp: any) {
            const { token, fistLogin } = resp?.data;
            setUserJWT(token, fistLogin);
          });
        })
        .catch(function (err) {
          if (typeof err.response.data === "string") {
            if (err.response.data.toLowerCase().includes("whitelist")) {
              setErrorMessage("User not in whitelist");
            } else if (
              err.response.data.toLowerCase().includes("existing keye account")
            ) {
              setErrorMessage("User already exists");
            }
          }
        })
        .finally(function () {
          setDisabled(false);
        });
    }
  }

  const handleEmailAlreadyExist = async (e: any) => {
    try {
      const emailAlreadyExist = await searchEmailAlreadyExist(
        apiInstance,
        e.target.value
      );
      if (emailAlreadyExist.data) {
        setErrorMessage(
          "This email is already being used with a Keye account. Try signing in or use a different email."
        );
      } else {
        setErrorMessage("");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const formGroupClass = `form-group m-1`;
  const passwordCheckValid = password === retypePassword;
  const retypePasswordClassName = `${!passwordCheckValid ? "is-invalid" : ""}`;

  return (
    <>
      <Header />

      <div className="register-view">
        <Row className="no-gutters">
          <Col xs={0} sm={0} md={1} lg={2} xl={2}></Col>
          <Col
            xs={12}
            sm={12}
            md={5}
            lg={4}
            xl={4}
            style={{
              margin: "6rem auto",
              paddingRight: "3rem",
              display: isMobile ? "none" : "_",
            }}>
            <h1>
              Keye is free for 7 days
              <br />
              with no commitment
            </h1>

            <p>What you’ll get:</p>
            <ul className="list">
              <li>
                Credits to unlock your favorite premium content, tools and more
              </li>
              <li>
                Instant access to over 20 productivity tools, 500 streaming
                classes, 1000 paywalled articles and millions of books
              </li>
              <li>
                As an early user, you’ll get an offer to keep using Keye at a
                special reduced price once your trial is up
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={5}
            lg={4}
            xl={4}
            style={{ margin: "6rem auto" }}>
            <div
              className="white p-5"
              style={{ borderRadius: isMobile ? 0 : "_" }}>
              <h1 className="blue mb-5">
                {step === 1 && "Create Account"}
                {step === 2 && "Tell us a bit about you"}
              </h1>

              {step === 1 && (
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={disabled}>
                    <div className={formGroupClass}>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)}
                        required
                      />
                    </div>
                    <div className={formGroupClass}>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <Row>
                      <Col xs={6}>
                        {errorMessage.length > 0 && (
                          <p className="invalid-feedback d-block p-0 mt-0 mx-3 mb-3">
                            {errorMessage}
                          </p>
                        )}
                      </Col>
                      <Col xs={6}>
                        <p className="mx-3 mb-3" style={{ textAlign: "right" }}>
                          {/*<Link to="/reset">Forgot your password?</Link>*/}
                        </p>
                      </Col>
                    </Row>
                    <div className="mt-1">
                      <button
                        type="submit"
                        className="btn w-100"
                        id="submit-signup-btns"
                        onClick={() => {
                          sendAnalyticsData(
                            ANALYTICS_EVENT.ON_CLICK_SIGNUP_PAGE_CONTINUE,
                            eventBundleProperties(null, EVENT_TYPE.UI)
                          );
                        }}>
                        Continue
                      </button>
                    </div>
                  </fieldset>
                </form>
              )}
              {step === 2 && (
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={disabled}>
                    <div className={formGroupClass}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className={formGroupClass}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className={formGroupClass}>
                      <select
                        className="form-control form-border"
                        value={userCategory}
                        onChange={(e) => setUserCategory(e.target.value)}
                        required>
                        <option value="" hidden>
                          Select your occupation
                        </option>
                        <option value={"student"}>Student</option>
                        <option value={"employed_full_time"}>
                          Employed full-time
                        </option>
                        <option value={"self_employed"}>Self-employed</option>
                        <option value={"other"}>Other</option>
                      </select>
                    </div>
                    <Row>
                      <Col xs={6}>
                        {errorMessage.length > 0 && (
                          <p className="invalid-feedback d-block p-0 mt-0 mx-3 mb-3">
                            {errorMessage}
                          </p>
                        )}
                      </Col>
                      <Col xs={6}>
                        {/*<p className="mx-3 mb-3" style={{ textAlign: "right" }}>
                          <Link to="/reset">Forgot your password?</Link>
                        </p>*/}
                      </Col>
                    </Row>
                    <div className="mt-1">
                      <button
                        type="submit"
                        className="btn w-100"
                        id="submit-signup-btns"
                        onClick={() => {
                          sendAnalyticsData(
                            ANALYTICS_EVENT.ON_CLICK_SIGNUP_PAGE_STAGE_2_CONTINUE,
                            eventBundleProperties(null, EVENT_TYPE.UI)
                          );
                        }}>
                        Sign up
                      </button>
                    </div>
                  </fieldset>
                </form>
              )}
              <span
                className={[formGroupClass, "px-3"].join(" ")}
                style={{ paddingTop: "80px" }}>
                By getting started, you agree to our{" "}
                <Link to="/terms">Terms</Link> and{" "}
                <Link to="/privacy">Privacy Policy</Link>
              </span>
            </div>
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} xl={2}></Col>
        </Row>
      </div>
    </>
  );
}
