import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  sendAnalyticsData,
} from "../scripts/analytics";
import { AuthContext } from "../context/AuthContext";
import { sendResetPasswordLink } from "../scripts/createApiInstance";

import { Header } from "../components/Header";
import { CenterLoader } from "../components/Loader";
import { KeyeLandingPageHeader } from "../components/KeyeLandingPageHeader";
import { Row, Col } from "react-bootstrap";
import { isEmpty } from "../scripts/util";

import "../styles/ResetView.scss";

export function ResetView(): JSX.Element {
  const { apiInstance, loader } = React.useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showResetMessage, setShowResetMessage] = useState<boolean>(false);

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>): void {
    evt.preventDefault();
    if (isEmpty(email)) {
      return;
    }
    setDisabled(true);
    sendResetPasswordLink(apiInstance, email)
      .then(function (resp) {
        setShowResetMessage(true);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(function () {});
  }

  const formGroupClass = `form-group m-3`;

  if (loader) {
    return <CenterLoader />;
  }

  return (
    <>
      <Header />

      <div className="reset-view">
        <Row>
          <Col xs={1} sm={2} md={2} lg={3} xl={4}></Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            lg={6}
            xl={4}
            style={{ margin: "6rem auto" }}>
            <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
              Reset password
            </h1>

            <form onSubmit={handleSubmit}>
              <fieldset disabled={disabled}>
                <div className={formGroupClass}>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <Row>
                  <Col xs={11}>
                    {showResetMessage && (
                      <p
                        className={[
                          "invalid-feedback d-block p-0",
                          "mt-0 mx-3 mb-3",
                        ].join(" ")}>
                        You will receive an email from Keye with instructions.
                      </p>
                    )}
                  </Col>
                  <Col xs={1}>
                    <p className="mx-3 mb-3" style={{ textAlign: "right" }}>
                      &nbsp;
                    </p>
                  </Col>
                </Row>
                <div className="mx-3 mt-1">
                  <button
                    type="submit"
                    className="btn w-100"
                    id="submit-signup-btns"
                    onClick={() => {
                      sendAnalyticsData(
                        ANALYTICS_EVENT.ON_CLICK_RESET_PASSWORD_CONFIRM,
                        eventBundleProperties(null, EVENT_TYPE.UI)
                      );
                    }}>
                    Confirm
                  </button>
                </div>
              </fieldset>
            </form>
            <p
              className={[formGroupClass, "px-3 py-2"].join(" ")}
              style={{ textAlign: "right" }}>
              Back to <Link to="/login">Login</Link>
            </p>
          </Col>
          <Col xs={1} sm={2} md={2} lg={3} xl={4}></Col>
        </Row>
      </div>
    </>
  );
}
