import { useEffect } from "react";
import Row from "react-bootstrap/Row";

import "../styles/PrivacyPolicyView.scss";

export function PrivacyPolicyView(): JSX.Element {
  useEffect(() => {
    document.documentElement.style.backgroundColor = "#175fd3";

    return () => {
      document.documentElement.style.backgroundColor = "";
    };
  }, []);

  return (
    <div id="privacy-policy-view">
      <Row>
        <div>
          <h1>Keye, Inc. Privacy Policy</h1>
          <p
            className="s2"
            style={{
              paddingTop: "4pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Last Updated: 19<sup>th</sup> February, 2023.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Your privacy is important to us at Keye, Inc. (“<b>Keye</b>,” “
            <b>we</b>,” “<b>us</b>,” or “<b>our</b>”), and we are committed to
            safeguarding, preserving, and respecting your privacy rights. This
            privacy statement (the “<b>Statement</b>” or “<b>Policy</b>”)
            describes how we collect, use, disclose, and the security of the
            personal information we gather about you through our website, [url]
            (the “Website”), our mobile application, and the platform
            (collectively, the “Services”).
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            For purposes of this Statement, personal information means data that
            classifies as personal information, personal data, personally
            identifiable information, or similar terms under applicable data
            privacy and security laws and regulations. It does not include data
            excluded or exempted from those laws and regulations, such as
            deidentified, anonymized, or aggregated data. Nothing in this
            Statement will constitute an admission or evidence that any
            particular data privacy or information security law or regulation
            applies to Keye generally or in any specific context.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "justify",
            }}>
            Our Services are used at times by third parties in connection with
            the services they provide. This Policy does not apply to how third
            parties may collect and process personal information, and you should
            consult their applicable privacy policies and terms.
          </p>
          <h1
            style={{
              paddingTop: "8pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            You Consent to This Statement
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            You should read this Statement carefully. We recommend printing and
            retaining a copy for your future reference. By accessing, browsing,
            or otherwise using the Services, you confirm that you have read,
            understood, and agreed with this Statement. If you do not agree to
            this Statement, you may not use the Services.
          </p>
          <p
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            This Statement applies regardless of how the Services are accessed
            and will cover any technologies or devices by which we make the
            Services available to you.
          </p>
          <p
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We may provide you with additional privacy notices and terms where
            we believe it is appropriate to do so. It is important that you read
            this Statement together with any other privacy notice or terms we
            may provide on specific occasions, so that you are fully aware of
            how and why we are using your data. This Statement supplements these
            other notices and is not intended to override them.
          </p>
          <p
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            If you have any questions or concerns about our personal information
            policies or practices, you can contact us in the methods described
            in the “<b>Contact Us</b>” section below.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            The Information We Collect
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We collect the information you voluntarily provide directly to us.
            We also collect information when you interact with the Services.
          </p>
          <h2
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark0">Information You Provide</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ul>
            <li data-list-text="●">
              Using Our Service.{" "}
              <span className="p">
                When you use our Services, we may ask you to provide information
                such as your name, email address, mailing address, phone number,
                and payment information. We may also collect information
                relating to how you use the Services, including drawing
                inferences derived from your use.
              </span>
            </li>
            <li data-list-text="●">
              Social Media.{" "}
              <span className="p">
                If you interact with our Services through social media, we may
                collect information that you post. Please note that your
                comments and information will be visible to the public, so you
                should never share personal information that you would like to
                keep private.
              </span>
            </li>
            <li data-list-text="●">
              When You Contact Us.{" "}
              <span className="p">
                If you send us an email or otherwise contact us, we will collect
                your email address and any information that you provide in the
                email.
              </span>
            </li>
          </ul>
          <h2
            style={{
              paddingTop: "9pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark1">Information That We Collect About You</a>
          </h2>
          <br />
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We currently do not collect any information but we may collect
            information about you from third party sources, before which you
            will be notified.
          </p>
          <br />
          <h2
            style={{
              paddingTop: "9pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark2">
              Information We Collect as You Navigate Our Site
            </a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            We automatically collect certain information as you use the
            Services, such as the following:
          </p>
          <ul>
            <li data-list-text="●">
              Usage Information
              <span className="p">
                . We may collect information automatically through your use of
                the Services, such as which of the pages on the Services you
                access, the frequency of access, and how long you use our
                Services.
              </span>
            </li>
            <li data-list-text="●">
              Device Information.{" "}
              <span className="p">
                We may collect information about the device you are using, such
                as hardware model, operating system, application version number,
                browser, and IP addresses.
              </span>
            </li>
            <li data-list-text="●">
              Mobile Device Information
              <span className="p">
                . When you access our Services via a browser on your mobile
                device, we may also collect mobile network information,
                including telephone number, the unique device identifier
                assigned to that device, mobile carrier, operating system, and
                other device attributes.
              </span>
            </li>
            <li data-list-text="●">
              Location Information
              <span className="p">
                . We may collect information about your actual location which
                may be determined from GPS and other sensors that may reveal
                information on or nearby devices, Wi-Fi access points, and cell
                towers.
              </span>
            </li>
          </ul>
          <h1
            style={{
              paddingTop: "8pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            How Do We Use “Cookies” and Other Tracking Technologies?
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We may send one or more Cookies to your computer or other device. We
            may also use other similar technologies such as tracking pixels,
            tags, or similar tools when you visit our Services.
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Cookies are small files created by websites, including our Services,
            that reside on your computer
            <span className="s4">’</span>s hard drive and that store information
            about your use of a particular website. When you access our
            Services, we use Cookies and other tracking technologies to:
          </p>
          <ul>
            <li data-list-text="●">
              Estimate our audience size and usage patterns;
            </li>
            <li data-list-text="●">
              Store information about your preferences, allowing us to customize
              our Services according to your individual needs;
            </li>
            <li data-list-text="●">
              Contact you to provide you with information or services that you
              request from us;
            </li>
            <li data-list-text="●">
              Advertise new content, events, and services that relate to your
              interests;
            </li>
            <li data-list-text="●">
              Provide you with more personalized content that is most relevant
              to your interest areas; and
            </li>
            <li data-list-text="●">
              Recognize when you return to our Services.
            </li>
          </ul>
          <p
            style={{
              paddingTop: "11pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            You can manage your Cookies preference as described in the “
            <b>Managing Your Cookies</b>” section below.
          </p>
          <h2
            style={{
              paddingTop: "9pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark3">What Types of Cookies Do We Use and Why?</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "justify",
            }}>
            The following chart lists the different types of Cookies that we and
            our service providers use on the Services, examples of who serves
            those Cookies and links to the privacy notices and opt-out
            information of those Cookie servers.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <table>
            <tbody>
              <tr>
                <th>Types of Cookies</th>
                <th>Purpose</th>
                <th>Who Serves (for example)</th>
              </tr>
              <tr>
                <td>Essential</td>
                <td>
                  These Cookies are required for the operation of the Services
                  and enable you to move around the Services and use its
                  features. Disabling these Cookies can negatively impact the
                  performance of Services.
                </td>
                <td>
                  <ul id="l2">
                    <li data-list-text="●">Google</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Functionality</td>
                <td>
                  These Cookies are used to recognize you when you return to the
                  Site. This enables us to personalize content for you and
                  remember your preferences. These Cookies also enable your
                  interactions with the Services such as emailing us.
                </td>
                <td>
                  <ul id="l3">
                    <li data-list-text="●">Google</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Analytics, Performance, and Research</td>
                <td>
                  These Cookies, beacons, and pixels allow us to analyze
                  activities on the Services. They can be used to improve the
                  functioning of the Services. For example, these Cookies
                  recognize and count the number of visitors and see how they
                  move around the Services. Analytics Cookies also help us
                  measure the performance of our advertising campaigns to help
                  us improve them and to optimize the content on the Services
                  for those who engage with our advertising.
                </td>
                <td>
                  <ul id="l4">
                    <li data-list-text="●">Google</li>
                    <li data-list-text="●">Facebook</li>
                    <li data-list-text="●">PostHog</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Social Networking</td>
                <td>
                  These Cookies are used to enable you to share pages and
                  content that you find interesting on our Services through
                  third-party social networking and other websites. These
                  Cookies may also be used for advertising purposes.
                </td>
                <td>
                  <ul id="l5">
                    <li data-list-text="●">Google</li>
                    <li data-list-text="●">Facebook</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Advertising</td>
                <td>
                  These Cookies and pixels are used to deliver relevant ads,
                  track ad campaign performance, or track email marketing.
                </td>
                <td>
                  <ul id="l6">
                    <li data-list-text="●">Google</li>
                    <li data-list-text="●">Facebook</li>
                    <li data-list-text="●">PostHog</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We do not use any cookies, pixels, or other tracking that discloses
            to any third party information that identifies a person as having
            viewed specific video materials.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark4">How Long Do Cookies Stay on My Device?</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Some Cookies operate from the time you visit the Services until the
            end of that particular browsing session. These Cookies, which are
            called “<b>Session Cookies</b>,” expire and are automatically
            deleted when you close your Internet browser.
          </p>
          <p
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Some Cookies will stay on your device between browsing sessions and
            will not expire or automatically delete when you close your Internet
            browser. These Cookies are called “Persistent Cookies” and the
            length of time they will remain on your device will vary from Cookie
            to Cookie. Persistent Cookies are used for a number of purposes,
            such as storing your preferences so that they are available for your
            next visit and to keep a more accurate account of how often you
            visit the Services, how your use of the Services may change over
            time, and the effectiveness of advertising efforts.
          </p>
          <h2
            style={{
              paddingTop: "9pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark5">Managing Your Cookies</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            It may be possible to block Cookies by changing your Internet
            browser settings to refuse all or some Cookies. If you choose to
            block all Cookies (including Essential Cookies), you may not be able
            to access all or parts of the Services.
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            You can find out more about Cookies and how to manage them by
            visiting{" "}
            <a
              href="http://www.AboutCookies.org/"
              target="_blank"
              rel="noreferrer">
              www.AboutCookies.org
            </a>
            .
          </p>
          <h2
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark6">
              Does the Site Respond to “Do Not Track” Signals?
            </a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            At this time, our Site does not respond differently based on a user
            <span className="s4">’</span>s Do Not Track signal.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            How We Use the Personal Information
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We use personal information we collect about you or that you provide
            to us in the following ways:
          </p>
          <ul id="l7">
            <li data-list-text="●">
              To present our Services, our Website, and their contents in a
              suitable and effective manner for you and your device;
            </li>
            <li data-list-text="●">
              To provide you with products, information, or Services that you
              request from us;
            </li>
            <li data-list-text="●">
              To advertise opportunities, services, or special events that we
              think may be of interest to you;
            </li>
            <li data-list-text="●">
              To provide customer support, troubleshoot issues, manage accounts,
              and respond to requests, questions, or comments;
            </li>
            <li data-list-text="●">
              To carry out our obligations and enforce our rights arising from
              any contracts; and
            </li>
            <li data-list-text="●">
              To notify you about any additions, upgrades, or changes in our
              Services.
            </li>
          </ul>
          <br />
          <br />
          <h1
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            How We Share Personal Information
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{
              paddingLeft: "7pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark7">
              Access and use by third-party service providers:
            </a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            From time to time, we enter into arrangements with companies whose
            expertise is essential for our own services to function properly. We
            disclose to these service providers only such personal information
            as is reasonably necessary to perform these functions on our behalf,
            and any such third parties will be under an obligation to us to
            maintain the confidentiality of your personal information.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark8">For legal purposes:</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We reserve the right to cooperate with local, provincial, state,
            federal and international officials in any investigation requiring
            either personal information or reports about lawful of unlawful user
            activity on this site. We also reserve the right to share your
            personal information
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            to establish, exercise, or defend our legal and property rights,
            including providing information to others for the purposes of fraud
            prevention. We may also share your personal information with any
            person who we reasonably believe may apply to a court or other
            competent authority for
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            disclosure of that personal information where, in our reasonable
            opinion, such court or authority would be reasonably likely to order
            disclosure of that personal information.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            If Keye is ordered by a legal process or government to provide data
            about one or more individual users, we will notify those users of
            our compliance with that order, to the extent permitted by law.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark9">Business transactions or mergers:</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Keye reserves the right to share your personal information to third
            parties as part of any potential business or asset sale, merger,
            acquisition, investment, round of funding, or similar type of
            transaction.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            <a id="bookmark10">Bankruptcy or insolvency:</a>
          </h2>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            In the event of bankruptcy, insolvency, or dissolution proceedings,
            we may share your personal information with third parties as part of
            the sale or reorganization process.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Advertising and Marketing Choice
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We respect your rights in how your personal information is used and
            shared. If at any time you would like to unsubscribe from receiving
            future emails, you can email us at:{" "}
            <a
              href="mailto:contact@keye.co"
              className="a"
              target="_blank"
              rel="noreferrer">
              contact@keye.co
            </a>{" "}
            as well as follow the instructions at the bottom of each email and
            we will promptly remove you from all correspondence. Please note,
            however, that we may still need to contact you regarding other
            matters.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Where Your Personal Information Is Held
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We processes personal information on our servers in the United
            States of America, and may do so in other countries. If you use our
            Services or otherwise provide us with information from outside of
            the United States, you expressly consent to the transfer of your
            data to the United States, the processing of your data in the United
            States, and the storage of your data in the United States.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            How Long Is Your Personal Information Kept
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We will retain your personal information until the personal
            information is no longer necessary to accomplish the purpose for
            which it was provided. We may retain your personal information for
            longer periods for specific purposes to the extent that we are
            obliged to do so in accordance with applicable laws and regulations,
            to protect you, other people, and us from fraud, abuse, an
            unauthorized access, as necessary to protect our legal rights, or
            for certain business requirements.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            We will delete your personal information when it is no longer
            necessary for the purpose for which it was collected, or upon your
            request, subject to exceptions as discussed in this Statement or
            under applicable law, contract, or regulation.
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            If you use our mobile application, you can delete your account by
            submitting a request to{" "}
            <a href="mailto:contact@keye.co">contact@keye.co</a> or by deleting
            your account through the application itself. If you delete your
            account from within the application, we will delete the accompanying
            personal data.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Our Commitment to Data Security
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            The security of your personal information is important to us. We
            take various reasonable organizational, administrative, and
            technical measures to protect your personal information from
            unauthorized access, disclosure, alteration, or destruction. If
            required by law to do so, we will notify you and/or the relevant
            supervisory authority in the event of a data breach.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            However, we cannot and do not guarantee complete security, as it
            does not exist on the Internet.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Third Party Links
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Our Services may contain links to third-party websites. When we
            provide links, we do so only as a convenience and do not endorse any
            products or services and are not responsible for any content of any
            third-party website or any links contained within. It is important
            to note that this Statement only applies to our Services. We are not
            responsible and assume no responsibility for any personal
            information collected, stored, or used by any third party as a
            result of you visiting third-party websites. We also advise that you
            read the privacy notice of any third-party websites you choose to
            visit carefully.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Children’s Privacy
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            Protecting the privacy of the very young is especially important.
            Our Services are not intended for children under 18 years of age,
            and we do not knowingly collect personal information from children
            under 18. In the event that we learn that we have collected personal
            information from a child under age 18 without verification or
            parental consent, we will immediately delete that information. If
            you believe that we might have any information from or about a child
            under 18, please contact us using the information provided in the “
            <b>Contact Us</b>” section below.
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Policy Changes
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            This Statement may change from time to time. We will post any
            changes to this Statement on this page, and you should check these
            terms when you use the Services. Your continued use of the Site
            constitutes acceptance of the most current version of this Statement
          </p>
          <h1
            style={{
              paddingTop: "10pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Contact Us
          </h1>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "109%",
              textAlign: "left",
            }}>
            If you have any questions about this Privacy Policy, please contact
            us by email at{" "}
            <a href="mailto:contact@keye.co" target="_blank" rel="noreferrer">
              contact@keye.co
            </a>
          </p>
          <br />
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Phone: +1 (470) 724-2721
          </p>
          <br />
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}>
            Mail:
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "196%",
              textAlign: "left",
            }}>
            77 Fulton Street, 25G
            <br />
            New York, NY 10038
          </p>
        </div>
      </Row>
    </div>
  );
}
