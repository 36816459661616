import { Fragment, useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AuthContext } from "../context/AuthContext";
import { RequestSearchQuery } from "../models/Product";
import {
  searchProductUniverse,
  requestProductForUser,
} from "../scripts/createApiInstance";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  sendAnalyticsData,
} from "../scripts/analytics";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/ProductRequestForm.scss";

export function ProductRequestForm(): JSX.Element {
  const { apiInstance, user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [formComplete, setFormComplete] = useState(false);

  async function handleSearch(term: any) {
    try {
      setIsLoading(true);
      const query: RequestSearchQuery = {
        query: term,
        page: 0,
        limit: 25,
      };
      const response = await searchProductUniverse(apiInstance, query);
      const options = response.data;
      setOptions(options);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  function sendProductRequestEvent(product_name: string) {
    return sendAnalyticsData(ANALYTICS_EVENT.ON_REQUEST_NEW_PRODUCT, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      input_product: product_name,
    });
  }

  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      let selectedProduct = selected;
      // the Typeahead component returns an array if the selection is a custom one,
      // so we need to check for that and extract the name
      if (selectedProduct[0].name === undefined) {
        // not custom selection
        selectedProduct = selected;
        await requestProductForUser(apiInstance, selectedProduct);
        sendProductRequestEvent(selected[0]);
        setFormComplete(true);
      } else {
        // custom selection
        selectedProduct = selectedProduct[0];
        await requestProductForUser(apiInstance, [selectedProduct.name]);
        sendProductRequestEvent(selectedProduct.name);
        setFormComplete(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Form id="product-request-form">
      <Row>
        <Col>
          <Form.Label id="request-product-header">
            Not finding what you're looking for? Request a product below!
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col id="request-product-form-body">
          <Form.Group controlId="formFindProduct">
            <Form.Label>Find a Product</Form.Label>
            <AsyncTypeahead
              clearButton
              allowNew={options.length === 0}
              newSelectionPrefix="Suggest new product:  "
              filterBy={filterBy}
              id="searchProductUniverse"
              isLoading={isLoading}
              labelKey="name"
              minLength={1}
              useCache={false}
              onSearch={handleSearch}
              onChange={setSelected}
              options={options}
              placeholder="Search for a product"
              renderMenuItemChildren={(option, props) => (
                <Fragment>
                  <span>{option}</span>
                </Fragment>
              )}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Label>
                If we add the product you requested, you will be the first to
                know.
              </Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              {!formComplete ? (
                <Button
                  disabled={selected.length === 0}
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}>
                  Place Request
                </Button>
              ) : (
                <Button
                  variant="success"
                  type="submit"
                  onClick={() => setFormComplete(false)}>
                  Request Submitted - Submit another?
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
