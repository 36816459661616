import React, { useContext, useEffect, useState } from "react";
import {
  getCreditsForUser,
  getUserTransactions,
} from "../scripts/createApiInstance";

import { UserTransactionRecord } from "../models/Product";
import { AuthContext } from "./AuthContext";

interface UserCreditContextType {
  userCreditBalance: number;
  setUserCreditBalance: (balance: number) => void;
  userCreditBalanceLoading: boolean;

  userTransactions: UserTransactionRecord[];
  userTransactionsLoading: boolean;
}

export const UserCreditContext = React.createContext({
  userCreditBalance: 0,
  setUserCreditBalance: (balance: number) => {},
  userCreditBalanceLoading: false,

  userTransactions: [],
  userTransactionsLoading: false,
} as UserCreditContextType);

// @ts-ignore
export default function UserCreditProvider({ children }) {
  const { apiInstance, user, creditSystemEnabled } = useContext(AuthContext);

  // LOADERS
  const [userCreditBalanceLoading, setUserCreditBalanceLoading] =
    useState<boolean>(false);
  const [userTransactionsLoading, setUserTransactionsLoading] =
    useState<boolean>(false);

  // END LOADERS

  const [userCreditBalance, setUserCreditBalance] = useState(0);
  const [userTransactions, setUserTransactions] = useState<
    UserTransactionRecord[]
  >([]);

  useEffect(() => {
    function getUserCreditBalanceOnLoad() {
      setUserCreditBalanceLoading(true);
      getCreditsForUser(apiInstance)
        .then(function (resp) {
          setUserCreditBalance(resp.data);
        })
        .catch(function (err) {
          console.log("Get credits for user error", err);
        })
        .finally(function () {
          setUserCreditBalanceLoading(false);
        });
    }
    if (user && creditSystemEnabled) {
      getUserCreditBalanceOnLoad();
    }
  }, [apiInstance, creditSystemEnabled, user]);

  useEffect(() => {
    async function getUserTransactionsOnLoad() {
      try {
        setUserTransactionsLoading(true);
        const transactions = await getUserTransactions(apiInstance);
        if (transactions.status === 200) {
          setUserTransactions(transactions.data);
        }
      } catch (e) {
        console.log("Error getting user transactions: ", e);
      } finally {
        setUserTransactionsLoading(false);
      }
    }
    if (user && creditSystemEnabled) {
      getUserTransactionsOnLoad();
    }
  }, [apiInstance, creditSystemEnabled, user]);

  return (
    <UserCreditContext.Provider
      value={{
        userCreditBalance: userCreditBalance,
        setUserCreditBalance: setUserCreditBalance,
        userCreditBalanceLoading: userCreditBalanceLoading,
        userTransactions: userTransactions,
        userTransactionsLoading: userTransactionsLoading,
      }}>
      {children}
    </UserCreditContext.Provider>
  );
}
