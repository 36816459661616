import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ProductContext } from "../context/ProductContext";
import { useHistory, useParams } from "react-router-dom";
import { Product, ProductAccount } from "../models/Product";
import {
  getProduct,
  getProductAccountForUser,
} from "../scripts/createApiInstance";

import { CenterLoader } from "../components/Loader";
import {
  ANALYTICS_EVENT,
  EVENT_TYPE,
  eventBundleProperties,
  productDetailsBundle,
  sendAnalyticsData,
} from "../scripts/analytics";

import ProductInfo from "../components/ProductPage/ProductInfo";
import ProductHeader from "../components/ProductPage/ProductHeader";
import { Col, Row } from "react-bootstrap";
import { ProductAccountSuccessPage } from "../components/ProductAccessFlow/ProductAccountSuccessPage";
import { ProductAccountWaitlistPage } from "../components/ProductAccessFlow/ProductAccountWaitlistPage";
import { ProductAccountShoppingPage } from "../components/ProductAccessFlow/ProductAccountShoppingPage";

import "../styles/ProductAccountShoppingPage.scss";

interface ProductViewRouterParamTypes {
  productId: string;
}

/**
 * The main purpose of this view is to determine whether to show a ShoppingPage, AccountPage, or WaitlistPage.
 * We determine if it should be an AccountPage by:
 *      - getting the productId from the URL.
 *      - Local to this component, we then get the product and the productAccount (if there is one).
 *      - If there is a productAccount, we show the AccountPage.
 * We determine if it should be a WaitlistPage by:
 *     - getting allWaitlistDetails from the ProductContext, and then checking to see if our productId is in the list.
 *    - If it is, we show the WaitlistPage.
 *
 * If it is neither, we show the ShoppingPage.
 *
 */
export function ProductView(): JSX.Element {
  // get the productId from the url
  const { productId } = useParams<ProductViewRouterParamTypes>();

  // CONTEXTS //
  const { apiInstance, user } = useContext(AuthContext);
  const { allProductWaitlistDetails, allProductCreditCosts } =
    useContext(ProductContext);

  // STATES //
  const [product, setProduct] = useState<Product>();
  const [productAccount, setProductAccount] = useState<ProductAccount>();

  // eventually we want to get rid of this hack-y setRefresh behavior, but for now
  // it gets passed down to the checkout flow in order to refresh this page if something goes wrong (e.g. there are no product accounts available)
  const [refresh, setRefresh] = useState<boolean>(false);

  // LOADERS //
  const [productLoader, setProductLoader] = useState(false);
  const [productAccountLoader, setProductAccountLoader] =
    useState<boolean>(false);

  const history = useHistory();

  const productCreditCost = allProductCreditCosts.find(
    // eslint-disable-next-line eqeqeq
    (productCreditCost) => productCreditCost.productId == productId
  );

  let eventAlreadySubmitted: boolean = false;
  useEffect(() => {
    if (product !== undefined) {
      if (!eventAlreadySubmitted) {
        sendAnalyticsData(ANALYTICS_EVENT.ON_LOAD_PRODUCTPAGE, {
          ...eventBundleProperties(user, EVENT_TYPE.UI),
          ...productDetailsBundle(product, "ACCESSIBLE"),
        });
        eventAlreadySubmitted = true;
      }
    }
  }, [product, user]);

  // we keep this at the component level because we need to get the product using the productId which is extracted from the URL params
  useEffect(() => {
    async function getProductOnLoad() {
      try {
        setProductLoader(true);
        const product = await getProduct(apiInstance, productId);
        setProduct(product.data);
      } catch (error) {
        console.log("Error getting product", error);
      } finally {
        setProductLoader(false);
      }
    }
    getProductOnLoad();
  }, [apiInstance, productId, history, refresh]);

  // This checks to see if there is a current, active product account for this user and this product.
  // If so, we need to show the ProductAccountSuccessPage with the credentials.
  useEffect(() => {
    async function getProductAccountOnLoad() {
      try {
        setProductAccountLoader(true);
        const productAccountResponse = await getProductAccountForUser(
          apiInstance,
          productId
        );
        setProductAccount(productAccountResponse.data);
      } catch (err) {
        console.log("Error getting product account", err);
      } finally {
        setProductAccountLoader(false);
      }
    }
    getProductAccountOnLoad();
  }, [apiInstance, productId, refresh]);

  const waitlistDetailsForUserAndProduct = allProductWaitlistDetails.find(
    (waitlistDetails) => waitlistDetails.productId === product?.id
  );

  const isLoading = !product || productLoader || productAccountLoader;

  return isLoading ? (
    <CenterLoader />
  ) : (
    <>
      <Row>
        <Col md={6}>
          <ProductInfo product={product} />
        </Col>
        <Col md={6}>
          <Row>
            <ProductHeader product={product} />
          </Row>
          <Row>
            {productAccount ? (
              <ProductAccountSuccessPage
                product={product}
                productAccount={productAccount}
              />
            ) : waitlistDetailsForUserAndProduct ? (
              <ProductAccountWaitlistPage
                product={product}
                waitlistDetails={waitlistDetailsForUserAndProduct}
              />
            ) : (
              <ProductAccountShoppingPage
                product={product}
                productCreditCost={productCreditCost}
                setProductAccount={setProductAccount}
                refresh={refresh}
                setRefresh={setRefresh}
                productId={productId}
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
}
