import { useEffect } from "react";
import Row from "react-bootstrap/Row";

import "../styles/TermsView.scss";

export function TermsView(): JSX.Element {
  useEffect(() => {
    document.documentElement.style.backgroundColor = "#175fd3";

    return () => {
      document.documentElement.style.backgroundColor = "";
    };
  }, []);

  return (
    <div id="terms-view">
      <Row>
        <p>
          This website is owned and operated by SmergeRP LLC. Where we say “we”,
          “us”, “our”, the “Company”, or “Keye”, we mean Keye and/or one or more
          of its affiliates, as maybe applicable. By visiting, accessing and
          using our website, you agree to our Terms and Conditions available at
          and our Privacy Policy.
        </p>
        <p>
          The information that you submit to us by your use of this website is
          at your sole and absolute discretion and any personal information that
          you submit shall be subjected to our privacy practices as particularly
          mentioned in the then current version of our Privacy Policy, made
          available in our website. You hereby acknowledge and agree that all
          information that we obtain from you, including technical and/or any
          statistical data that is non-personally identifiable with respect to
          any user, through your use of our website is for the purposes of
          market research and study. In no event, do we have the ability to
          identify any of our website’ user with respect to any non-personally
          identifiable information that is submitted, as the source of such
          information.
        </p>
        <p>
          The use of information on or derived from our website is at your own
          risk. The information and all content published on our website may
          include inaccuracies, typographical errors, or incomplete information
          owing to technical difficulties inherent in the internet, software or
          any transmission issues. While we endeavor to ensure that the
          information contained on the website is both accurate and up-to-date,
          some information may be subject to change and we assume no liability
          for any loss or claim arising due to any of the foregoing.
        </p>
        <p>
          Our website or any document posted on this website may contain links
          to other third-party websites and/or applications. You acknowledge
          that by accessing such link to third-party website and/or application,
          you are leaving our website and we do not monitor, administer, control
          or operate such third-party websites and their hosted content in any
          manner and accordingly we shall not be responsible for any content
          hosted by such third-party or any link contained in such third-party
          website/application. Your use of such third-party services shall be
          governed by the third-party terms and conditions mentioned in their
          website and you hereby agree to have read and be governed by the same
          prior to your usage.{" "}
        </p>
        <p>Exclusion of liability. </p>
        <p>
          The information contained on the website is provided in good faith
          based on the facts that we are aware of at the time. We, including any
          of our affiliates, directors, employees shall not be liable for any
          claims, expenses, damages (including direct, indirect, special or
          consequential damages), loss of profits, opportunities or information
          arising from the use of or reliance on information contained in our
          website; any inaccuracy, omission or failure to keep the information
          current; your use and access of any third party websites/applications
          linked to our website; any computer viruses, malware or other
          destructive programs encountered as a result of using and accessing
          our website; and any other matter connected with our website; even if
          we are made aware of the possibility of such claims, expenses, damages
          or losses.
        </p>
      </Row>
    </div>
  );
}
