import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { ProductContext } from "../context/ProductContext";

export function WaitListConfirmation(): JSX.Element {
  const { currentProducts, allProductWaitlistDetails } =
    useContext(ProductContext);
  const { productId } = useParams<any>();
  const [productInfo, setProductInfo] = useState<any>();
  useEffect(() => {
    let productData = currentProducts.filter((item) => item.id == productId);
    if (productData?.length > 0) setProductInfo(productData[0]);
  }, [currentProducts, productId]);

  return (
    <div className="new-arrivals-products-view">
      <>
        <Row id="product-card-row">
          <Col xs={12} md={6} xl={6} className="mb-3">
            <img
              src={productInfo?.imgUrl}
              alt="images"
              className="mb-3 col-xl-12 col-md-12 col-12"
            />
          </Col>
          <Col xs={12} md={6} xl={6} className="mb-3">
            <h3
              className="success"
              style={{ color: "#2e7cf6", fontWeight: "normal" }}>
              Success! Your reservation is confirmed.
            </h3>
            <div>
              <h4>{productInfo?.name}</h4>
              <div style={{ display: "flex" }}>
                <p>
                  Your access will begin at{" "}
                  {allProductWaitlistDetails?.length &&
                    allProductWaitlistDetails[0].scheduledTime}
                  .<br></br>
                  <br></br>
                  You will receive your login information and instructions via
                  email as soon as your account is ready.
                  <br></br> <br></br>
                  <img
                    src="/images/small_k.png"
                    id="nav-credit-k-logo"
                    alt="small keye logo"
                  />
                  10 will be deducted from your credit balance when access
                  starts.
                </p>
                <img
                  height={"113"}
                  src="../images/welcome_message.png"
                  alt="welcome message"
                />
              </div>
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
}
