import React from "react";
import { Button, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

import "../styles/Footer.scss";

export function Footer(): JSX.Element {
  return isMobile ? (
    <div id="footer-bottom">
      <div className="container">
        <Col
          className="d-flex flex-row align-items-center"
          style={{ justifyContent: "space-between" }}>
          <div className="d-flex flex-row align-items-center">
            <div className="key-logo-footer">
              <img
                src="/images/keye_logo.png"
                alt="a small blue keye icon"
                id="keye-logo-nav"
                style={{ width: "70px" }}
              />
            </div>
            <div className="d-flex flex-row flex-direction-link align-items-center margin10 footer-social-media">
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://www.linkedin.com/company/keye/">
                <Icon.Linkedin />
              </a>
              &nbsp;&nbsp;
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://www.instagram.com/keye.co/">
                <Icon.Instagram />
              </a>
            </div>
            <div className="d-flex flex-row flex-direction-link align-items-center margin10 footer-links">
              <Link to="/home">
                <Button
                  variant="link"
                  className="link-color links-remove-boder">
                  Home
                </Button>
              </Link>
              <Link to="/help">
                <Button
                  variant="link"
                  className="link-color links-remove-boder">
                  How Keye Works
                </Button>
              </Link>
              <Link to="/coming-soon#request-product-header">
                <Button
                  variant="link"
                  className="link-color links-remove-boder">
                  Request a Product
                </Button>
              </Link>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <h6 className="text-middle">
              Suggestions or have feedback? Submit them here.
            </h6>
            &nbsp;
            <Button
              id="feedback-button"
              className="button-color"
              onClick={() =>
                window.open("https://keye.kampsite.co/", "_blank")
              }>
              Submit Feedback
            </Button>
          </div>
        </Col>
      </div>
    </div>
  ) : (
    <div id="footer">
      <div className="container">
        <Col
          className="d-flex flex-row align-items-center"
          style={{ justifyContent: "space-between" }}>
          <div className="d-flex flex-row align-items-center">
            <div className="key-logo-footer">
              <img
                src="/images/keye_logo.png"
                alt="a small blue keye icon"
                id="keye-logo-nav"
                style={{ width: "70px" }}
              />
            </div>
            <div className="d-flex flex-row flex-direction-link align-items-center margin10 footer-social-media">
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://www.linkedin.com/company/keye/">
                <Icon.Linkedin />
              </a>
              &nbsp;&nbsp;
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://www.instagram.com/keye.co/">
                <Icon.Instagram />
              </a>
            </div>
            <div className="d-flex flex-row flex-direction-link align-items-center margin10 footer-links">
              <Link to="/home">
                <Button
                  variant="link"
                  className="link-color links-remove-boder">
                  Home
                </Button>
              </Link>
              <Link to="/help">
                <Button
                  variant="link"
                  className="link-color links-remove-boder">
                  How Keye Works
                </Button>
              </Link>
              <Link to="/coming-soon#request-product-header">
                <Button
                  variant="link"
                  className="link-color links-remove-boder">
                  Request a Product
                </Button>
              </Link>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <h6 className="text-middle">
              Suggestions or have feedback? Submit them here.
            </h6>
            &nbsp;
            <Button
              id="feedback-button"
              className="button-color"
              onClick={() =>
                window.open("https://keye.kampsite.co/", "_blank")
              }>
              Submit Feedback
            </Button>
          </div>
        </Col>
      </div>
    </div>
  );
}
