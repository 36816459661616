import React from "react";

import DotsGraphic from "../dots.png";
import LaptopGraphic from "../hero.gif";

import "../styles/BannerPage.scss";

export function BannerView(): JSX.Element {
  document.body.style.backgroundColor = "white";
  return (
    <div className="bg-blue-1">
      <div className="relative pt-9 pb-9 flex items-stretch justify-between mx-auto max-w-screen-1920 align-middle">
        <div className="my-auto z-10 sm:px-4 lg:px-8">
          <h1 className="font-bold font-gilroy xl:text-hero lg:text-largest md:text-larger">
            You’ve been pre-registered to the new Keye experience
          </h1>
          <p className="md:text-small lg:text-large my-6">
            Look out for our email for a unique link to get one month free. When
            we launch next week, you’ll be able to unlock premium content from
            over 30 sites with one click.
          </p>
          {/* <button
            className="font-semibold text-large md:text-small py-3 px-7 md:px-4 text-white rounded-full bg-primaryOrange shadow-button"
            onClick={onPress}>
            Join the waitlist
          </button> */}
        </div>
        <div unselectable="on">
          <div className="relative w-12 md:w-13 lg:w-14 xl:w-16 z-10">
            <img src={LaptopGraphic} alt="" />
          </div>
        </div>
      </div>
      <div
        unselectable="on"
        className="dotGrapic absolute 768:w-12 768:top-[440px] 820:w-12 820:top-[420px] 900:top-[430px] 1000:top-[400px] 1000:w-13 1024:top-[520px] 1024:w-13 1280:top-[600px] 1280:w-14 1360:top-[580px] 1650:top-[530px] max-w-screen-1920 mx-auto z-0">
        <div>
          <br />
          <br />
          <br />
          <img src={DotsGraphic} alt="" />
        </div>
      </div>
    </div>
  );
}
